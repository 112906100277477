export const FilterIcon = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.30777 8.00008H11.6924M2.66675 4.66675H13.3334M6.76931 11.3334H9.23085'
          stroke='#28287B'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
