import React from 'react'
import { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import CodeIcon from '@mui/icons-material/Code'
import DOMPurify from 'dompurify'
import toast from 'react-hot-toast'
// Undo and redo functions for Custom Toolbar
function undoChange () {
  this.quill.history.undo()
}
function redoChange () {
  this.quill.history.redo()
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size')
Size.whitelist = ['small', 'extra-small', 'medium', 'large']
Quill.register(Size, true)

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font')
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida'
]
Quill.register(Font, true)

const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024

const handleImageUpload = function () {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.click()

  input.onchange = () => {
    const file = input.files[0]
    if (file && file.size > FILE_SIZE_LIMIT) {
      toast.error('File size exceeds 4.5 MB. Please choose a smaller file.')
      return
    }
    if (typeof window !== 'undefined' && typeof FileReader !== 'undefined') {
      const reader = new window.FileReader()
      reader.onload = () => {
        const range = this.quill.getSelection()
        this.quill.insertEmbed(range.index, 'image', reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
}

const sanitizeHtml = (html) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['p', 'b', 'i', 'strong', 'em', 'u', 'a', 'ul', 'ol', 'li', 'br', 'span'],
    ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'id', 'style']
  })
}

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
      customButton: function () {
        if (typeof window !== 'undefined' && typeof alert !== 'undefined') {
          window.alert('Custom button clicked!')
        }
      },
      showHtml: function () {
        const txtArea = document.querySelector('.ql-html-editor')
        if (!txtArea) {
          console.error('Error: .ql-html-editor element not found.')
          return
        }
        const quillEditor = this.quill.root.innerHTML
        txtArea.value = quillEditor
        txtArea.style.display = txtArea.style.display === 'none' ? '' : 'none'
        txtArea.addEventListener('input', () => {
          const sanitizedHtml = sanitizeHtml(txtArea.value)
          const delta = this.quill.clipboard.convert(sanitizedHtml)
          this.quill.setContents(delta)
        })
      },
      image: handleImageUpload
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  },
  clipboard: {
    matchVisual: false
  }
}

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block'
]

// Quill Toolbar component
const QuillToolbar = () => (
  <>
    <div id='toolbar'>
      <span className='ql-formats'>
        <button className='ql-bold' />
        <button className='ql-italic' />
        <button className='ql-underline' />
        <button className='ql-strike' />
      </span>
      <span className='ql-formats'>
        <select className='ql-align' />
        <select className='ql-color' />
        <select className='ql-background' />
      </span>
      <span className='ql-formats'>
        <button className='ql-list' value='ordered' />
        <button className='ql-list' value='bullet' />
      </span>
      <span className='ql-formats'>
        <select className='ql-size custom-dropdown' defaultValue='medium' style={{ width: '40px' }}>
          <option value='small'>1</option>
          <option value='extra-small'> 2</option>
          <option value='medium'>3</option>
          <option value='large'>4</option>
        </select>
      </span>
      <span className='ql-formats'>
        <button className='ql-script' value='super' />
        <button className='ql-script' value='sub' />
        <button className='ql-blockquote' />
      </span>
      <span className='ql-formats'>
        <button className='ql-showHtml'><CodeIcon /></button>
      </span>
      <span className='ql-formats'>
        <button className='ql-link' />
        <button className='ql-image' />
      </span>
    </div>
  </>
)

export default QuillToolbar
