import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'

const DeleteWebhookModal = ({ open, onClose, onDelete }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2, py: 1 }}>
        <DialogTitle sx={{ fontSize: '24px', textAlign: 'center' }}>Are you sure?</DialogTitle>
        <DialogContent>
          Deleting this webhook will  delete any existing data related to webhook .
        </DialogContent>
        <DialogActions sx={{ mb: 3, justifyContent: 'center' }}>
          <Button onClick={onDelete} variant='contained' color='error'>
            Delete Webhook
          </Button>
          <Button onClick={onClose} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>Cancel</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DeleteWebhookModal
