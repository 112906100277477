import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { useAuth } from 'src/auth/auth';

const ViewerRoute = ({ element: Component, ...rest }) => {
  const { canEdit } = useAuth();
  const [delayedCanEdit, setDelayedCanEdit] = useState(canEdit);
  const [checking, setChecking] = useState(true);

  useEffect(() => {
    const delayCheck = setTimeout(() => {
      console.log('Re-checking canEdit:', canEdit);
      setDelayedCanEdit(canEdit);
      setChecking(false);
    }, 2000);

    return () => clearTimeout(delayCheck);
  }, [canEdit]);

  if (checking) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }
  if (!delayedCanEdit) {
    return <Navigate to="/accounts" />;
  }

  return <Component {...rest} />;
};

export default ViewerRoute;