import ReactDOM from 'react-dom/client'
import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { App } from './app'
import './styles.css'
import { Provider } from 'react-redux'
import { store } from './store.js'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { config } from './config'
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './msalConfig';
const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const RootComponent = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense>
            <GoogleOAuthProvider
              clientId={config.GOOGLE_OAUTH_CLIENT_ID}
            >
              <App instance={msalInstance} />
            </GoogleOAuthProvider>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<RootComponent />)
