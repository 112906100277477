import React, { useEffect, useState } from "react";

import { Box, Stack, Typography, alpha } from "@mui/material";

import Section1Img1 from "src/assets/auth/signin/section1-1.png";
import Section1Img2 from "src/assets/auth/signin/section1-2.png";
import Section1Img3 from "src/assets/auth/signin/section1-3.png";
import Ellipse1 from "src/assets/auth/signin/ellipse1.svg";

const BgEllipse = () => {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          width: "2000px",
          top: "10%",
          left: "50%",
          translate: "-50% 0",
          zIndex: "0",
        }}
      >
        <img alt="Ellipse 1" src={Ellipse1} style={{ width: "100%" }} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "1200px",
          top: "50%",
          left: "50%",
          translate: "-50% 0",
          zIndex: "0",
        }}
      >
        <img alt="Ellipse 1" src={Ellipse1} style={{ width: "100%" }} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "700px",
          top: "75%",
          left: "50%",
          translate: "-50% 0",
          zIndex: "0",
        }}
      >
        <img alt="Ellipse 1" src={Ellipse1} style={{ width: "100%" }} />
      </Box>
    </>
  );
};

export default function LoginAnimation() {
  const [currentIndex, setCurrentIndex] = useState(2);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const slideData = slides[currentIndex];

  return (
    <>
      <BgEllipse />
      <Box sx={{ width: "100%", minHeight: "800px", position: "relative" }}>
        <Slide
          key={slideData.heading}
          img={slideData.img}
          maxWidth={slideData.maxWidth}
          heading={slideData.heading}
          paragraph={slideData.paragraph}
        />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: 2 }}
      >
        {["Slide 1", "Slide 2", "Slide 3"].map((slide, i) => (
          <Box
            key={slide}
            sx={{
              width: 40,
              height: 8,
              borderRadius: "999px",
              backgroundColor: i < currentIndex ? "white" : "gray",
              position: "relative",
              overflow: "hidden",
              "&::before":
                i === currentIndex
                  ? {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "white",
                      transform: "scaleX(0)",
                      transformOrigin: "left",
                      "@keyframes fillAnimation": {
                        "0%": { transform: "scaleX(0)" },
                        "100%": { transform: "scaleX(1)" },
                      },
                      animation: "fillAnimation 5s linear forwards",
                    }
                  : {},
            }}
            aria-label={slide}
          />
        ))}
      </Stack>
    </>
  );
}

function Slide({ img, maxWidth, heading, paragraph }) {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      spacing={3}
      sx={{
        width: "100%",
        minWidth: "100%",
        maxWidth: "lg",
        height: "100%",
      }}
    >
      {/* Image Section */}
      <Box
        sx={{
          height: "600px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={img}
          sx={{
            maxWidth,
            width: "auto",
            height: "100%",
            margin: "0 auto",
            display: "block",
            objectFit: "contain",
            objectPosition: "center",
          }}
        />
      </Box>

      {/* Text Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
          maxWidth: 800,
        }}
      >
        <Box
          sx={{
            px: 4,
            py: 2,
            borderRadius: "9999px",
            fontSize: "0.875rem",
            fontWeight: "500",
            backgroundColor: alpha("#3F4FF8", 0.3),
            color: "white",
            border: "1px solid rgba(255, 255, 255, 0.15)",
          }}
        >
          Why Success.ai
        </Box>

        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            fontSize: "2.25rem",
            textAlign: "center",
            maxWidth: 580,
            lineHeight: 1.5,
            color: "white",
          }}
        >
          {heading}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            maxWidth: 480,
            textAlign: "center",
            fontSize: "0.875rem",
            color: "white",
          }}
        >
          {paragraph}
        </Typography>
      </Box>
    </Stack>
  );
}

const slides = [
  {
    img: Section1Img1,
    maxWidth: "70%",
    heading: "Expand Your Outreach with Unlimited Email Sending",
    paragraph:
      "Automate your interactions with large-scale personalization to better engage your target audience.",
  },
  {
    img: Section1Img2,
    maxWidth: "70%",
    heading: "Boost Your Deliverability with Seamless Email Warming",
    paragraph:
      "Seamlessly enhance your email reputation with our warmup tool,ensuring reliable inbox delivery and maximizing engagement.",
  },
  {
    img: Section1Img3,
    maxWidth: "100%",
    heading: "Find New Leads Effortlessly with Our Exceptional Database",
    paragraph:
      "Effortlessly uncover high-potential leads with our robust,data-rich database, designed to streamline your search and fuel your growth.",
  },
];
