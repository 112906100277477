import React, { useState } from 'react'
import {
  Box,
  Chip,
  // Grid,
  Typography,
  useTheme,
  Checkbox,
  // CircularProgress,
  Tooltip,
  Popover,
  Button
  , useMediaQuery
} from '@mui/material'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
// import { EAPlayIcon } from 'src/assets/emailAccounts/EAPlayIcon'
import { EAMoreIcon } from 'src/assets/emailAccounts/EAMoreIcon'
// import { FaTags } from 'react-icons/fa'
import { LinkedIn, WhatsApp, Delete, DriveFileRenameOutline } from '@mui/icons-material'

const LinkedAccountBlock = ({ account, cardName, bulkSettingsOn, canEdit = true, onDelete, deleteLoading, onReconnect, reconnectLoading }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTab = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isCardView = cardName === 'Card view' || isMobile || isTab
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const isTableView = cardName === 'Table view' && !isMobile && !isTab
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (

    <Box
      sx={{
        width: '100%',
        height: '100%',
        p: 3,
        pt: 1.5,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0px 12px 15px 0px #4B71970D',
        backgroundColor: '#fff',
        '&:hover': {
          boxShadow: '0px 2px 14px -1px rgba(0, 0, 0, 0.2)'
        },
        transition: 'all 0.2s ease-in-out',
        flexDirection: 'column'
        // position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          flexDirection: { xs: 'column-reverse', md: 'row' }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: isCardView ? '100%' : '150vw',
            maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
          }}
        >
          <Checkbox
            //   checked={isAccountChecked}
            //   onChange={handleChangeChecked}
            //   onClick={handleCheckedBox}
            inputProps={{ 'aria-label': 'controlled' }}
            size='small'
            icon={<OffCheckboxCustomIcon />}
            checkedIcon={<OnCheckboxCustomIcon />}
          />
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
            // bulkSettingsOn ? "calc(100% - 50px)" : "100%"
          }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: '2px',
                width: isCardView ? '100%' : { md: '39vw', lg: '30vw', xl: '36vw' }
              }}
            >
              {isCardView
                ? (
                  <Typography
                    sx={{
                      ml: (!bulkSettingsOn && 1) || 1,
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '18px',
                      color: '#28287B',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%'
                    }}
                  >
                    {account.account_type === 'LINKEDIN' ? `${account.account_obj.first_name} ${account.account_obj.last_name}` : account.account_type === 'WHATSAPP' ? account.account_obj?.name : account.account_id}

                  </Typography>
                )
                : <></>}
              <Typography
                sx={{
                  display: 'flex',
                  ml: !bulkSettingsOn && 1,
                  fontSize: isCardView ? '13px' : '14px',
                  fontWeight: isCardView ? 400 : 600,
                  lineHeight: '18px',
                  color: isCardView ? '#8181B0' : '#14171f',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%'
                }}
              >
                {/* {account.email}{" "} */}
                {account.account_type === 'LINKEDIN'
                  ? <><span>{account?.account_obj?.email}</span>&nbsp;&nbsp;<span style={{ fontWeight: 'normal' }}>{'(  ' + account?.account_id + '  )'}</span></>
                  : account?.account_id}{' '}
                {isTableView && (
                  // account?.eEngineStatus === "authenticationError" ||
                  // account?.eEngineStatus === "connectError" ||
                  // account?.status === "reconnect"
                  account?.account_obj?.sources && account?.account_obj.sources[0].status !== 'OK'
                )
                  ? (
                    <Chip
                      label='Disconnected'
                      sx={{
                        fontSize: '10px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        color: 'red',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        border: ' 1px solid #FAD7DB',
                        px: 0.5,
                        // py: 1,
                        // mb:1,
                        ml: 1,
                        width: '100px'
                      }}
                      size='small'
                    />
                  )
                  : (
                    <></>
                  )}
              </Typography>
              {/* <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    gap: '8px',
                                    alignItems: 'center'
                                }}
                            >
                                {account?.tags.map((tag, index) => (
                                    <Typography
                                        key={index}
                                        sx={{
                                            ml: 1,
                                            fontSize: "13px",
                                            fontWeight: 400,
                                            lineHeight: "18px",
                                            color: "#8181B0",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '4px'
                                        }}
                                    >
                                        <FaTags style={{ marginRight: '1px' }} fontSize="small" />
                                        {tag?.label}
                                    </Typography>
                                ))}
                            </Box> */}
            </Box>
          </Box>
        </Box>

        {isTableView
          ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '50vw',
                  maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                }}
              >
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                  // bulkSettingsOn ? "calc(100% - 50px)" : "100%"
                }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '2px',
                      width: '10vw'
                    }}
                  >
                    <Typography
                      sx={{
                        ml: (!bulkSettingsOn && 1) || 1,
                        fontSize: '13px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        color: '#14171f',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%'
                      }}
                    >
                      {account?.campaignSend ? account?.campaignSend : 0} of {account?.campaign?.dailyLimit}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '50vw',
                  maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                }}
              >
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                  // bulkSettingsOn ? "calc(100% - 50px)" : "100%"
                }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '2px',
                      width: '10vw'
                    }}
                  >
                    <Typography
                      sx={{
                        ml: (!bulkSettingsOn && 1) || 1,
                        fontSize: '13px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        color: '#14171f',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%'
                      }}
                    >
                      {account?.warmupStats?.sent_count ? account?.warmupStats?.sent_count : 0}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: bulkSettingsOn ? 'calc(100% - 50px)' : '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '2px',
                      width: '100%'
                    }}
                  >
                    <Typography
                      sx={{
                        ml: !bulkSettingsOn && 1,
                        fontSize: '13px',
                        fontWeight: 700,
                        lineHeight: '18px',
                        color: '#14171f',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%'
                      }}
                    >
                      {account?.warmupStats?.health_score || 0}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          )
          : (
            <></>
          )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%'
          }}
        >
          {canEdit && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'space-between', md: 'center' },
                alignItems: 'center',
                width: { xs: '100%', md: 'fit-content' },
                borderRadius: 0.75,
                p: 1.3,
                pr: 0
              }}
            >
              {account.status === 'paused' && (
                <>
                  {account.status === 'paused' && (
                    <>
                      <Box
                        sx={{
                          display: { xs: 'none', sm: 'flex' },
                          justifyContent: 'center',
                          alignItems: 'center',
                          '&:hover': {
                            backgroundColor: '#f2f2f2'
                          },
                          borderRadius: 0.75,
                          p: 0.5
                        }}
                      >
                        {/* {isLoading ? (
                                                    <CircularProgress size={24} />
                                                ) : (
                                                    account.freeUserOtherAccounts === true ? (
                                                        <></>
                                                    ) : (
                                                        <Tooltip title="Click to resume your account" placement="top" sx={{ textAlign: "center" }} arrow>
                                                            <Grid item xs={4} sm={12} md={12} px={3}>
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <EAPlayIcon />
                                                                </Box>
                                                            </Grid>
                                                        </Tooltip>
                                                    )
                                                )} */}
                      </Box>
                    </>
                  )}
                </>
              )}{' '}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  '&:hover': {
                    backgroundColor: '#f2f2f2'
                  },
                  borderRadius: 0.75,
                  p: 0.5
                }}
                onClick={handleClick}
              >
                <Tooltip title='' placement='top' sx={{ textAlign: 'center' }} arrow>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <EAMoreIcon />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          py: 2,
          mt: 1,
          borderTop: `1px solid ${theme.palette.grey[200]}`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {
              account?.account_type === 'LINKEDIN' && <LinkedIn sx={{ height: '40px', width: '40px', color: '#0077B5' }} />
            }
            {
              account?.account_type === 'WHATSAPP' && <WhatsApp sx={{ height: '40px', width: '40px', color: '#25D366' }} />
            }
            {account?.account_obj?.sources && account?.account_obj.sources[0]?.status === 'OK'
              ? <Chip
                label='Connected'
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '16px',
                  color: 'green',
                  backgroundColor: '#fff',
                  borderRadius: '18px',
                  border: '1px solid lime',
                  px: 0.5,
                  py: 2,
                  width: '100%'
                }}
                size='small'
              />
              : account?.account_obj?.sources && account?.account_obj.sources[0]?.status === 'CONNECTING'
                ? <Chip
                  label='Connecting'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    color: 'green',
                    backgroundColor: '#fff',
                    borderRadius: '18px',
                    border: '1px solid lime',
                    px: 0.5,
                    py: 2,
                    width: '100%'
                  }}
                  size='small'
                />
                : <Chip
                  label='Disconnected'
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    color: 'red',
                    backgroundColor: '#fff',
                    borderRadius: '18px',
                    border: '1px solid red',
                    px: 0.5,
                    py: 2,
                    width: '100%'
                  }}
                  size='small'
                />}
          </Box>
          {/* <Box sx={{ ml: 1 }}>
                        <Typography
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",

                                fontSize: "20px",
                                fontWeight: 700,
                                lineHeight: "25px",
                                color: "#28287B",
                            }}
                        >
                            {account?.campaignSend ? account?.campaignSend : 0}
                        </Typography>
                        <Typography sx={{ color: "#8181B0", fontSize: "13px", fontWeight: 400 }}>
                            Campaign emails sent today
                        </Typography>
                    </Box> */}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {/* {(isSmtpImapTesting || loadingDNS) && isLoading && !isMobile ?
                        <Chip
                            label="Testing Account. Please wait."
                            sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                color: "#0071f6",
                                backgroundColor: "#fff",
                                borderRadius: "8px",
                                border: "1px solid #E4E4E5",
                                px: 0.5,
                                py: 2,
                                mb: 1,
                                width: "100%",
                            }}
                            size="small"
                        />
                        : ""} */}
          {/* {account?.sources[0].status !== "OK" && (
                        <Box>
                            <Chip
                                label="Paused"
                                sx={{
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: "16px",
                                    color: "#28287B",
                                    backgroundColor: "#fff",
                                    borderRadius: "8px",
                                    border: "1px solid #E4E4E5",
                                    px: 0.5,
                                    py: 2,
                                    mb: 1,
                                    width: "100%",
                                }}
                                size="small"
                            />
                        </Box>
                    )} */}
          {/* <Grid item xs="auto">
                        <Box sx={{
                            display: "flex",
                            flexDirection: 'column',
                            gap: '10px'
                        }} >
                            {
                                deleteLoading === account.account_id ? <CircularProgress disableShrink size={20} /> : <Button variant='outlined' disabled={deleteLoading ? true : false} color="error" onClick={() => onDelete(account.account_id)}>
                                    Delete
                                </Button>
                            }
                            {
                                reconnectLoading === account.account_id ? <CircularProgress disableShrink size={20} /> : <Button color="success" variant="outlined" onClick={() => onReconnect(account.account_id)}>
                                    Reconnect
                                </Button>
                            } */}
          {/* {(
                                // account?.eEngineStatus === "authenticationError" ||
                                // account?.eEngineStatus === "connectError" ||
                                // account?.status === "reconnect"
                                account?.sources[0]?.status === "OK"
                            ) &&
                                <Chip
                                    label="Connected"
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "16px",
                                        color: "green",
                                        backgroundColor: "#fff",
                                        borderRadius: "8px",
                                        border: "1px solid lime",
                                        px: 0.5,
                                        py: 2,
                                        mb: 1,
                                        width: "100%",
                                    }}
                                    size="small"
                                />
                            } */}
          {/* </Box>
                    </Grid> */}

          {/* <Grid item xs="auto">
                        <Box >
                            {testMessage &&
                                <Chip
                                    label={testMessage}
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "16px",
                                        color: enableFunctionality ? "green" : "red",
                                        backgroundColor: "#fff",
                                        borderRadius: "8px",
                                        border: enableFunctionality ? " 1px solid green" : " 1px solid #FAD7DB",
                                        px: 0.5,
                                        py: 2,
                                        mb: 1,
                                        width: "100%",
                                    }}
                                    size="small"
                                />
                            }
                        </Box>
                    </Grid> */}
          {/* <Grid item xs="auto">
                        <Box >
                            {domainMessage &&
                                <Chip
                                    label={domainMessage}
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "16px",
                                        color: enableDomainFunctionality ? "green" : "red",
                                        backgroundColor: "#fff",
                                        borderRadius: "8px",
                                        border: enableDomainFunctionality ? " 1px solid green" : " 1px solid #FAD7DB",
                                        px: 0.5,
                                        py: 2,
                                        mb: 1,
                                        width: "100%",
                                    }}
                                    size="small"
                                />
                            }
                        </Box>
                    </Grid> */}
          {/* {account?.sources[0]?.status !== "OK" && (
                        <Box>
                          <Chip
                            label="Disconnected"
                            // color="error"
                            sx={{
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "16px",
                              color: "#FD1E36",
                              backgroundColor: "white",
                              borderRadius: "8px",
                              border: "1px solid #FAD7DB",
                              px: 0.5,
                              py: 2,
                              mb:1,
                              width: "100%",
                            }}
                            size="small"
                          />
                        </Box>
                        <Grid item xs="auto">
                            <Box>
                                <Chip
                                    label="Paused"
                                    sx={{
                                        fontSize: "12px",
                                        fontWeight: 500,
                                        lineHeight: "16px",
                                        color: "#28287B",
                                        backgroundColor: "#fff",
                                        borderRadius: "8px",
                                        border: "1px solid #E4E4E5",
                                        px: 0.5,
                                        py: 2,
                                        mb: 1,
                                        width: "100%",
                                    }}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                    )} */}
          {/* <Grid item xs="auto">
                        <Box>
                            {account?.accountError && (
                                <>
                                    <Chip
                                        label="Error"
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            lineHeight: '16px',
                                            color: '#FD1E36',
                                            backgroundColor: 'white',
                                            borderRadius: '8px',
                                            border: '1px solid #FAD7DB',
                                            px: 0.5,
                                            py: 2,
                                            mb: 1,
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        size="small"
                                        // onClick={handleChipClick}
                                    />
                                </>
                            )}
                        </Box>
                    </Grid> */}
        </Box>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 240
          }}
        >
          {(account?.status !== 'connected' ||
            account?.eEngineStatus === 'authenticationError' ||
            account?.eEngineStatus === 'connectError') && (
              <Button
                fullWidth
                sx={{
                  py: 2,
                  px: 2,

                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828'
                }}
                // disabled = {account.status === 'paused'}
                onClick={() => { onReconnect(account.account_id, account.account_obj?.email); handleClose() }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <DriveFileRenameOutline sx={{ mr: 1 }} fontSize='small' />
                  Reconnect to Account
                </Box>
              </Button>
            )}
          <Button
            fullWidth
            sx={{
              py: 2,
              px: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: '#101828',
              '&:hover': {
                backgroundColor: '#ffd6d6'
              },
              borderRadius: 0
            }}
            // disabled = {account.status === 'paused'}
            onClick={() => {
              // setOpenRemoveAccountDialog(true);
              onDelete(account.account_id)
              setAnchorEl(null)
            }}
            disabled={!canEdit}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <Delete sx={{ mr: 1 }} fontSize='small' />
              Delete Account
            </Box>
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default LinkedAccountBlock
