import React from 'react'

export const UploadColoredIcon = () => {
  return (
    <>
      <svg
        width='56'
        height='56'
        viewBox='0 0 56 56'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='56' height='56' rx='28' fill='#D8E7FE' />
        <path
          d='M20 31.2044V34.8925C20 35.4514 20.2107 35.9875 20.5858 36.3827C20.9609 36.778 21.4696 37 22 37H34C34.5304 37 35.0391 36.778 35.4142 36.3827C35.7893 35.9875 36 35.4514 36 34.8925V31.2044M28.0007 30.9425L28.0007 19M28.0007 19L23.4293 23.5632M28.0007 19L32.5722 23.5632'
          stroke='#0071F6'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
