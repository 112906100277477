import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setWorkspace, useGetWorkSpacesMutation } from 'src/services/workSpace-service';
import { useEffect, useState } from 'react';

const EnterprisePlanRoute = ({ element: Component, ...rest }) => {
  const [getWorkSpaces] = useGetWorkSpacesMutation();
  const [timelineButtons, setTimeLineButtons] = useState([]);
  const dispatch = useDispatch();
  const [activeWorkspace, setActiveWorkspace] = useState({})

  useEffect(() => {
    const allWorkSpaces = async() => {
    const docs = await getWorkSpaces().unwrap();
    setTimeLineButtons(docs?.uniqueWorkspaces);
    setActiveWorkspace(docs?.currentWorkspace)
    }
    allWorkSpaces();
}, [getWorkSpaces]);

  useEffect(() => {
    dispatch(setWorkspace(activeWorkspace));
    if (activeWorkspace) {
      localStorage.setItem("workspace", JSON.stringify(activeWorkspace));
    }
  }, [timelineButtons,activeWorkspace, dispatch]);
  if (activeWorkspace?._id && (activeWorkspace?.enterprise && !activeWorkspace?.campaign?.uiForCampaign)) {
    return <Navigate to="/campaigns" />;
  }
  if ((activeWorkspace._id && activeWorkspace?.enterprise) || !activeWorkspace?.enterprise) {
    return <Component {...rest} />;
  }
};

export default EnterprisePlanRoute;
