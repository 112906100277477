export const EDLandedInbox = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' rx='12' fill='#DAEFDF' />
        <path
          d='M6 12.7617V15.8094C6 16.6509 6.67157 17.3332 7.5 17.3332H16.5C17.3284 17.3332 18 16.6509 18 15.8094V12.7617M6 12.7617L7.88501 7.65527C8.10455 7.06052 8.66423 6.6665 9.2895 6.6665H14.7105C15.3358 6.6665 15.8954 7.06052 16.115 7.65527L18 12.7617M6 12.7617H9L10 13.8284H14L15 12.7617H18'
          stroke='#00AA38'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
