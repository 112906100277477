import React, { useState } from 'react'
import {
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Button,
  Box,
  IconButton,
  Paper
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const SPFRecordGeneratorPage = () => {
  const [domainDetails, setDomainDetails] = useState([])

  // Combined IPv4 and IPv6 regex
  const ipRegex = /^(?:\d{1,3}\.){3}\d{1,3}$|^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/

  const validationSchema = () => {
    const schema = Yup.object({
      domain: Yup.string()
        .max(255)
        .required('Domain is required')
        .matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, 'Please add a valid Domain address'),
      ip: Yup.string()
        .max(255)
        .matches(ipRegex, 'Invalid IP address (IPv4 or IPv6)')
    })

    return schema
  }

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      let mark = ''
      if (values.spfFailure === 'fail') {
        mark = '-'
      } else if (values.spfFailure === 'soft fail') {
        mark = '~'
      } else if (values.spfFailure === 'neutral') {
        mark = '?'
      }

      let aMxPtrValue = ''
      if (values.sendMail) {
        aMxPtrValue += ' a'
      }

      if (values.mxRecordsSendMail) {
        aMxPtrValue += ' mx'
      }

      if (values.approveHosts) {
        aMxPtrValue += ' ptr'
      }

      const spfRecord = `v=spf1 ${aMxPtrValue}  ${mark}all`

      const spfRecordUsingBindKey = `${values.domain}. IN TXT ${aMxPtrValue}  ${mark}all \n${values.domain}. IN TXT ${aMxPtrValue}  ${mark}all`

      const spfRecordUsingTinyDNS = `${values.domain}:v=spf1 ${aMxPtrValue} ${mark}all:3600`
      setDomainDetails([{ spfRecord, spfRecordUsingBindKey, spfRecordUsingTinyDNS }])
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const formik = useFormik({
    initialValues: {
      domain: '',
      sendMail: false,
      mxRecordsSendMail: false,
      approveHosts: false,
      spfFailure: 'fail'
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers, true)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // success
    }).catch((err) => {
      console.error('Failed to copy: ', err)
    })
  }

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Typography variant='h5' component='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        SPF RECORD GENERATOR
      </Typography>

      <Typography color='primary.main' sx={{ fontSize: '0.8rem' }}>
        Create SPF records with our free SPF generator
      </Typography>

      {/* Spf Recored Generator Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={4}
          mt={4}
        >
          <TextField
            placeholder='Enter a domain name'
            variant='outlined'
            onChange={formik.handleChange}
            error={!!(formik.touched.domain && formik.errors.domain)}
            helpertext={formik.touched.domain && formik.errors.domain}
            onBlur={formik.handleBlur}
            name='domain'
            value={formik?.values?.domain}
            sx={{
              borderColor: 'gray.300',
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                width: { sm: 'auto', xs: 'auto' },
                maxHeight: '44px',
                color: 'primary.main'
              }
            }}
          />
          <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} sx={{ fontSize: '0.9rem' }} gap={3}>
            <FormControl component='fieldset' sx={{ mb: 2 }}>
              <FormLabel component='legend'>Does domain send mail?</FormLabel>
              <RadioGroup
                row
                onChange={formik.handleChange}
                error={!!(formik.touched.sendMail && formik.errors.sendMail)}
                name='sendMail'
                value={formik?.values?.sendMail}
              >
                <FormControlLabel value='true' control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>Yes</Typography>} />
                <FormControlLabel value='false' control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>No</Typography>} />
              </RadioGroup>
            </FormControl>
            <FormControl component='fieldset' sx={{ mb: 2 }}>
              <FormLabel component='legend'>Does MX records send mail?</FormLabel>
              <RadioGroup
                row
                onChange={formik.handleChange}
                error={!!(formik.touched.mxRecordsSendMail && formik.errors.mxRecordsSendMail)}
                name='mxRecordsSendMail'
                value={formik?.values?.mxRecordsSendMail}
              >
                <FormControlLabel value='true' control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>Yes</Typography>} />
                <FormControlLabel value='false' control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>No</Typography>} />
              </RadioGroup>
            </FormControl>
            <FormControl component='fieldset' sx={{ mb: 2 }}>
              <FormLabel component='legend'>Approve all hosts with domain name? (PTR)</FormLabel>
              <RadioGroup
                row
                error={!!(formik.touched.approveHosts && formik.errors.approveHosts)}
                name='approveHosts'
                value={formik?.values?.approveHosts}
                onChange={formik.handleChange}
              >
                <FormControlLabel value='true' control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>Yes</Typography>} />
                <FormControlLabel value='false' control={<Radio />} label={<Typography sx={{ fontSize: '0.9rem' }}>No</Typography>} />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box mt={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 3 }}>
          <FormControl>
            <FormLabel htmlFor='failedSpf' sx={{ mb: 1 }}>What do you want to happen if SPF Fails Authentication?</FormLabel>
            <Select
              id='failedSpf'
              onChange={formik.handleChange}
              error={!!(formik.touched.spfFailure && formik.errors.spfFailure)}
              onBlur={formik.handleBlur}
              name='spfFailure'
              value={formik?.values?.spfFailure}
              style={{ color: 'primary.main', maxHeight: '44px' }}

            >
              <MenuItem value='fail' style={{ fontSize: '0.9rem' }}>Fail</MenuItem>
              <MenuItem value='soft fail' style={{ fontSize: '0.9rem' }}>Soft Fail</MenuItem>
              <MenuItem value='neutral' style={{ fontSize: '0.9rem' }}>Neutral</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            sx={{
              // ml: [0,4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '10px',

              marginTop: { xs: 2 },
              maxHeight: '44px',
              cursor: formik?.values?.domain ? 'pointer' : 'not-allowed'
            }}
          >
            Generate
          </Button>
        </Box>
      </form>

      {/* result Table */}
      {domainDetails.length !== 0 &&
        <Box mt={8} p={2} sx={{ border: '2px solid #ECEDF5', borderRadius: '8px', maxWidth: { sm: '85vw' } }}>
          {/* SPF Record  Section */}
          <Box className='public-key-container'>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' color='text.primary' fontWeight='medium' sx={{ fontSize: '0.9rem' }}>
                SPF Record
              </Typography>
              <IconButton sx={{ color: '#6E58F1' }} aria-label='copy public key' onClick={() => handleCopy(domainDetails[0].spfRecord)}>
                <ContentCopyIcon />
                <Typography component='span' ml={1} sx={{ fontSize: '0.9rem' }}>Copy</Typography>
              </IconButton>
            </Box>
            <Paper variant='outlined' sx={{ backgroundColor: '#f9fafb', p: 2, mt: 2 }}>
              <Typography variant='body2' component='pre' color='text.secondary' style={{ wordWrap: 'break-word' }}>
                {domainDetails[0].spfRecord}
              </Typography>
            </Paper>
          </Box>

          {/* SPF Record using Bind Section */}
          <Box className='private-key-container' mt={4}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' color='text.primary' fontWeight='medium' sx={{ fontSize: '0.9rem' }}>
                SPF Record using Bind
              </Typography>
              <IconButton sx={{ color: '#6E58F1' }} aria-label='copy private key' onClick={() => handleCopy(domainDetails[0].spfRecordUsingBindKey)}>
                <ContentCopyIcon />
                <Typography component='span' ml={1} sx={{ fontSize: '0.9rem' }}>Copy</Typography>
              </IconButton>
            </Box>
            <Paper variant='outlined' sx={{ backgroundColor: '#f9fafb', p: 2, mt: 2 }}>
              <Typography variant='body2' component='pre' color='text.secondary' style={{ wordWrap: 'break-word' }}>
                {domainDetails[0].spfRecordUsingBindKey}
              </Typography>
            </Paper>
          </Box>

          {/* SPF Record using TinyDNS Section */}
          <Box className='private-key-container' mt={4}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' color='text.primary' fontWeight='medium' sx={{ fontSize: '0.9rem' }}>
                SPF Record using TinyDNS
              </Typography>
              <IconButton sx={{ color: '#6E58F1' }} aria-label='copy private key' onClick={() => handleCopy(domainDetails[0].spfRecordUsingTinyDNS)}>
                <ContentCopyIcon />
                <Typography component='span' ml={1} sx={{ fontSize: '0.9rem' }}>Copy</Typography>
              </IconButton>
            </Box>
            <Paper variant='outlined' sx={{ backgroundColor: '#f9fafb', p: 2, mt: 2 }}>
              <Typography variant='body2' component='pre' color='text.secondary' style={{ wordWrap: 'break-word' }}>
                {domainDetails[0].spfRecordUsingTinyDNS}
              </Typography>
            </Paper>
          </Box>

        </Box>}
    </Box>
  )
}

export default SPFRecordGeneratorPage
