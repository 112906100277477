import { useEffect, useState } from 'react'
import { useGetCurrentPlanQuery } from 'src/services/billing-service.js'
import { useGetMeAndWorkspaceQuery } from 'src/services/user-service'
import { useSelector } from 'react-redux'
import { planNames } from 'src/config'
import { useGetAccountsLengthMutation } from 'src/services/account-service'

const UserVerification = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [accountUsage, setAccountUsage] = useState(0);
  const { data: data, refetch: refetchData } = useGetMeAndWorkspaceQuery();
  const [getAccountsLength] = useGetAccountsLengthMutation();
  const { data: currentPlan } = useGetCurrentPlanQuery();
  const accountsLimit = useSelector((state) => state.accounts);
  const { _id: workspaceId } = useSelector((state) => state.workspace);
  const advanceApis = { successai_tier1_: true, successai_tier2_: true, successai_tier3_: true, successai_tier4_: false, successai_tier5_: false };
  const buyersIntendData = { successai_tier1_: false, successai_tier2_: false, successai_tier3_: true, successai_tier4_: true, successai_tier5_: true };
  const newAppSumoPlans = { successai_tier1_: true, successai_tier2_: true, successai_tier3_: true, successai_tier4_: true, successai_tier5_: true };
  const appSumoPlans = { successai_tier1: true, successai_tier2: true, successai_tier3: true, successai_tier4: true, successai_tier5: true };

  // Helper functions to determine user type
  const isAppSumoUser = () => {
    return data?.workspace && data?.workspace.assignedPlan && data?.workspace.assignedPlan.length > 0 && !data?.workspace.isAppSumoRefund
  }

  const newAppSumoUser = () => {
    return data?.workspace && data?.workspace.assignedPlan && data?.workspace.assignedPlan.length > 0 && !data?.workspace.isAppSumoRefund && newAppSumoPlans[data?.workspace?.assignedPlan]
  }

  const isEarlyBirdAccessUser = () => {
    return data?.workspace && data?.workspace.isEarlyBirdAccessUser && !data?.workspace.isEarlyBirdDealRefund
  }

  const isPaidUser = () => {
    return data?.workspace && currentPlan && (currentPlan.subscription?.sendingWarmup?.expiresAt || currentPlan.subscription?.leads?.expiresAt)
  }

  const checkIfDisableButton = () => {
    if (newAppSumoUser()) {
      if (totalCount >= accountUsage) {
        return true;
      }
      return false;
    }

    if(isAppSumoUser() || isEarlyBirdAccessUser) {
      return false;
    }

    if ((totalCount ?? accountsLimit.length) >= 2 && !isPaidUser()) {
      return true;
    }
    if (isPaidUser()) {
      if (planNames[currentPlan?.subscription?.sendingWarmup?.planId || currentPlan?.subscription?.leads?.planId] == 'Monthly Starter' && (accountUsage > 0 ? totalCount >= accountUsage : totalCount >= 5)) {
        return true
      }
      if (planNames[currentPlan?.subscription?.sendingWarmup?.planId || currentPlan?.subscription?.leads?.planId] == 'Monthly Growth' && (accountUsage > 0 ? totalCount >= accountUsage : totalCount >= 100)) {
        return true
      }
      if (planNames[currentPlan?.subscription?.sendingWarmup?.planId || currentPlan?.subscription?.leads?.planId] == 'Monthly Infinity' && (accountUsage > 0 ? totalCount >= accountUsage : totalCount >= 250)) {
        return true
      }
    }
    return false;
  }



  useEffect(() => {
    getAccountsLength({ workspaceId: workspaceId }).then((res) => {
      setTotalCount(res?.data?.total);
      setAccountUsage(res?.data?.emailAccountUsage)
    });
    refetchData();
  }, [currentPlan, accountsLimit]);

  const appSumoUserPlan = () => {
    return data?.workspace && data?.workspace.assignedPlan && data?.workspace.assignedPlan.length > 0 && !data?.workspace.isAppSumoRefund && appSumoPlans[data?.workspace?.assignedPlan]
  }

  const newAppSumoUserPlan = () => {
    return data?.workspace && data?.workspace.assignedPlan && data?.workspace.assignedPlan.length > 0 && !data?.workspace.isAppSumoRefund && buyersIntendData[data?.workspace?.assignedPlan]
  }

  const userDetails = {
    isAppSumoUser: isAppSumoUser(),
    newAppSumoUser: newAppSumoUser(),
    isEarlyBirdAccessUser: isEarlyBirdAccessUser(),
    isPaidUser: isPaidUser(),
    isFreeUser: !isAppSumoUser() && !isEarlyBirdAccessUser() && !isPaidUser() && !newAppSumoUser(),
    shouldDisableButton: checkIfDisableButton(),
    advancedApiButton: data?.workspace?.assignedPlan ? advanceApis[data?.workspace.assignedPlan] : false,
    buyersIntendFields: !appSumoUserPlan() && !isEarlyBirdAccessUser() && !isPaidUser() && !newAppSumoUserPlan(),
    accountsLimit
  }

  return userDetails
}

export default UserVerification
