export const EDSavedSpam = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' rx='12' fill='#FFECDD' />
        <path
          d='M12.0693 17.01H6.86989C5.98623 17.01 5.26988 16.2937 5.26989 15.41L5.26995 9.32714C5.26995 8.71786 5.26973 7.85004 5.26953 7.22167C5.26939 6.77973 5.62762 6.42188 6.06956 6.42188H9.8817L11.7251 8.39097H17.2693C17.7111 8.39097 18.0693 8.74914 18.0693 9.19097V11.316M16.4693 17.5786L16.4693 15.3158M16.4693 15.3158L16.4693 13.0531M16.4693 15.3158L14.2065 15.3158M16.4693 15.3158L18.732 15.3158'
          stroke='#FF7000'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
