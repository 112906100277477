export const AddEmailHero = () => {
  return (
    <>
      <svg
        width='109'
        height='88'
        viewBox='0 0 109 88'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_108_186)'>
          <path
            d='M54.4691 88.2179C84.5516 88.2179 108.938 86.8021 108.938 85.0555C108.938 83.309 84.5516 81.8931 54.4691 81.8931C24.3866 81.8931 0 83.309 0 85.0555C0 86.8021 24.3866 88.2179 54.4691 88.2179Z'
            fill='#F5F5F5'
          />
          <path
            d='M2.45209 44.7827L2.16748 44.8158L2.67324 49.1166L2.95785 49.0835L2.45209 44.7827Z'
            fill='#5C5C5C'
          />
          <path
            d='M3.14642 50.6832L2.86182 50.7163L3.11698 52.8862L3.40159 52.8531L3.14642 50.6832Z'
            fill='#5C5C5C'
          />
          <path
            d='M22.0473 36.013L21.7101 35.0716C21.4783 34.4245 21.0324 33.8748 20.4455 33.5125C19.8587 33.1501 19.1655 32.9965 18.4795 33.0769L5.05393 34.6385C4.24966 34.7323 3.5158 35.14 3.01373 35.7718C2.51167 36.4036 2.28252 37.2079 2.37668 38.0077L6.1973 70.4978C6.22902 70.7696 6.31431 71.0324 6.44828 71.2714C6.58224 71.5103 6.76226 71.7206 6.97804 71.8903C7.19382 72.06 7.44113 72.1858 7.70581 72.2603C7.97049 72.3349 8.24736 72.3568 8.52058 72.3249L56.5592 66.7376C56.833 66.7067 57.0979 66.6224 57.3389 66.4895C57.5798 66.3566 57.7919 66.1776 57.9631 65.963C58.1344 65.7483 58.2613 65.5022 58.3366 65.2386C58.4119 64.9751 58.4342 64.6994 58.4021 64.4272L55.0872 36.3399C55.0231 35.7916 54.7426 35.2911 54.3074 34.9485C53.8723 34.6058 53.3181 34.4491 52.7667 34.5128L24.8706 37.759C24.2708 37.8281 23.6652 37.6931 23.1525 37.376C22.6398 37.0589 22.2502 36.5785 22.0473 36.013Z'
            fill='#5C5C5C'
          />
          <path
            d='M23.3878 21.8842L8.20996 24.892L12.3771 45.6869L27.5549 42.6792L23.3878 21.8842Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.7'
            d='M23.3878 21.8842L8.20996 24.892L12.3771 45.6869L27.5549 42.6792L23.3878 21.8842Z'
            fill='white'
          />
          <g opacity='0.4'>
            <path
              d='M21.3618 26.8806L12.0125 28.7356C11.7947 28.7776 11.569 28.7325 11.3844 28.6102C11.1998 28.4878 11.0712 28.298 11.0264 28.0819C10.9842 27.8653 11.0295 27.6408 11.1525 27.4573C11.2756 27.2737 11.4665 27.1458 11.6838 27.1013L21.0387 25.2547C21.2565 25.2126 21.4822 25.2577 21.6668 25.3801C21.8514 25.5025 21.98 25.6923 22.0248 25.9084C22.0644 26.1245 22.0172 26.3475 21.8932 26.5293C21.7692 26.7112 21.5784 26.8373 21.3618 26.8806Z'
              fill='#5C5C5C'
            />
            <path
              d='M22.0501 30.3252L12.7008 32.1885C12.4834 32.2298 12.2585 32.1843 12.0745 32.062C11.8905 31.9397 11.7623 31.7503 11.7176 31.5348C11.6761 31.3186 11.7218 31.0949 11.8448 30.912C11.9678 30.7291 12.1582 30.6016 12.3749 30.557L21.7242 28.7048C21.9416 28.6636 22.1666 28.7091 22.3505 28.8314C22.5345 28.9537 22.6627 29.143 22.7075 29.3586C22.7457 29.573 22.6984 29.7939 22.5757 29.9743C22.453 30.1548 22.2645 30.2806 22.0501 30.3252Z'
              fill='#5C5C5C'
            />
            <path
              d='M22.7383 33.7641L13.389 35.6163C13.1717 35.6576 12.9467 35.6121 12.7627 35.4898C12.5788 35.3675 12.4506 35.1781 12.4058 34.9626C12.3643 34.7465 12.41 34.5228 12.533 34.3398C12.656 34.1569 12.8464 34.0294 13.0632 33.9848L22.4124 32.1299C22.6298 32.0886 22.8548 32.1341 23.0387 32.2564C23.2227 32.3787 23.3509 32.5681 23.3957 32.7836C23.438 33.0002 23.3927 33.2246 23.2696 33.4082C23.1465 33.5917 22.9556 33.7197 22.7383 33.7641Z'
              fill='#5C5C5C'
            />
            <path
              d='M23.4294 37.2171L14.0801 39.0693C13.8623 39.1113 13.6366 39.0663 13.452 38.9439C13.2674 38.8215 13.1388 38.6317 13.0941 38.4156C13.0518 38.199 13.0971 37.9746 13.2202 37.791C13.3432 37.6074 13.5341 37.4795 13.7514 37.435L23.1007 35.5828C23.3185 35.5408 23.5442 35.5858 23.7288 35.7082C23.9134 35.8306 24.042 36.0204 24.0868 36.2365C24.129 36.4531 24.0837 36.6775 23.9607 36.8611C23.8376 37.0447 23.6467 37.1726 23.4294 37.2171Z'
              fill='#5C5C5C'
            />
            <path
              d='M21.5612 41.1478L14.7683 42.4943C14.551 42.5355 14.326 42.4901 14.142 42.3678C13.9581 42.2455 13.8299 42.0561 13.7851 41.8406C13.7428 41.624 13.7881 41.3996 13.9112 41.216C14.0343 41.0324 14.2252 40.9045 14.4425 40.86L21.2353 39.5135C21.4527 39.4722 21.6776 39.5177 21.8616 39.64C22.0456 39.7623 22.1738 39.9517 22.2185 40.1672C22.2608 40.3838 22.2155 40.6082 22.0924 40.7918C21.9694 40.9753 21.7785 41.1033 21.5612 41.1478Z'
              fill='#5C5C5C'
            />
          </g>
          <path
            d='M45.0144 17.8767L29.6809 19.9627L32.571 40.9709L47.9045 38.8848L45.0144 17.8767Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.7'
            d='M45.0144 17.8767L29.6809 19.9627L32.571 40.9709L47.9045 38.8848L45.0144 17.8767Z'
            fill='white'
          />
          <g opacity='0.4'>
            <path
              d='M42.687 22.746L33.2422 24.0311C33.0223 24.0597 32.7998 24.001 32.6231 23.8677C32.4464 23.7343 32.3297 23.537 32.2983 23.3187C32.2694 23.0999 32.3285 22.8787 32.4626 22.703C32.5967 22.5273 32.7951 22.4112 33.0147 22.38L42.4595 21.0949C42.6794 21.0662 42.9019 21.1249 43.0786 21.2583C43.2553 21.3917 43.372 21.5889 43.4034 21.8073C43.4322 22.026 43.3732 22.2472 43.2391 22.423C43.105 22.5987 42.9066 22.7148 42.687 22.746Z'
              fill='#5C5C5C'
            />
            <path
              d='M43.1646 26.2185L33.7198 27.5036C33.4998 27.5322 33.2774 27.4735 33.1007 27.3402C32.9239 27.2068 32.8072 27.0096 32.7758 26.7912C32.7478 26.5729 32.8072 26.3524 32.9412 26.1773C33.0753 26.0022 33.2732 25.8865 33.4922 25.8553L42.937 24.5702C43.1565 24.5415 43.3786 24.5998 43.5552 24.7326C43.7319 24.8654 43.8489 25.0619 43.8809 25.2798C43.9098 25.4985 43.8507 25.7198 43.7166 25.8955C43.5825 26.0712 43.3842 26.1873 43.1646 26.2185Z'
              fill='#5C5C5C'
            />
            <path
              d='M36.7988 30.6241L34.1974 30.9789C33.9775 31.0075 33.755 30.9488 33.5783 30.8155C33.4016 30.6821 33.2849 30.4849 33.2535 30.2665C33.2246 30.0477 33.2837 29.8265 33.4178 29.6508C33.5519 29.4751 33.7503 29.359 33.9699 29.3278L36.5741 28.973C36.7936 28.9451 37.0153 29.0042 37.1914 29.1375C37.3675 29.2708 37.4838 29.4676 37.5152 29.6854C37.544 29.9041 37.485 30.1253 37.3509 30.3011C37.2168 30.4768 37.0184 30.5928 36.7988 30.6241Z'
              fill='#5C5C5C'
            />
            <path
              d='M41.847 29.9368L39.2428 30.2916C39.0233 30.3195 38.8016 30.2605 38.6255 30.1272C38.4494 29.9939 38.3331 29.7971 38.3017 29.5792C38.2729 29.3605 38.3319 29.1393 38.466 28.9636C38.6001 28.7879 38.7985 28.6718 39.0181 28.6406L41.6195 28.2886C41.839 28.2598 42.0611 28.3182 42.2377 28.4509C42.4143 28.5837 42.5313 28.7803 42.5634 28.9982C42.5922 29.2169 42.5332 29.4381 42.3991 29.6138C42.265 29.7896 42.0666 29.9056 41.847 29.9368Z'
              fill='#5C5C5C'
            />
            <path
              d='M44.1198 33.1663L34.675 34.4514C34.455 34.4801 34.2326 34.4214 34.0559 34.288C33.8791 34.1546 33.7624 33.9574 33.731 33.739C33.703 33.5207 33.7624 33.3002 33.8964 33.1251C34.0305 32.95 34.2284 32.8343 34.4474 32.8031L43.8922 31.5152C44.1122 31.4865 44.3346 31.5453 44.5113 31.6786C44.688 31.812 44.8047 32.0092 44.8361 32.2276C44.865 32.4463 44.8059 32.6676 44.6718 32.8433C44.5377 33.019 44.3394 33.1351 44.1198 33.1663Z'
              fill='#5C5C5C'
            />
            <path
              d='M42.0184 36.9908L35.1525 37.9239C34.9335 37.9518 34.7121 37.8931 34.5361 37.7604C34.3601 37.6277 34.2435 37.4316 34.2114 37.2143C34.1825 36.996 34.2412 36.7751 34.3747 36.5995C34.5082 36.4238 34.7059 36.3075 34.925 36.2756L41.7909 35.3425C42.01 35.3146 42.2313 35.3733 42.4073 35.506C42.5833 35.6387 42.6999 35.8348 42.732 36.0521C42.7609 36.2704 42.7022 36.4913 42.5687 36.6669C42.4352 36.8426 42.2375 36.9589 42.0184 36.9908Z'
              fill='#5C5C5C'
            />
          </g>
          <path
            d='M30.5456 20.9418L15.1277 22.2778L16.9786 43.4016L32.3965 42.0656L30.5456 20.9418Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.8'
            d='M30.5456 20.9418L15.1277 22.2778L16.9786 43.4016L32.3965 42.0656L30.5456 20.9418Z'
            fill='white'
          />
          <g opacity='0.4'>
            <path
              d='M27.9804 25.6961L18.4822 26.5174C18.2612 26.5353 18.0421 26.4658 17.8722 26.3241C17.7024 26.1824 17.5955 25.9799 17.5748 25.7603C17.5576 25.541 17.6278 25.3238 17.7702 25.1555C17.9127 24.9872 18.1159 24.8814 18.3361 24.8608L27.8343 24.0227C28.0553 24.0048 28.2744 24.0743 28.4443 24.216C28.6141 24.3577 28.721 24.5602 28.7417 24.7798C28.7525 24.8897 28.7414 25.0007 28.709 25.1063C28.6765 25.212 28.6234 25.3102 28.5526 25.3954C28.4819 25.4805 28.3949 25.5509 28.2967 25.6025C28.1985 25.6541 28.091 25.6859 27.9804 25.6961Z'
              fill='#5C5C5C'
            />
            <path
              d='M28.2866 29.1882L18.7885 30.0262C18.5675 30.0441 18.3483 29.9747 18.1785 29.833C18.0086 29.6912 17.9018 29.4887 17.8811 29.2692C17.8631 29.0494 17.9329 28.8315 18.0754 28.6626C18.2179 28.4937 18.4216 28.3874 18.6424 28.3668L28.1406 27.5287C28.3615 27.5109 28.5807 27.5803 28.7505 27.722C28.9204 27.8637 29.0272 28.0663 29.0479 28.2858C29.0659 28.5056 28.9961 28.7235 28.8536 28.8924C28.7111 29.0613 28.5074 29.1676 28.2866 29.1882Z'
              fill='#5C5C5C'
            />
            <path
              d='M28.5928 32.6802L19.0946 33.5183C18.8736 33.5362 18.6545 33.4667 18.4846 33.325C18.3148 33.1833 18.2079 32.9808 18.1872 32.7612C18.1693 32.5415 18.2391 32.3235 18.3816 32.1546C18.5241 31.9857 18.7278 31.8795 18.9485 31.8589L28.4467 31.0208C28.6677 31.0029 28.8869 31.0724 29.0567 31.2141C29.2265 31.3558 29.3334 31.5584 29.3541 31.7779C29.3721 31.9976 29.3022 32.2156 29.1597 32.3845C29.0172 32.5534 28.8135 32.6596 28.5928 32.6802Z'
              fill='#5C5C5C'
            />
            <path
              d='M28.9047 36.1723L19.4065 37.0104C19.1855 37.0282 18.9664 36.9588 18.7965 36.8171C18.6267 36.6754 18.5198 36.4728 18.4991 36.2533C18.4811 36.0335 18.551 35.8156 18.6935 35.6467C18.836 35.4778 19.0397 35.3715 19.2604 35.3509L28.7586 34.5296C28.9796 34.5117 29.1987 34.5812 29.3686 34.7229C29.5384 34.8646 29.6453 35.0672 29.666 35.2867C29.6794 35.5037 29.6074 35.7173 29.4654 35.8826C29.3233 36.0478 29.1223 36.1517 28.9047 36.1723Z'
              fill='#5C5C5C'
            />
            <path
              d='M26.6094 39.8906L19.7071 40.4884C19.4847 40.5086 19.2633 40.4403 19.0916 40.2983C18.9199 40.1564 18.8119 39.9525 18.7912 39.7314C18.7733 39.5116 18.8431 39.2937 18.9856 39.1248C19.1281 38.9559 19.3318 38.8496 19.5525 38.829L26.4549 38.2312C26.6759 38.2133 26.8951 38.2828 27.0649 38.4245C27.2348 38.5662 27.3416 38.7687 27.3623 38.9883C27.3804 39.2067 27.3117 39.4235 27.171 39.5922C27.0303 39.7608 26.8287 39.868 26.6094 39.8906Z'
              fill='#5C5C5C'
            />
          </g>
          <path
            d='M49.3325 19.9155L33.8741 20.6566L34.9009 41.8358L50.3592 41.0947L49.3325 19.9155Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.8'
            d='M49.3325 19.9155L33.8741 20.6566L34.9009 41.8358L50.3592 41.0947L49.3325 19.9155Z'
            fill='white'
          />
          <g opacity='0.4'>
            <path
              d='M46.5863 24.5618L37.0657 25.02C36.8445 25.0292 36.6285 24.9513 36.4645 24.8034C36.3004 24.6555 36.2016 24.4493 36.1892 24.2294C36.18 24.0094 36.2582 23.7946 36.407 23.6315C36.5558 23.4684 36.7631 23.3701 36.9842 23.3578L46.5077 22.8996C46.7289 22.8905 46.9449 22.9683 47.1089 23.1162C47.273 23.2642 47.3718 23.4704 47.3842 23.6902C47.3934 23.9107 47.3147 24.1259 47.1654 24.2891C47.016 24.4522 46.8079 24.5503 46.5863 24.5618Z'
              fill='#5C5C5C'
            />
            <path
              d='M46.7577 28.0651L37.2343 28.5205C37.013 28.5296 36.797 28.4518 36.633 28.3039C36.469 28.1559 36.3701 27.9497 36.3578 27.7298C36.3486 27.5094 36.4272 27.2942 36.5766 27.131C36.726 26.9678 36.934 26.8698 37.1556 26.8582L46.6763 26.4001C46.898 26.3909 47.1143 26.4691 47.2784 26.6177C47.4425 26.7662 47.5411 26.9731 47.5528 27.1935C47.562 27.4135 47.4837 27.6283 47.3349 27.7914C47.1862 27.9545 46.9788 28.0528 46.7577 28.0651Z'
              fill='#5C5C5C'
            />
            <path
              d='M40.0295 31.9091L37.4057 32.0349C37.1844 32.044 36.9684 31.9662 36.8044 31.8183C36.6404 31.6703 36.5415 31.4641 36.5292 31.2443C36.52 31.0242 36.5982 30.8095 36.747 30.6464C36.8958 30.4833 37.1031 30.3849 37.3242 30.3726L39.9481 30.2441C40.1698 30.235 40.3862 30.3132 40.5503 30.4617C40.7143 30.6103 40.8129 30.8172 40.8246 31.0375C40.8338 31.2575 40.7555 31.4723 40.6067 31.6354C40.458 31.7985 40.2506 31.8969 40.0295 31.9091Z'
              fill='#5C5C5C'
            />
            <path
              d='M45.1171 31.6521L42.4932 31.7779C42.2719 31.787 42.056 31.7092 41.8919 31.5613C41.7279 31.4133 41.6291 31.2071 41.6167 30.9873C41.6075 30.7672 41.6857 30.5525 41.8345 30.3894C41.9833 30.2262 42.1906 30.1279 42.4117 30.1156L45.0356 29.9899C45.2568 29.9808 45.4728 30.0586 45.6368 30.2065C45.8008 30.3545 45.8997 30.5607 45.9121 30.7805C45.9213 31.0005 45.843 31.2153 45.6943 31.3784C45.5455 31.5415 45.3382 31.6399 45.1171 31.6521Z'
              fill='#5C5C5C'
            />
            <path
              d='M47.0977 35.0687L37.5742 35.5241C37.353 35.5333 37.137 35.4555 36.973 35.3075C36.809 35.1596 36.7101 34.9534 36.6977 34.7335C36.6886 34.513 36.7672 34.2979 36.9166 34.1347C37.0659 33.9715 37.274 33.8735 37.4956 33.8619L47.0162 33.4037C47.2379 33.3946 47.4543 33.4728 47.6184 33.6213C47.7825 33.7699 47.8811 33.9768 47.8927 34.1971C47.9019 34.4171 47.8237 34.6319 47.6749 34.795C47.5261 34.9581 47.3188 35.0565 47.0977 35.0687Z'
              fill='#5C5C5C'
            />
            <path
              d='M44.6648 38.6949L37.7455 39.0274C37.5243 39.0365 37.3083 38.9587 37.1443 38.8108C36.9802 38.6628 36.8814 38.4566 36.869 38.2368C36.8598 38.0168 36.9381 37.802 37.0868 37.6389C37.2356 37.4758 37.4429 37.3774 37.664 37.3652L44.5833 37.0327C44.8045 37.0236 45.0205 37.1014 45.1845 37.2493C45.3486 37.3973 45.4474 37.6034 45.4598 37.8233C45.469 38.0433 45.3907 38.2581 45.242 38.4212C45.0932 38.5843 44.8859 38.6826 44.6648 38.6949Z'
              fill='#5C5C5C'
            />
          </g>
          <path
            d='M26.3342 36.6137L26.087 35.6946C25.9139 35.0585 25.5117 34.5078 24.957 34.1473C24.4023 33.7867 23.7337 33.6414 23.0783 33.739L9.65275 35.3007C8.83825 35.3999 8.08813 35.7912 7.54302 36.4012C6.99792 37.0112 6.69525 37.7979 6.69177 38.6139L7.45309 70.4196C7.45763 70.6816 7.51852 70.9396 7.63167 71.1762C7.74481 71.4128 7.90759 71.6226 8.10906 71.7914C8.31052 71.9603 8.546 72.0843 8.79967 72.1551C9.05334 72.2259 9.31931 72.2419 9.57971 72.202L57.6184 66.6147C58.1761 66.5474 58.6901 66.2802 59.064 65.8631C59.4379 65.4461 59.6461 64.9078 59.6495 64.349L58.9921 36.8539C58.9872 36.5918 58.9259 36.3338 58.8125 36.0972C58.699 35.8606 58.5359 35.6508 58.3343 35.482C58.1326 35.3132 57.8969 35.1893 57.6431 35.1185C57.3893 35.0477 57.1232 35.0317 56.8627 35.0716L28.9693 38.315C28.3968 38.4019 27.812 38.2767 27.3262 37.963C26.8404 37.6494 26.4874 37.1692 26.3342 36.6137Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.3'
            d='M26.3342 36.6137L26.087 35.6946C25.9139 35.0585 25.5117 34.5078 24.957 34.1473C24.4023 33.7867 23.7337 33.6414 23.0783 33.739L9.65275 35.3007C8.83825 35.3999 8.08813 35.7912 7.54302 36.4012C6.99792 37.0112 6.69525 37.7979 6.69177 38.6139L7.45309 70.4196C7.45763 70.6816 7.51852 70.9396 7.63167 71.1762C7.74481 71.4128 7.90759 71.6226 8.10906 71.7914C8.31052 71.9603 8.546 72.0843 8.79967 72.1551C9.05334 72.2259 9.31931 72.2419 9.57971 72.202L57.6184 66.6147C58.1761 66.5474 58.6901 66.2802 59.064 65.8631C59.4379 65.4461 59.6461 64.9078 59.6495 64.349L58.9921 36.8539C58.9872 36.5918 58.9259 36.3338 58.8125 36.0972C58.699 35.8606 58.5359 35.6508 58.3343 35.482C58.1326 35.3132 57.8969 35.1893 57.6431 35.1185C57.3893 35.0477 57.1232 35.0317 56.8627 35.0716L28.9693 38.315C28.3968 38.4019 27.812 38.2767 27.3262 37.963C26.8404 37.6494 26.4874 37.1692 26.3342 36.6137Z'
            fill='black'
          />
          <path
            d='M73.2912 21.324C73.0833 22.3604 72.8614 23.3829 72.6113 24.397C72.3613 25.4111 72.1169 26.4364 71.8444 27.4505L71.4286 28.9674L71.3753 29.163C71.35 29.2468 71.3219 29.3306 71.2882 29.4172C71.2163 29.6074 71.122 29.7883 71.0073 29.9564C70.8679 30.1651 70.6896 30.3453 70.4819 30.4872C70.2532 30.6414 69.9898 30.7373 69.715 30.7665C69.299 30.7966 68.883 30.7084 68.5154 30.5123C68.2363 30.3612 67.9744 30.1804 67.7344 29.9731C67.3002 29.5956 66.916 29.1647 66.5911 28.6908L67.3917 27.998C67.774 28.2937 68.1777 28.5607 68.5997 28.797C68.783 28.9004 68.9771 28.9837 69.1784 29.0456C69.3498 29.1015 69.5015 29.0931 69.5015 29.0652C69.5015 29.0373 69.4734 29.0652 69.4593 29.0652C69.4453 29.0652 69.4593 29.0652 69.4593 29.0177C69.4593 28.9702 69.493 28.9199 69.5127 28.8473L69.5773 28.5372L69.9032 27.0119L70.5634 23.9612C70.7769 22.9443 71.0073 21.9246 71.2292 20.9189L73.2912 21.324Z'
            fill='#FFC3BD'
          />
          <path
            d='M72.3586 18.1308C69.9623 19.9551 70.0606 23.3941 70.0606 23.3941L74.3448 24.4138C74.3448 24.4138 75.6623 20.9552 75.1286 19.6897C74.5723 18.3711 73.7099 17.1028 72.3586 18.1308Z'
            fill='#263238'
          />
          <path
            d='M67.6053 28.04L66.0293 26.4727L65.1865 28.9255C65.1865 28.9255 66.5181 29.6379 67.7907 28.9647L67.6053 28.04Z'
            fill='#FFC3BD'
          />
          <path
            d='M63.8465 26.2296L63.5571 28.1684L65.1781 28.9255L66.0293 26.4727L63.8465 26.2296Z'
            fill='#FFC3BD'
          />
          <path
            d='M86.2477 82.9939L83.8373 83.2285L81.3286 77.5211L83.739 77.2864L86.2477 82.9939Z'
            fill='#FFC3BD'
          />
          <path
            d='M101.777 74.557L99.9653 76.2472L96.0576 71.9757L97.8696 70.2855L101.777 74.557Z'
            fill='#FFC3BD'
          />
          <path
            d='M99.1366 75.437L100.496 73.001C100.519 72.9583 100.557 72.9254 100.602 72.9084C100.648 72.8914 100.698 72.8916 100.744 72.9088L102.836 73.7022C102.888 73.7238 102.935 73.756 102.973 73.7967C103.011 73.8375 103.041 73.8858 103.059 73.9385C103.077 73.9912 103.084 74.0472 103.079 74.1027C103.074 74.1583 103.058 74.2122 103.03 74.2609C102.541 75.099 102.263 75.4845 101.665 76.5573C101.297 77.2166 100.581 78.728 100.072 79.6303C99.5636 80.5327 98.631 80.0913 98.7602 79.6666C99.3417 77.7614 99.2322 76.7026 99.0692 75.8645C99.0413 75.7185 99.0652 75.5675 99.1366 75.437Z'
            fill='#263238'
          />
          <path
            d='M83.517 82.4882H86.4779C86.5312 82.4883 86.5829 82.5059 86.6251 82.5382C86.6672 82.5705 86.6975 82.6158 86.7111 82.667L87.3544 84.9941C87.3702 85.049 87.3729 85.1069 87.3622 85.163C87.3514 85.2192 87.3276 85.272 87.2927 85.3174C87.2577 85.3627 87.2126 85.3993 87.1609 85.4242C87.1092 85.4491 87.0523 85.4616 86.9949 85.4607C85.9498 85.4607 85.4554 85.4607 84.1547 85.4607H80.1767C79.0924 85.4607 78.8676 84.3739 79.3143 84.2734C81.3229 83.832 82.4775 83.2229 83.0984 82.6419C83.2135 82.5401 83.363 82.4853 83.517 82.4882Z'
            fill='#263238'
          />
          <path
            opacity='0.2'
            d='M81.3314 77.5239L82.6237 80.4656H85.138L83.7418 77.2892L81.3314 77.5239Z'
            fill='black'
          />
          <path
            opacity='0.2'
            d='M97.8696 70.2883L96.0576 71.9785L98.0747 74.1799L99.8867 72.4869L97.8696 70.2883Z'
            fill='black'
          />
          <path
            d='M73.0833 17.7789C73.0833 17.7789 71.9989 18.251 75.6341 32.5545L85.7307 31.6857C85.2081 27.6153 84.9834 25.0954 86.2167 16.5385C84.7784 16.3693 83.3296 16.3049 81.8819 16.3457C80.3462 16.3686 78.8141 16.5002 77.2972 16.7396C75.8758 17.0149 74.4693 17.3617 73.0833 17.7789Z'
            fill='#263238'
          />
          <path
            d='M81.882 16.2647C81.882 16.2647 82.4439 16.2647 83.1293 16.2647C83.5367 16.8877 82.2163 19.6171 80.0391 21.4022C79.7076 20.4098 79.0985 19.5318 78.2833 18.8712L81.882 16.2647Z'
            fill='white'
          />
          <path
            d='M77.2973 16.7033C77.2973 16.7033 77.0417 16.7396 76.4321 16.8793C76.0247 17.5023 75.3364 20.1646 76.536 21.5224C76.838 20.4928 77.4438 19.577 78.275 18.8935L77.2973 16.7033Z'
            fill='white'
          />
          <path
            d='M80.9803 11.2501C80.8201 12.7419 80.764 15.4406 81.882 16.3457C81.882 16.3457 80.6881 17.5582 78.2749 18.8935C76.5191 17.6867 77.2973 16.7396 77.2973 16.7396C78.907 16.2088 78.7581 14.9992 78.3733 13.8817L80.9803 11.2501Z'
            fill='#FFC3BD'
          />
          <path
            d='M78.2328 18.9019C78.2328 18.9019 79.7133 17.2984 80.4802 17.6057C81.2471 17.913 80.6544 18.4633 80.587 18.9801C80.5195 19.497 80.8847 20.8714 79.8594 20.5139C78.834 20.1563 78.2328 18.9019 78.2328 18.9019Z'
            fill='#5C5C5C'
          />
          <path
            d='M78.1737 18.8684C78.1737 18.8684 77.4742 17.6392 76.7185 17.804C75.9628 17.9688 76.4123 18.399 76.4797 18.8628C76.5668 19.455 75.9376 20.5949 76.8478 20.2596C77.758 19.9244 78.1737 18.8684 78.1737 18.8684Z'
            fill='#5C5C5C'
          />
          <g opacity='0.2'>
            <path
              d='M77.7861 18.603C77.776 18.8508 77.824 19.0976 77.9266 19.3238C78.0196 19.1793 78.1013 19.028 78.171 18.8712C78.0672 18.696 77.9478 18.5305 77.8142 18.3767C77.7911 18.4498 77.7815 18.5265 77.7861 18.603Z'
              fill='black'
            />
            <path
              d='M78.7722 18.3823C78.4547 18.6617 78.2356 18.9019 78.2356 18.9019C78.3172 19.0696 78.4111 19.2312 78.5165 19.3852C78.6862 19.2159 78.7864 18.9898 78.7975 18.7511C78.8244 18.6282 78.8156 18.5003 78.7722 18.3823Z'
              fill='black'
            />
          </g>
          <path
            d='M78.2159 19.4048C78.0052 19.4048 77.876 19.1785 77.8619 18.6393C77.8479 18.1001 78.2637 18.399 78.2637 18.399C78.2637 18.399 78.8255 18.1197 78.7244 18.765C78.6513 19.2763 78.4322 19.4048 78.2159 19.4048Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.2'
            d='M79.9183 12.3257L78.376 13.8817C78.4684 14.1408 78.5389 14.4071 78.5867 14.6779C79.2075 14.5354 80.1065 13.8929 80.1149 13.2336C80.1049 12.9216 80.0382 12.6141 79.9183 12.3257Z'
            fill='black'
          />
          <path
            d='M78.5952 6.20757C78.5952 6.20757 76.9096 8.5291 76.6287 9.55996C75.4825 10.2248 75.2521 6.95348 75.8589 4.93646C77.7524 6.11818 78.5952 6.20757 78.5952 6.20757Z'
            fill='#263238'
          />
          <path
            d='M82.3006 8.92019C81.6741 11.3032 81.4578 12.3256 79.9914 13.323C77.7805 14.826 74.974 13.5409 74.87 11.0182C74.7773 8.74977 75.8168 5.23257 78.3816 4.73809C78.9469 4.62399 79.5321 4.656 80.0813 4.83107C80.6305 5.00614 81.1254 5.3184 81.5186 5.738C81.9118 6.1576 82.1902 6.67049 82.3271 7.22764C82.464 7.78478 82.4549 8.36754 82.3006 8.92019Z'
            fill='#FFC3BD'
          />
          <path
            d='M83.6041 7.96756C83.6041 7.96756 82.3989 9.90915 82.236 10.7612C81.3623 11.3507 80.9943 8.76375 81.3623 7.12947C81.764 5.67397 83.6041 7.96756 83.6041 7.96756Z'
            fill='#263238'
          />
          <path
            d='M83.1237 6.61823C82.7753 6.88083 82.4073 5.91143 82.7529 5.16832C82.0871 5.21023 81.2864 4.81632 81.4578 4.17658C80.1459 4.61518 79.6598 3.2882 79.8115 2.36908C79.9941 1.25162 80.9718 1.01416 81.5477 1.3075C81.5477 0.807433 82.8175 -0.234599 83.3007 0.581147C84.1968 0.265465 85.6408 0.363242 85.6099 1.45835C86.2027 1.47512 87.9528 2.1428 87.2955 3.69327C88.068 3.73797 88.4866 4.71296 88.0568 5.45048C88.2261 5.53966 88.3713 5.66806 88.4802 5.82483C88.5892 5.98159 88.6585 6.16212 88.6825 6.35116C88.7065 6.5402 88.6843 6.7322 88.618 6.91094C88.5516 7.08968 88.4429 7.24991 88.3012 7.3781C88.658 8.11563 87.5764 9.21353 87.0005 9.08223C86.5622 9.74991 85.8908 10.2528 85.4526 9.68286C84.6098 10.3003 83.6406 9.51524 83.8569 8.91181C82.9018 8.76654 81.9269 7.18255 83.1237 6.61823Z'
            fill='#263238'
          />
          <path
            d='M84.5508 4.27157C85.1801 5.3164 85.1267 6.50649 84.4356 6.91157C83.7445 7.31665 82.6686 6.81379 82.0393 5.76897C81.41 4.72414 81.4606 3.53405 82.1545 3.12897C82.8484 2.72389 83.9215 3.22954 84.5508 4.27157Z'
            fill='#5C5C5C'
          />
          <path
            d='M78.7075 5.92821C78.671 6.22713 77.8423 6.1489 77.6035 5.6209C77.2608 5.97849 76.4545 6.16287 76.1792 5.77455C75.7129 6.64617 74.7746 6.41709 74.533 6.00084C74.2914 5.58458 74.1312 4.81074 74.6172 4.65709C74.3363 4.41684 74.4206 3.28541 75.1566 3.42789C75.4656 2.82446 76.3112 2.14001 76.9293 2.6736C77.2664 2.39424 78.6148 1.81316 79.1598 2.87754C79.2794 2.79631 79.4159 2.74308 79.5591 2.72185C79.7022 2.70061 79.8484 2.71193 79.9866 2.75495C80.1247 2.79797 80.2513 2.87157 80.3567 2.97022C80.4622 3.06887 80.5438 3.19001 80.5954 3.32452C81.4691 3.09824 81.8483 3.75754 81.8455 4.11513C82.4664 4.28275 82.5085 5.34992 82.1264 5.579C82.2753 6.11817 82.1967 6.69646 81.6264 6.64618C81.5168 7.37253 80.5392 7.48427 80.3088 7.09037C79.6739 7.49544 78.3789 6.79144 78.7075 5.92821Z'
            fill='#263238'
          />
          <path
            d='M77.2242 3.86647C77.935 2.88869 80.528 2.46964 82.7726 3.94749C84.7952 5.26888 84.6632 8.3391 83.0535 8.17428C81.4438 8.00945 79.8537 6.13492 79.8537 6.13492L77.2242 3.86647Z'
            fill='#263238'
          />
          <path
            d='M82.4972 11.0713C82.2733 11.4914 81.8916 11.8066 81.4353 11.9485C80.8257 12.1301 80.511 11.5937 80.6684 11.0154C80.8116 10.4958 81.2976 9.77504 81.9129 9.84768C82.0471 9.86542 82.1747 9.91625 82.2841 9.99554C82.3934 10.0748 82.4812 10.1801 82.5392 10.3017C82.5973 10.4233 82.6239 10.5574 82.6165 10.6918C82.6092 10.8262 82.5682 10.9567 82.4972 11.0713Z'
            fill='#FFC3BD'
          />
          <path
            d='M75.6342 32.5545C75.6342 32.5545 72.2182 47.5843 74.5105 58.1806C76.0388 65.2765 81.5337 79.6107 81.5337 79.6107L85.8432 79.5018C85.8432 79.5018 81.6714 64.458 80.8089 58.5494C79.3341 48.4308 83.0283 31.9287 83.0283 31.9287L75.6342 32.5545Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.2'
            d='M79.5728 35.5912L81.7191 38.9687C81.0703 42.9734 80.6584 47.0125 80.4858 51.0652C77.4658 45.6735 78.9098 38.4435 79.5728 35.5912Z'
            fill='black'
          />
          <path
            d='M80.9269 79.8342L86.2476 79.6973L85.9414 78.0742L80.5111 78.3284L80.9269 79.8342Z'
            fill='#263238'
          />
          <path
            d='M77.8423 32.3952C77.8423 32.3952 79.8453 48.333 84.1912 57.0352C87.7 64.0641 97.0634 74.0765 97.0634 74.0765L99.4569 71.6237C99.4569 71.6237 93.1332 60.7508 90.5262 56.0826C84.1294 44.6286 89.3575 35.8985 85.7307 31.694L77.8423 32.3952Z'
            fill='#5C5C5C'
          />
          <path
            d='M96.8218 74.3978L99.912 71.403L98.8866 70.0145L95.6082 73.2663L96.8218 74.3978Z'
            fill='#263238'
          />
          <path
            d='M85.8319 31.085L86.3657 31.9455C86.4078 32.0097 86.3291 32.0879 86.214 32.0991L75.623 33.0098C75.5331 33.0098 75.4544 32.9791 75.4432 32.9232L75.2746 32.0321C75.2606 31.9706 75.3364 31.9119 75.4348 31.9036L85.6605 31.0236C85.692 31.0177 85.7245 31.0203 85.7546 31.0311C85.7847 31.0419 85.8114 31.0605 85.8319 31.085Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.5'
            d='M85.8319 31.085L86.3657 31.9455C86.4078 32.0097 86.3291 32.0879 86.214 32.0991L75.623 33.0098C75.5331 33.0098 75.4544 32.9791 75.4432 32.9232L75.2746 32.0321C75.2606 31.9706 75.3364 31.9119 75.4348 31.9036L85.6605 31.0236C85.692 31.0177 85.7245 31.0203 85.7546 31.0311C85.7847 31.0419 85.8114 31.0605 85.8319 31.085Z'
            fill='white'
          />
          <path
            d='M84.5564 32.3394L84.8373 32.317C84.8907 32.317 84.93 32.2807 84.9244 32.2472L84.694 31.0906C84.694 31.0543 84.6379 31.0319 84.5817 31.0375L84.3007 31.0599C84.2446 31.0599 84.2052 31.0962 84.2137 31.1297L84.444 32.2863C84.4525 32.3198 84.503 32.345 84.5564 32.3394Z'
            fill='#5C5C5C'
          />
          <path
            d='M76.4011 33.0406L76.682 33.0182C76.7354 33.0182 76.7748 32.9819 76.7691 32.9484L76.5388 31.7918C76.5287 31.7718 76.5121 31.7557 76.4918 31.7461C76.4714 31.7364 76.4484 31.7339 76.4264 31.7387L76.1455 31.7611C76.0893 31.7611 76.05 31.7974 76.0584 31.8309L76.2888 32.9875C76.2972 33.0266 76.3477 33.0462 76.4011 33.0406Z'
            fill='#5C5C5C'
          />
          <path
            d='M80.4802 32.6914L80.7612 32.6662C80.8173 32.6662 80.8567 32.6299 80.8482 32.5964L80.6179 31.4398C80.6179 31.4063 80.5617 31.3811 80.5083 31.3867L80.2274 31.4119C80.174 31.4119 80.1347 31.4482 80.1403 31.4817L80.3707 32.6383C80.3763 32.6718 80.424 32.6942 80.4802 32.6914Z'
            fill='#5C5C5C'
          />
          <path
            d='M78.244 8.83917C78.2047 9.03473 78.0699 9.17441 77.9406 9.14927C77.8114 9.12413 77.7384 8.94254 77.7749 8.74698C77.8114 8.55143 77.9519 8.41174 78.0811 8.43689C78.2103 8.46203 78.2834 8.64082 78.244 8.83917Z'
            fill='#263238'
          />
          <path
            d='M76.0074 8.40018C76.0463 8.20337 75.973 8.02332 75.8436 7.99803C75.7142 7.97274 75.5777 8.11178 75.5388 8.30859C75.4999 8.5054 75.5733 8.68545 75.7027 8.71075C75.8321 8.73604 75.9685 8.59699 76.0074 8.40018Z'
            fill='#263238'
          />
          <path
            d='M75.9038 8.02342L75.4543 7.79434C75.4543 7.79434 75.6257 8.2106 75.9038 8.02342Z'
            fill='#263238'
          />
          <path
            d='M76.7775 8.89227C76.5144 9.30656 76.1949 9.68262 75.828 10.0097C75.9132 10.103 76.018 10.1765 76.1351 10.2248C76.2521 10.2731 76.3785 10.295 76.505 10.2891L76.7775 8.89227Z'
            fill='#ED847E'
          />
          <path
            d='M77.4181 11.211C77.8331 11.2154 78.2345 11.0637 78.5418 10.7864C78.5478 10.7819 78.5527 10.7763 78.5562 10.7698C78.5597 10.7633 78.5617 10.7561 78.5621 10.7487C78.5625 10.7413 78.5613 10.7339 78.5585 10.7271C78.5557 10.7202 78.5514 10.7141 78.5459 10.7091C78.5405 10.7041 78.5339 10.7003 78.5269 10.6981C78.5198 10.6959 78.5123 10.6952 78.5049 10.6962C78.4976 10.6972 78.4905 10.6997 78.4843 10.7037C78.478 10.7077 78.4727 10.713 78.4688 10.7193C78.2797 10.8851 78.0539 11.0041 77.8098 11.0667C77.5658 11.1294 77.3103 11.1338 77.0641 11.0797C77.0578 11.0785 77.0514 11.0786 77.0451 11.0799C77.0389 11.0812 77.033 11.0838 77.0277 11.0874C77.0225 11.0911 77.018 11.0957 77.0146 11.1011C77.0112 11.1065 77.009 11.1125 77.0079 11.1188C77.0063 11.1251 77.006 11.1316 77.0072 11.138C77.0083 11.1444 77.0109 11.1505 77.0146 11.1558C77.0183 11.1611 77.0232 11.1656 77.0288 11.1688C77.0345 11.1721 77.0408 11.1741 77.0473 11.1747C77.1692 11.2001 77.2935 11.2123 77.4181 11.211Z'
            fill='#263238'
          />
          <path
            d='M79.5841 8.07652C79.6018 8.07625 79.6193 8.07144 79.6346 8.06256C79.657 8.04921 79.6731 8.02759 79.6794 8.00245C79.6857 7.9773 79.6818 7.95069 79.6684 7.92846C79.5754 7.77148 79.4461 7.63887 79.2912 7.54168C79.1363 7.44449 78.9603 7.38553 78.7778 7.36973C78.7649 7.36936 78.752 7.37152 78.74 7.37609C78.7279 7.38066 78.7168 7.38756 78.7074 7.39638C78.6981 7.4052 78.6905 7.41578 78.6852 7.4275C78.6799 7.43923 78.677 7.45187 78.6767 7.46471C78.6763 7.47756 78.6785 7.49034 78.6831 7.50235C78.6877 7.51436 78.6946 7.52534 78.7035 7.53468C78.7123 7.54402 78.723 7.55153 78.7348 7.55679C78.7466 7.56204 78.7593 7.56492 78.7722 7.56529C78.9229 7.58177 79.0675 7.6337 79.194 7.71677C79.3205 7.79984 79.4253 7.91167 79.4998 8.043C79.5101 8.05491 79.5231 8.06418 79.5378 8.07001C79.5525 8.07585 79.5683 8.07808 79.5841 8.07652Z'
            fill='#263238'
          />
          <path
            d='M75.3926 7.28871C75.4083 7.2923 75.4247 7.2923 75.4404 7.28871C75.5709 7.21191 75.7178 7.16683 75.8691 7.15712C76.0204 7.14741 76.1719 7.17335 76.3113 7.23284C76.334 7.24231 76.3596 7.2429 76.3828 7.23449C76.406 7.22608 76.4252 7.20929 76.4365 7.18746C76.4478 7.16563 76.4505 7.14036 76.4439 7.11668C76.4374 7.09299 76.4221 7.07263 76.4012 7.05964C76.2329 6.98384 76.0488 6.94959 75.8644 6.9598C75.68 6.97002 75.5008 7.02439 75.342 7.1183C75.32 7.13116 75.3038 7.15207 75.297 7.17659C75.2902 7.2011 75.2933 7.22729 75.3055 7.2496C75.3151 7.26337 75.3283 7.27429 75.3436 7.28118C75.359 7.28807 75.3759 7.29067 75.3926 7.28871Z'
            fill='#263238'
          />
          <path
            d='M86.6465 18.8237C86.8376 19.0081 86.9696 19.1533 87.1185 19.3182C87.2674 19.483 87.3994 19.645 87.5343 19.8099C87.8011 20.1423 88.0568 20.4831 88.2956 20.8323C88.7704 21.5364 89.1911 22.2751 89.5541 23.0421C89.9163 23.8128 90.2087 24.614 90.4278 25.4363C90.5374 25.8469 90.6357 26.2744 90.7087 26.685C90.8481 27.2783 90.7797 27.9012 90.5149 28.4506C90.3607 28.7392 90.1473 28.9924 89.8884 29.1937C89.664 29.3654 89.4174 29.5063 89.1552 29.6128C88.7043 29.7857 88.2317 29.8966 87.7506 29.9424C86.8969 30.024 86.0369 30.0127 85.1857 29.9089C83.5561 29.7205 81.9479 29.381 80.3818 28.8948L80.6403 27.8807C81.4044 28.0064 82.191 28.1238 82.9664 28.2048C83.7417 28.2858 84.5227 28.3584 85.2896 28.3752C86.0326 28.4018 86.7764 28.3607 87.5118 28.2523C87.8284 28.2011 88.1366 28.1071 88.4276 27.9729C88.606 27.9058 88.7531 27.7754 88.8406 27.6069C88.8641 27.5425 88.8756 27.4743 88.8743 27.4058C88.8782 27.3556 88.8782 27.3051 88.8743 27.2549C88.8743 27.1991 88.8546 27.1488 88.8293 27.0454C88.7507 26.685 88.6467 26.3302 88.5344 25.9782C88.3057 25.2764 88.024 24.5928 87.6916 23.9333C87.3576 23.2699 86.9745 22.6321 86.5454 22.0252C86.3347 21.7179 86.1128 21.419 85.8824 21.1313C85.7672 20.986 85.6464 20.8519 85.5284 20.7094C85.4104 20.5669 85.2756 20.43 85.1829 20.3295L86.6465 18.8237Z'
            fill='#FFC3BD'
          />
          <path
            d='M81.2837 28.0344L79.3172 26.9979L79.2273 29.5876C79.2273 29.5876 80.6319 29.677 81.4354 29.0289L81.2837 28.0344Z'
            fill='#FFC3BD'
          />
          <path
            d='M77.8339 41.9663L79.4576 24.4194L75.9882 21.7766L65.3972 20.8072L63.5571 40.6589L77.8339 41.9663Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.8'
            d='M77.8339 41.9663L79.4576 24.4194L75.9882 21.7766L65.3972 20.8072L63.5571 40.6589L77.8339 41.9663Z'
            fill='white'
          />
          <path
            d='M79.4576 24.4194L75.9882 21.7766L76.8984 24.939L79.4576 24.4194Z'
            fill='#5C5C5C'
          />
          <path
            d='M64.7988 43.7263C67.1882 43.7263 69.1251 41.8001 69.1251 39.4241C69.1251 37.048 67.1882 35.1219 64.7988 35.1219C62.4095 35.1219 60.4725 37.048 60.4725 39.4241C60.4725 41.8001 62.4095 43.7263 64.7988 43.7263Z'
            fill='#5C5C5C'
          />
          <path
            d='M66.9363 42.113C68.4313 40.9384 68.6857 38.7811 67.5046 37.2944C66.3235 35.8077 64.1541 35.5546 62.6591 36.7292C61.1641 37.9037 60.9096 40.0611 62.0907 41.5478C63.2718 43.0344 65.4413 43.2875 66.9363 42.113Z'
            fill='white'
          />
          <path
            d='M66.931 39.1223L65.4084 38.9463L65.5853 37.4322L64.4953 37.3037L64.3184 38.8178L62.7957 38.639L62.6665 39.7258L64.1891 39.9046L64.0093 41.4159L65.1022 41.5444L65.2791 40.0303L66.8018 40.2091L66.931 39.1223Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.4'
            d='M76.8674 28.0567C76.8533 28.2103 76.8089 28.3596 76.7367 28.4961C76.6646 28.6325 76.5661 28.7535 76.4468 28.8521C76.3276 28.9507 76.19 29.025 76.0419 29.0707C75.8937 29.1163 75.738 29.1325 75.5836 29.1183L67.8581 28.4115C67.5465 28.3824 67.2592 28.2318 67.0591 27.9925C66.859 27.7532 66.7624 27.4448 66.7906 27.1348C66.8047 26.9812 66.8491 26.8319 66.9213 26.6954C66.9934 26.5589 67.092 26.4379 67.2112 26.3394C67.3304 26.2408 67.468 26.1665 67.6162 26.1208C67.7643 26.0752 67.92 26.059 68.0744 26.0732L75.7999 26.78C75.9543 26.794 76.1045 26.8382 76.2417 26.91C76.379 26.9817 76.5007 27.0797 76.5998 27.1983C76.699 27.3169 76.7736 27.4537 76.8195 27.601C76.8655 27.7483 76.8817 27.9032 76.8674 28.0567Z'
            fill='#5C5C5C'
          />
          <path
            opacity='0.4'
            d='M74.2352 31.2275C74.2211 31.3811 74.1767 31.5304 74.1045 31.6669C74.0323 31.8033 73.9338 31.9244 73.8146 32.0229C73.6953 32.1215 73.5577 32.1958 73.4096 32.2415C73.2615 32.2871 73.1058 32.3033 72.9514 32.2891L67.5519 31.7946C67.3975 31.7806 67.2474 31.7364 67.1101 31.6646C66.9729 31.5929 66.8512 31.4949 66.752 31.3763C66.6529 31.2577 66.5782 31.1209 66.5323 30.9736C66.4864 30.8263 66.4701 30.6715 66.4844 30.5179C66.4985 30.3643 66.5429 30.215 66.6151 30.0786C66.6873 29.9421 66.7858 29.8211 66.905 29.7225C67.0243 29.6239 67.1619 29.5496 67.31 29.504C67.4581 29.4583 67.6138 29.4421 67.7682 29.4563L73.1677 29.9536C73.4787 29.9827 73.7656 30.1329 73.9656 30.3716C74.1656 30.6103 74.2625 30.918 74.2352 31.2275Z'
            fill='#5C5C5C'
          />
          <path
            d='M78.1147 27.1516L78.4097 29.0876L79.2244 29.5876L79.3143 26.9979L78.1147 27.1516Z'
            fill='#FFC3BD'
          />
          <path
            d='M86.214 16.5441C87.4529 16.762 89.189 20.2932 89.189 20.2932L86.0679 23.0868C86.0679 23.0868 83.5845 20.3937 83.7165 18.7902C83.8317 17.4353 84.6408 16.2647 86.214 16.5441Z'
            fill='#263238'
          />
          <path
            d='M98.6535 76.7472C98.6858 76.7467 98.7179 76.742 98.749 76.7333C99.0468 76.6327 99.2406 76.0879 99.3305 75.7723C99.3324 75.7631 99.3318 75.7537 99.3288 75.7449C99.3259 75.736 99.3206 75.7281 99.3136 75.722C99.3061 75.7158 99.2967 75.7125 99.287 75.7125C99.2772 75.7125 99.2678 75.7158 99.2603 75.722C99.1704 75.7667 98.3781 76.1885 98.3529 76.4986C98.3492 76.5324 98.3551 76.5666 98.37 76.5972C98.3849 76.6278 98.4082 76.6537 98.4371 76.6718C98.4984 76.7209 98.5748 76.7475 98.6535 76.7472ZM99.2153 75.8589C99.0777 76.2947 98.9007 76.5824 98.7321 76.6383C98.6939 76.65 98.6533 76.6516 98.6144 76.6427C98.5754 76.6339 98.5395 76.615 98.5102 76.588C98.4951 76.5794 98.4828 76.5667 98.4748 76.5513C98.4668 76.536 98.4635 76.5186 98.4652 76.5014C98.4652 76.3114 98.9288 76.0125 99.2013 75.8589H99.2153Z'
            fill='#5C5C5C'
          />
          <path
            d='M98.5298 75.9818C98.7997 75.9806 99.0657 75.9185 99.308 75.8002C99.3149 75.7956 99.3206 75.7894 99.3245 75.7821C99.3284 75.7747 99.3305 75.7666 99.3305 75.7583C99.3308 75.7496 99.3286 75.741 99.3241 75.7336C99.3196 75.7262 99.313 75.7202 99.3052 75.7164C99.2799 75.7024 98.6534 75.3588 98.3163 75.4789C98.2739 75.4913 98.235 75.5134 98.2028 75.5435C98.1705 75.5736 98.1459 75.6108 98.1309 75.6521C98.1098 75.6913 98.1028 75.7364 98.111 75.7801C98.1192 75.8238 98.142 75.8634 98.1758 75.8924C98.2805 75.9606 98.4051 75.992 98.5298 75.9818ZM99.1731 75.7583C98.9063 75.8728 98.3865 75.9427 98.2405 75.8198C98.2152 75.7974 98.1899 75.7639 98.2236 75.6884C98.2336 75.6607 98.25 75.6356 98.2715 75.6153C98.2929 75.5949 98.3189 75.5798 98.3472 75.5711C98.5748 75.4901 98.9962 75.6717 99.1731 75.7695V75.7583Z'
            fill='#5C5C5C'
          />
          <path
            d='M82.2051 82.9631C82.4767 82.9566 82.7469 82.922 83.0113 82.8598C83.0206 82.8565 83.0286 82.8504 83.0342 82.8424C83.0399 82.8344 83.0429 82.8248 83.0429 82.8151C83.0429 82.8053 83.0399 82.7957 83.0342 82.7877C83.0286 82.7797 83.0206 82.7737 83.0113 82.7704C82.8905 82.7117 81.8146 82.2116 81.5449 82.4016C81.5201 82.4176 81.5004 82.4403 81.4879 82.467C81.4755 82.4937 81.4709 82.5233 81.4746 82.5525C81.4753 82.6071 81.4889 82.6609 81.5142 82.7094C81.5396 82.758 81.5761 82.7999 81.6207 82.8318C81.7965 82.9364 82.0012 82.9824 82.2051 82.9631ZM82.8428 82.7955C82.5253 82.8542 81.9354 82.9324 81.691 82.7508C81.6596 82.7263 81.6339 82.6954 81.6155 82.6603C81.5971 82.6251 81.5864 82.5864 81.5842 82.5469C81.5817 82.5346 81.5831 82.5219 81.5881 82.5104C81.5932 82.4989 81.6016 82.4892 81.6123 82.4826C81.7612 82.3792 82.3961 82.5999 82.8428 82.7955Z'
            fill='#5C5C5C'
          />
          <path
            d='M83 82.8625C83.011 82.8658 83.0228 82.8658 83.0338 82.8625C83.0408 82.8564 83.0459 82.8484 83.0484 82.8395C83.0508 82.8306 83.0507 82.8211 83.0478 82.8122C83.0478 82.7675 82.795 81.7143 82.205 81.7814C82.0589 81.8009 82.0168 81.8708 82.0112 81.9295C81.9747 82.2088 82.6517 82.7368 82.9748 82.8709L83 82.8625ZM82.264 81.8652C82.6377 81.8652 82.854 82.4798 82.927 82.7256C82.5758 82.5413 82.0842 82.1027 82.1151 81.9322C82.1151 81.9183 82.1151 81.8792 82.2247 81.868L82.264 81.8652Z'
            fill='#5C5C5C'
          />
          <path
            d='M78.1344 8.45924L77.6877 8.23016C77.6877 8.23016 77.8591 8.64642 78.1344 8.45924Z'
            fill='#263238'
          />
        </g>
        <defs>
          <clipPath id='clip0_108_186'>
            <rect width='109' height='88' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
