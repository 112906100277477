import React from 'react'

export const ArrowRightLong = () => {
  return (
    <>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M13.8333 5L20.5 12M20.5 12L13.8333 19M20.5 12L4.5 12'
          stroke='white'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
