import React, { useState, useEffect } from 'react'
import {
  Container, Card, CardContent, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Autocomplete, CircularProgress, Grid, useMediaQuery,
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material'
import { toast } from 'react-hot-toast'
import { useAddWebhookMutation, useGetAllWebhooksMutation, useDeleteWebhookMutation, useGetAllIntegrationLogsMutation, useSendDummyDataMutation } from '../services/integration-service'
import { useGetCampaignsQuery } from 'src/services/campaign-service.js'
import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import InboxPagination from 'src/components/InboxPagination'
import DeleteWebhookModal from 'src/DeleteWebhookModal'
import PremiumFeatureDialog from 'src/utils/premiumFeatureDialog'
import UserVerification from 'src/components/emailAccounts/UserVerification'
import { useSelector } from 'react-redux'

const eventOptions = [
  { icon: <EmailIcon />, text: 'Email Sent', value: 'email_sent' },
  { icon: <ErrorIcon />, text: 'Email Bounced', value: 'email_bounced' },
  { icon: <EmailIcon />, text: 'Email Opened', value: 'email_opened' },
  { icon: <CheckCircleIcon />, text: 'Reply Received', value: 'reply_received' },
  { icon: <EmailIcon />, text: 'Email Link Clicked', value: 'email_link_clicked' },
  { icon: <ThumbUpIcon />, text: 'Lead is marked as interested', value: 'lead_marked_interested' },
  { icon: <ThumbDownIcon />, text: 'Lead is marked as not interested', value: 'lead_marked_not_interested' },
  { icon: <EmailIcon />, text: 'Lead is marked as neutral', value: 'lead_marked_neutral' },
  { icon: <CheckCircleIcon />, text: 'Campaign Completed', value: 'campaign_completed' },
  { icon: <CloseIcon />, text: 'Lead status changed to "Meeting Booked"', value: 'meeting_booked' },
  { icon: <ThumbUpIcon />, text: 'Lead status changed to "Meeting Complete | Not Closed"', value: 'meeting_complete_not_closed' },
  { icon: <CloseIcon />, text: 'Lead status changed to "Closed"', value: 'closed' },
  { icon: <EmailIcon />, text: 'Lead status changed to "Out of Office"', value: 'out_of_office' },
  { icon: <ErrorIcon />, text: 'Lead status changed to "Wrong Person"', value: 'wrong_person' },
  { icon: <EmailIcon />, text: 'Lead Unsubscribed', value: 'lead_unsubscribed' },
  { icon: <ErrorIcon />, text: 'Email Account Error', value: 'email_account_error' }
]

const IntegrationDialog = ({
  open,
  handleClose,
  setIntegration,
  eventType,
  setEventType,
  selectedCampaign,
  setSelectedCampaign,
  webhookUrl,
  setWebhookUrl,
  isCampaign,
  setIsCampaign,
  setWebhookError,
  setEventError,
  setCampaignError,
  webhookError,
  eventError,
  campaignError,
  workspaceId
}) => {
  const { data: campaigns } = useGetCampaignsQuery({
    unibox: true,
    option: true,
    workspaceId
  })
  const campaignsList = campaigns?.updatedEmail?.map((e) => e) || []
  const [addWebhook] = useAddWebhookMutation()
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
  const [isTestButtonEnabled, setIsTestButtonEnabled] = useState(false)
  const [sendDummyData] = useSendDummyDataMutation()

  useEffect(() => {
    if (webhookUrl && eventType && selectedCampaign?.id && selectedCampaign?.name) {
      setIsTestButtonEnabled(true)
    } else {
      setIsTestButtonEnabled(false)
    }
  }, [webhookUrl, eventType, selectedCampaign])

  const handleTestWebhook = async () => {
    const { message } = await sendDummyData({
      webhookUrl,
      event: eventType?.value,
      campaign: selectedCampaign
    }).unwrap()
    setIsTestButtonEnabled(false)
    toast.success(message, { duration: 2000 })
  }

  const handleAddWebhook = async () => {
    if (webhookUrl === '') {
      setWebhookError('This field is required')
      return
    }
    if (!eventType) {
      setEventError('This field is required')
      return
    }
    if (!selectedCampaign?.id || !selectedCampaign?.name) {
      setCampaignError('This field is required')
      return
    }
    if (webhookUrl && eventType && selectedCampaign?.id && selectedCampaign?.name && !webhookError) {
      setIsCampaign(true)
      setIntegration(true)
      const toastId = toast.loading('Adding webhook...', { duration: Infinity })
      try {
        const { message } = await addWebhook({
          webhookUrl,
          event: eventType?.value,
          campaign: selectedCampaign,
          workspaceId
        }).unwrap()

        if (message.includes('Webhook with this event name and campaign already exists.')) {
          toast.error(message, { id: toastId, duration: 2000 })
        } else {
          toast.success(message, { id: toastId, duration: 2000 })
          setIsCampaign(false)
          setIntegration(false)
          handleClose()
        }
      } catch (error) {
        console.error(error)
        const errorMessage = error?.data?.error?.message || 'An unexpected error occurred'
        toast.error(errorMessage, { id: toastId, duration: 2000 })
        setIsCampaign(false)
        setIntegration(false)
      }
    }
  }

  const handleChange = (e) => {
    const value = e.target.value
    setWebhookUrl(value)

    if (value === '') {
      setWebhookError('This field is required')
    } else if (!urlRegex.test(value)) {
      setWebhookError('Invalid URL format')
    } else {
      setWebhookError('')
    }
  }
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle sx={{ fontSize: '20px', fontWeight: 700, lineHeight: '28px', color: '#28287B' }}>
        Add Webhook
      </DialogTitle>
      <DialogContent>
        <Box>
          <TextField
            value={webhookUrl}
            onChange={handleChange}
            label='Webhook URL'
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            error={!!webhookError}
            helperText={webhookError}
            required
          />
          <Autocomplete
            freeSolo
            value={eventType}
            onChange={(e, newValue) => {
              setEventType(newValue)
              if (newValue) {
                setEventError('')
              }
            }}
            options={eventOptions}
            getOptionLabel={(option) => option.text}
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {option.icon}
                  <Typography sx={{ ml: 1 }}>{option.text}</Typography>
                </Box>
              </li>
            )}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select event'
                variant='outlined'
                error={!!eventError}
                helperText={eventError}
                required
                sx={{
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 2,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': { color: 'rgba(40, 40, 123, 0.5)' }
                  },
                  '& label': {
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: '#28287B'
                  }
                }}
                name='location'
              />
            )}
            sx={{ width: '100%', mt: 2 }}
          />
          <Autocomplete
            freeSolo
            id='checkboxes-tags-demo'
            options={campaignsList}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option) => (
              <li
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  px: 0
                }}
                {...props}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    color: '#28287B'
                  }}
                >
                  {option?.name}
                </Typography>
              </li>
            )}
            renderTags={(value) => (
              <Box
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '90%',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  letterSpacing: '0px',
                  color: '#28287B'
                }}
              >
                {value}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select Campaign'
                variant='outlined'
                error={!!campaignError}
                helperText={campaignError}
                required
                sx={{
                  backgroundColor: 'white',
                  '& div': { pl: 0.3 },
                  '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                  '& div input': {
                    py: 2,
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0em',
                    '&::placeholder': { color: 'rgba(40, 40, 123, 0.5)' }
                  },
                  '& label': {
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '0px',
                    color: '#28287B'
                  }
                }}
                name='location'
              />
            )}
            sx={{ width: '100%', mt: 2 }}
            onChange={(e, option) => {
              setSelectedCampaign({ id: option?._id, name: option?.name })
              if (option) {
                setCampaignError('')
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          mb: 3,
          mx: 2,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2
        }}
      >
        <Button onClick={handleTestWebhook} variant='outlined' fullWidth disabled={!isTestButtonEnabled}>
          Test
        </Button>
        <Button onClick={handleClose} variant='outlined' fullWidth>
          Cancel
        </Button>
        <Button
          onClick={handleAddWebhook}
          variant='contained'
          fullWidth
          sx={{ '&.MuiButton-root': { margin: 0 } }}
        >
          Add Webhook
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const WebhookTable = ({ canEdit }) => {
  const [getAllWebhooks, { isLoading: isLoadingIntegrationWebhook }] = useGetAllWebhooksMutation()
  const [getAllIntegrationLogs, { isLoading: isLoadingIntegrationLogs }] = useGetAllIntegrationLogsMutation()
  const [webhookIntegration, setWebhookIntegration] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openLogDialog, setOpenLogDialog] = useState(false)
  const [integration, setIntegration] = useState(false)
  const [integrationLogs, setIntegrationLogs] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalLogs, setTotalLogs] = useState(0)
  const [campiagnId, setCampaignId] = useState('')
  const [eventType, setEventType] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState({ id: '', name: '' })
  const [webhookUrl, setWebhookUrl] = useState('')
  const [isCampaign, setIsCampaign] = useState(false)
  const [webhookError, setWebhookError] = useState('')
  const [eventError, setEventError] = useState('')
  const [campaignError, setCampaignError] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const { isFreeUser } = UserVerification()
  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const handleClickOpen = () => {
    if (isFreeUser) {
      setOpenModal(true)
      return
    }
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
    setWebhookUrl('')
    setIsCampaign(false)
    setEventType(null)
    setWebhookError('')
    setEventError('')
    setCampaignError('')
    setSelectedCampaign({ id: '', name: '' })
  }

  const handleLogClickOpen = () => {
    setOpenLogDialog(true)
  }

  const handleLogClose = () => {
    setOpenLogDialog(false)
    setIntegrationLogs([])
    setCampaignId('')
    setPage(1)
    setLimit(10)
  }

  const handleGetAllWebhook = async () => {
    try {
      const allWebhook = await getAllWebhooks().unwrap()
      setWebhookIntegration(allWebhook)
    } catch (error) {
      console.error('Failed to fetch webhook:', error)
    }
  }

  useEffect(() => {
    handleGetAllWebhook()
  }, [integration])

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [deleteWebhook] = useDeleteWebhookMutation()
  const [openDeleteWebhookDialog, setOpenDeleteWebhookDialog] = useState(false)
  const [webhookIdToDelete, setWebhookIdToDelete] = useState(null)

  const handleDeleteWebhookClick = async (id) => {
    setOpenDeleteWebhookDialog(true)
    setWebhookIdToDelete(id)
  }

  const handleConfirmDelete = async (id) => {
    setIntegration(true)
    setOpenDeleteWebhookDialog(false)
    const toastId = toast?.loading('Deleting webhook...', { duration: Infinity })
    try {
      const { message } = await deleteWebhook(webhookIdToDelete).unwrap()
      toast.success(message, { id: toastId, duration: 2000 })
    } catch (error) {
      toast.error('Failed to delete webhook', { id: toastId, duration: 2000 })
    }
    setIntegration(false)
  }

  const handleCloseDialog = () => {
    setOpenDeleteWebhookDialog(false)
    setWebhookIdToDelete(null)
  }

  const handleGetAllIntegrationLogs = async (id, event) => {
    try {
      const LogsLimit = 10
      const offset = integrationLogs.length
      const allIntegration = await getAllIntegrationLogs({
        id: id ?? campiagnId,
        eventType: event ?? eventType,
        limit: LogsLimit,
        offset
      }).unwrap()
      setIntegrationLogs(allIntegration?.docs)
      setTotalLogs(allIntegration?.totalDocs)
    } catch (error) {
      console.error('Failed to fetch logs:', error)
    }
  }

  useEffect(() => {
    const object = {}
    object.id = campiagnId
    object.offset = integrationLogs.length * (page - 1)
    object.limit = limit
    getAllIntegrationLogs(object).then((res) => {
      setTotalLogs(res?.data?.totalDocs)
      setIntegrationLogs(res?.data?.docs)
    })
  }, [limit, page])

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Container>
        <Card style={{ marginBottom: '20px' }}>
          <CardContent>
            <Box display='flex' alignItems='center' justifyContent='space-between' flexDirection={isMobile ? 'column' : 'row'}>
              <Box marginBottom={isMobile ? 2 : 0}>
                <Typography variant='h6'>Webhooks</Typography>
                <Typography variant='body2' color='textSecondary'>
                  Notify external services when an event occurs in your Successai workspace.
                </Typography>
              </Box>
              <Box>
                <Button variant='contained' disabled={!canEdit} color='primary' onClick={handleClickOpen}>
                  Add Webhook
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
        {isLoadingIntegrationWebhook
          ? (
            <Box display='flex' justifyContent='center' alignItems='center' style={{ height: '200px' }}>
              <CircularProgress />
            </Box>
            )
          : (
              webhookIntegration.map((row, index) => (
                <Card key={index} style={{ marginBottom: '20px' }}>
                  <CardContent>
                    <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
                      <Grid item xs={12} sm={8}>
                        <Typography variant='h6'>{row?.campaignName}</Typography>
                        <Typography variant='body2' color='textSecondary'>
                          {row?.eventType}
                        </Typography>
                        <Typography variant='body2' color='textSecondary'>
                          {row?.webhookUrl}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} style={{ textAlign: isMobile ? 'center' : 'right' }}>
                        <Box mb={isMobile ? 2 : 0} mr={1} display='inline-block'>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => {
                              setCampaignId(row?.campaignId)
                              handleLogClickOpen()
                              handleGetAllIntegrationLogs(row?.campaignId, row?.eventType)
                            }}
                          >
                            Show logs
                          </Button>
                        </Box>
                        <Box display='inline-block'>
                          <Button
                            variant='outlined'
                            color='error'
                            onClick={() => handleDeleteWebhookClick(row?._id)}
                            disabled={!canEdit}
                          >
                            Delete
                          </Button>
                          <DeleteWebhookModal
                            open={openDeleteWebhookDialog}
                            onClose={handleCloseDialog}
                            onDelete={handleConfirmDelete}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ))
            )}
        <IntegrationDialog
          open={openDialog}
          handleClose={handleClose}
          setIntegration={setIntegration}
          setWebhookUrl={setWebhookUrl}
          setIsCampaign={setIsCampaign}
          isCampaign={isCampaign}
          setEventType={setEventType}
          eventType={eventType}
          webhookUrl={webhookUrl}
          setSelectedCampaign={setSelectedCampaign}
          selectedCampaign={selectedCampaign}
          setWebhookError={setWebhookError}
          setEventError={setEventError}
          setCampaignError={setCampaignError}
          webhookError={webhookError}
          eventError={eventError}
          campaignError={campaignError}
          workspaceId={workspaceId}
        />
        <LogDialog
          open={openLogDialog}
          handleClose={handleLogClose}
          integrationLogs={integrationLogs}
          isLoadingIntegrationLogs={isLoadingIntegrationLogs}
          page={page}
          setPage={setPage}
          totalLogs={totalLogs}
          handleLimitChange={handleLimitChange}
          limit={limit}
        />
      </Container>
      <PremiumFeatureDialog
        open={openModal}
        onClose={handleCloseModal}
      />
    </>
  )
}

const LogDialog = ({ open, handleClose, integrationLogs, isLoadingIntegrationLogs, page, setPage, totalLogs, handleLimitChange, limit }) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
    <DialogTitle>Webhook Logs</DialogTitle>
    <DialogContent>
      {integrationLogs && integrationLogs.length > 0
        ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Event</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {integrationLogs?.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{log?.from}</TableCell>
                    <TableCell>{log?.to}</TableCell>
                    <TableCell>{log?.eventType}</TableCell>
                    <TableCell>{log?.timestamp}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {integrationLogs?.length > 0 && (
              <Box sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
                <InboxPagination
                  page={page}
                  setPage={setPage}
                  total={totalLogs}
                  length={integrationLogs?.length}
                  limit={limit}
                  handleLimitChange={handleLimitChange}
                />
              </Box>
            )}
          </TableContainer>
          )
        : (
          <Typography variant='body1'>No logs available</Typography>
          )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color='primary'>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)

export default WebhookTable
