import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Divider,
  Typography,
  useTheme,
  alpha,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SuccessAiBlueLogo } from "src/assets/SuccessAiBlueLogo";
import {
  setAuthToken,
  useForgotPasswordMutation,
  useLoginMutation,
  useRegisterMutation,
  useResendVerifyLinkMutation,
} from "src/services/auth-service.js";
import * as Yup from "yup";
import msLogo from "../../icons/msIcon.svg";
import AppleLogo from "src/assets/appleIcon.svg";
import { GoogleConnect } from "src/assets/GoogleConnect";
import toast from "react-hot-toast";
import { ArrowRightLong } from "src/assets/general/ArrowRightLong";
import { EACloseIcon } from "src/assets/emailAccounts/EACloseIcon";
import LoginImage from "../../assets/auth/login-new.png";
import { useLazyGetMeQuery, useLazyUpdateGoogleTrackingQuery } from "src/services/user-service";
import UserVerification from "src/components/emailAccounts/UserVerification";
import { EDSCancelIconBlue } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCancelIcon";
import { useLazyGetCurrentPlanQuery } from "src/services/billing-service";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { VisibilityOff, Visibility } from "@mui/icons-material";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "src/msalConfig";
import RedirectButton from "src/components/RedirectButton";
import LoginAnimation from "./loginAnimation";

const LoginPage = () => {
  const navigate = useNavigate();
  const [register] = useRegisterMutation();
  const [login] = useLoginMutation();
  const [resendVerifyLink, { isLoading: isResendLinkVerifyLinkLoading }] =
    useResendVerifyLinkMutation();
  const [forgotPassword] = useForgotPasswordMutation();
  const [queryParameters] = useSearchParams();
  const sumo = queryParameters.get("appsumo");
  const encodedPartnerKey = queryParameters.get("ps_partner_key");
  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [getme, { data }] = useLazyGetMeQuery();

  const [getCurrentPlan, { data: planData }] = useLazyGetCurrentPlanQuery();

  const [updateTracking, { datas }] = useLazyUpdateGoogleTrackingQuery();
  useEffect(() => {
    if (reloadDomOnce === "true") {
      // Reload the page
      window.location.href = window.location.href;

      // Remove the item from localStorage
      localStorage.removeItem("reloadDom");
    }
  }, []);

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const handleRedirect = () => {
    instance
      .loginPopup({
        ...loginRequest,
        prompt: "select_account",
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    console.log("activeAccount : ", activeAccount);
    if (!activeAccount) {
      return;
    }
    const decoded = jwtDecode(activeAccount.idToken);
    instance.setActiveAccount(null);
    sessionStorage.clear();
    const userData = {
      email: decoded.preferred_username,
      name: {
        first: decoded.name.split(" ")[0],
        last: decoded.name.split(" ")[1] || "",
      },
      password: decoded.sub,
      googleId: decoded.sub,
    };

    handleSocialSignUp(userData, "microsoft");
  }, [activeAccount]);

  useEffect(() => {
    if (data && data?.isTrackedByGoogleAds) {
      toast.success("Successfully logged in");
      navigate("/accounts");
      return;
    }
    if (data && planData) {
      if (
        data.isEarlyBirdAccessUser &&
        !data.isEarlyBirdDealRefund &&
        !data?.isTrackedByGoogleAds
      ) {
        updateTracking();
        toast.success("Successfully logged in");
        navigate("/accounts?user=lifetimepaidUser");
        return;
      }

      if (
        data &&
        (planData.subscription?.sendingWarmup?.expiresAt || planData.subscription?.leads?.expiresAt)
      ) {
        updateTracking();
        toast.success("Successfully logged in");
        navigate("/accounts?user=generalpaiduser");
      } else {
        if (!data?.isTrackedByGoogleAds) {
          updateTracking();
          navigate("/accounts?user=freeUser");
          toast.success("Successfully logged in");
        } else {
          navigate("/accounts");
          toast.success("Successfully logged in");
        }
      }
    } else if (data && data?.appSumoCode) {
      updateTracking();
      toast.success("Successfully logged in");
      navigate("/accounts");
    }
  }, [data, planData]);

  const handleSocialSignUp = async (values, provider) => {
    let stripeCustomerId;
    try {
      const result = await register(values);
      if (
        result?.error &&
        result?.error.status === 400 &&
        result?.error.data.error.message === "Email already exists"
      ) {
        console.log("email already exists");
        const { email, password, ...rest } = values;
        const reqObj = {
          email,
          password,
        };
        if (provider === "google") {
          reqObj.isGoogleLogin = true;
        } else if (provider === "microsoft") {
          reqObj.isMicrosoftLogin = true;
        }
        const response = await login(reqObj);
        // if
        if (response?.data?.authToken?.message === "Different provider login") {
          const userResponse = confirm(
            "Account with this email already exist with some other provider. Do you want to continue with that account?"
          );
          console.log("userResponse : ", userResponse);
          if (userResponse) {
            setAuthToken(response?.data?.authToken?.authToken);
            await getme();
            await getCurrentPlan();
            return;
          } else return;
        }
        if (response.error) {
          toast.error(response.error.data.error.message || "Something went wrong");
          return;
        }

        setAuthToken(response?.data?.authToken?.authToken);
        await getme();
        await getCurrentPlan();
        return;
      }
      if (result?.error) {
        toast.error(result.error.data.error.message || "Something went wrong");
        return;
      }
      ({ stripeCustomerId: stripeCustomerId } = result?.data);
      setAuthToken(result?.data?.authToken);
      await getme();
      await getCurrentPlan();
      // /*****
      //  * Store data in growsumo and createSignup method call for create a customer
      //  *****/
      growsumo.data.name = `${values?.name?.first} ${values?.name?.last}`;
      growsumo.data.email = values?.email;
      growsumo.data.customer_key = values?.email;
      growsumo.data.partner_key = atob(encodedPartnerKey);
      growsumo.data.provider_key = stripeCustomerId;
      if (stripeCustomerId && encodedPartnerKey) {
        growsumo.createSignup();
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const response = await login(values).unwrap();
        if (response?.authToken?.twofaEnabled) {
          navigate("/two-factor-authentication", { state: { responseData: response.authToken } });
        } else {
          setAuthToken(response?.authToken?.authToken);
          await getme();
          await getCurrentPlan();
        }
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
    },
  });

  const formikForgotPassword = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address")
        .max(255)
        .required("This field is mandatory."),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const { message } = await forgotPassword(values).unwrap();
        toast.success(message);
        setOpenForgotPasswordDialog(false);
        formikForgotPassword.resetForm();
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
    },
  });

  const handleResendVerifyLinkClick = async () => {
    await resendVerifyLink({ email: formik.values.email }).unwrap();
    navigate("/register/verify-email");
  };

  const handleClickOpenResetPasswordDialog = () => {
    setOpenForgotPasswordDialog(true);
  };

  const theme = useTheme();
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const reloadDomOnce = localStorage.getItem("reloadDom");

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      const decoded = jwtDecode(response.credential);
      const userData = {
        email: decoded.email,
        name: {
          first: decoded.given_name,
          last: decoded.family_name,
        },
        password: decoded.sub,
        googleId: decoded.sub,
      };

      handleSocialSignUp(userData, "google");
    },
  });

  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "flex-start",
          // alignItems: "center",
          backgroundColor: "#F2F4F6",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: isMdUp ? "50%" : "100%",
            // height: "100%",
            paddingLeft: isMdUp ? "115px" : "32px",
            paddingRight: isMdUp ? "115px" : "32px",
            paddingTop: "32px",
            paddingBottom: "32px",
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Box sx={{ marginLeft: "-36px" }}>
            <SuccessAiBlueLogo />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              // height: "100%",
            }}
          >
            {isResendLinkVerifyLinkLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: 300,
                }}
              >
                <CircularProgress />
                <Typography sx={{ mt: 3, fontSize: "20px", fontWeight: 500, color: "#216fed" }}>
                  Please wait...
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "30px",
                      fontWeight: 600,
                      lineHeight: "30px",
                      color: "#101010",
                    }}
                  >
                    Sign In
                  </Typography>
                </Box>
                {/* <RedirectButton
                  buttonImage={<GoogleConnect />}
                  buttonText="Sign in with Google"
                  handleClick={handleGoogleLogin}
                  buttonStyle={{ marginTop: "18px" }}
                />
                <RedirectButton
                  buttonImage={<img src={msLogo} alt="msLogo" />}
                  buttonText="Sign in with Microsoft"
                  buttonStyle={{ marginTop: "12px" }}
                  handleClick={handleRedirect}
                /> */}
                {/* <RedirectButton
                  buttonImage={<img src={AppleLogo} alt="msLogo" />}
                  buttonText="Sign in with Apple"
                  buttonStyle={{ marginTop: "12px" }}
                  handleClick={() => {}}
                /> */}

                {/* <Box display="flex" alignItems="center" width="100%" mt={3}>
                  <Divider sx={{ flex: 1, borderColor: "#D7D9E6" }} />
                  <Typography sx={{ mx: 2, color: "#92979C", fontSize: "14px" }}>or</Typography>
                  <Divider sx={{ flex: 1, borderColor: "#D7D9E6" }} />
                </Box> */}

                <form noValidate onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                  <Stack
                    // spacing={3}
                    sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        fontSize: "15px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#101010",
                        mt: 2,
                      }}
                    >
                      Email
                    </Typography>
                    <TextField
                      error={!!(formik.touched.email && formik.errors.email)}
                      fullWidth
                      helperText={formik.touched.email && formik.errors.email}
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.email}
                      placeholder="Enter email"
                      variant="outlined"
                      sx={{
                        mt: 1,
                        width: "100%",
                        // height: 40,
                        backgroundColor: "white",
                        "& div": { pl: 0.3 },
                        "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                        "& div input": {
                          py: 2,
                          fontSize: "13px",
                          fontWeight: 400,
                          lineHeight: "16px",
                          letterSpacing: "0em",
                          "&::placeholder": {
                            color: "rgba(40, 40, 123, 0.5)",
                          },
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "left",
                        fontSize: "15px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        color: "#101010",
                        mt: 2,
                      }}
                    >
                      Password
                    </Typography>
                    <TextField
                      error={!!(formik.touched.password && formik.errors.password)}
                      fullWidth
                      helperText={formik.touched.password && formik.errors.password}
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type={showPassword ? "text" : "password"}
                      value={formik.values.password}
                      placeholder="Enter password"
                      variant="outlined"
                      sx={{
                        mt: 1,
                        width: "100%",
                        // height: 40,
                        backgroundColor: "white",
                        "& div": { pl: 0.3 },
                        "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                        "& div input": {
                          py: 2,
                          fontSize: "13px",
                          fontWeight: 400,
                          lineHeight: "16px",
                          letterSpacing: "0em",
                          "&::placeholder": {
                            color: "rgba(40, 40, 123, 0.5)",
                          },
                        },
                        "& .MuiFormHelperText-root": { textAlign: "right", mx: 0 },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 3, textAlign: "right" }} variant="body2">
                      {formik.errors.submit}
                    </Typography>
                  )}
                  {formik.errors.submit === "Email not verified" && (
                    <Typography
                      sx={{ textAlign: "center", fontWeight: 600, fontSize: 16, cursor: "pointer" }}
                      onClick={handleResendVerifyLinkClick}
                    >
                      Need another verification link?
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Checkbox sx={{ padding: 0 }} />
                      <Typography
                        sx={{
                          color: "#101010",
                          cursor: "pointer",
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "16px",
                        }}
                        onClick={handleClickOpenResetPasswordDialog}
                      >
                        Remember me
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#0071F6",
                        cursor: "pointer",
                        mt: 1,
                        "&:hover": {
                          color: "#164694",
                        },
                        mr: 0.5,
                        fontSize: "13px",
                        fontWeight: 700,
                        lineHeight: "16px",
                      }}
                      onClick={handleClickOpenResetPasswordDialog}
                    >
                      <Link href="/forget-password" sx={{ textDecoration: "none" }}>
                        {" "}
                        Forgot password?
                      </Link>
                    </Typography>
                  </Box>
                  <Button
                    fullWidth
                    sx={{
                      mt: 3,
                      py: 2.5,
                      fontSize: "14px",
                      fontWeight: 700,
                      lineHeight: "18px",
                      borderRadius: "14px",
                    }}
                    type="submit"
                    variant="contained"
                    disabled={!formik.isValid}
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <>
                        <Typography
                          sx={{ fontWeight: 600, fontSize: "14px", lineHeight: "17.5px" }}
                        >
                          Sign in to Success.ai
                        </Typography>
                      </>
                    )}
                  </Button>
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      sx={{
                        mt: 3,
                        color: "#8181B0",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "21px",
                      }}
                    >
                      Don't have an account?
                      <Link
                        href="/register"
                        sx={{
                          textDecoration: "none",
                          "&:hover": {
                            color: "#164694",
                          },
                          ml: 0.3,
                          color: "#0071F6",
                          fontSize: "13px",
                          fontWeight: 700,
                          lineHeight: "20px",
                        }}
                      >
                        Create an account
                      </Link>
                    </Typography>
                  </Box>
                </form>
              </>
            )}
          </Box>
          <Box sx={{ display: "flex", alignSelf: "center" }}>
            <Typography
              sx={{
                mt: 3,
                color: "#8181B0",
                fontSize: "13px",
                fontWeight: 500,
                lineHeight: "18px",
              }}
            >
              2024 Success.ai, All right Reserved
            </Typography>
          </Box>
        </Box>
        {isMdUp && (
          <Box
            sx={{
              background: "linear-gradient(to bottom, #3F4FF8 50%, #01052AE0)",
              color: "white",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              width: "50%",
              paddingBottom: 3,
            }}
          >
            <LoginAnimation />
          </Box>
        )}
      </Box>

      {/* <Dialog
        open={openForgotPasswordDialog}
        onClose={handleCloseDialog}
        sx={{ backgroundColor: "rgba(4, 4, 30, 0.5)" }}
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "28px",
                color: "#28287B",
              }}
            >
              {" "}
              Password Recovery
            </Typography>
            <IconButton onClick={handleCloseDialog}>
              <EACloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 3 }}>
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#8181B0",
              }}
            >
              {" "}
              Please enter the email address associated with your account, and we'll dispatch a link
              to reset your password.
            </Typography>
          </DialogContentText>
          <Typography
            sx={{
              width: "100%",
              textAlign: "left",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "20px",
              color: "#28287B",
            }}
          >
            Your Email Address
          </Typography>
          <TextField
            autoFocus
            id="email"
            placeholder="Enter Email"
            type="email"
            fullWidth
            variant="outlined"
            error={!!(formikForgotPassword.touched.email && formikForgotPassword.errors.email)}
            helperText={formikForgotPassword.touched.email && formikForgotPassword.errors.email}
            name="email"
            onBlur={formikForgotPassword.handleBlur}
            onChange={formikForgotPassword.handleChange}
            value={formikForgotPassword.values.email}
            sx={{
              mt: 2,
              width: "100%",
              // height: 40,
              backgroundColor: "white",
              "& div": { pl: 0.3 },
              "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
              "& div input": {
                py: 2,
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "16px",
                letterSpacing: "0em",
                "&::placeholder": {
                  color: "rgba(40, 40, 123, 0.5)",
                },
              },
            }}
          />{" "}
          {formikForgotPassword.errors.submit && (
            <Typography color="error" sx={{ mt: 3, textAlign: "center" }} variant="body2">
              {formikForgotPassword.errors.submit}
            </Typography>
          )}
          <Grid container columnSpacing={2} sx={{ mt: 3 }}>
            <Grid item xs={6}>
              {" "}
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "14px",
                  py: 1.5,
                }}
                onClick={handleCloseDialog}
              >
                <Box
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}
                >
                  <EDSCancelIconBlue />
                </Box>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <Button
                fullWidth
                variant="contained"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "14px",
                  py: 1.5,
                }}
                disabled={!formikForgotPassword.isValid}
                onClick={formikForgotPassword.handleSubmit}
              >
                {" "}
                {formik.isSubmitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: 1,
                      }}
                    >
                      <ArrowRightLong />
                    </Box>
                    Submit
                  </>
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>

        {/* <DialogActions sx={{ mb: 3 }}>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={formikForgotPassword.handleSubmit} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default LoginPage;
