import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, CircularProgress } from '@mui/material'

const LeaveWorkspaceDialog = ({ open, onClose, onClick, isLoading }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2, py: 1 }}>
        <DialogTitle sx={{ fontSize: '24px', textAlign: 'center' }}>Are you sure?</DialogTitle>
        <DialogContent>
          You will have to be invited again to join this workspace!
        </DialogContent>
        <DialogActions sx={{ mb: 3, justifyContent: 'center' }}>
          <Button onClick={onClick} variant='contained' color='error'>
            {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Yes, I leave Organization'}
          </Button>
          <Button onClick={onClose} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>Cancel</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default LeaveWorkspaceDialog
