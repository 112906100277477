export const MicrosoftIcon = () => {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M23 23H3V3H23V23Z' fill='#F1511B' />
        <path d='M45 23H25V3H45V23Z' fill='#80CC28' />
        <path d='M23 45H3V25H23V45Z' fill='#00ADEF' />
        <path d='M45 45H25V25H45V45Z' fill='#FBBC09' />
      </svg>
    </>
  )
}
