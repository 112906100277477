export const EATotalEmailsReceived = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' rx='12' fill='#DAEFDF' />
        <path
          d='M6 12.762V15.8096C6 16.6512 6.67157 17.3334 7.5 17.3334H16.5C17.3284 17.3334 18 16.6512 18 15.8096V12.762M6 12.762L7.88501 7.65551C8.10455 7.06076 8.66423 6.66675 9.2895 6.66675H14.7105C15.3358 6.66675 15.8954 7.06076 16.115 7.65551L18 12.762M6 12.762H9L10 13.8287H14L15 12.762H18'
          stroke='#00AA38'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
