import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "./theme";
import "simplebar-react/dist/simplebar.min.css";
import { routes } from "./routes";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import { config } from "./config";
import Maintenance from "./Maintenance";
import { useSelector } from "react-redux";
import { MsalProvider } from "@azure/msal-react";

export const App = ({ instance }) => {
  const user = useSelector((state) => state.user);
  const theme = createTheme({
    colorPreset: "blue",
    contrast: "high",
  });
  const maintenanceModeString = process.env.REACT_APP_MAINTENANCE_MODE;
  const maintenanceMode = maintenanceModeString === "true";
  useEffect(() => {
    if (!maintenanceMode) {
      window.intercomSettings = {
        app_id: config.INTERCOM_APP_ID,
      };
    }

    if (window.clarity) {
      window.clarity("set", "userEmail", user?.email);
    } else {
      const clarityCheck = setInterval(() => {
        if (window.clarity) {
          window.clarity("set", "userEmail", user?.email);
          clearInterval(clarityCheck);
        }
      }, 1000);
    }
  }, [user?.email]);
  if (maintenanceMode) {
    return <Maintenance lead={false} />;
  }
  return (
    <MsalProvider instance={instance} >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routes}
        <Toaster
          toastOptions={{
            duration: 2000,
            success: {
              style: {
                background: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
              iconTheme: {
                primary: theme.palette.primary.contrastText,
                secondary: theme.palette.primary.main,
              },
            },
            error: {
              style: {
                background: "red",
                color: theme.palette.primary.contrastText,
              },
              iconTheme: {
                primary: theme.palette.primary.contrastText,
                secondary: "red",
              },
            },
            loading: {
              style: {
                background: theme.palette.primary.contrastText,
                color: theme.palette.primary.main,
              },
              iconTheme: {
                primary: theme.palette.primary.main,
                secondary: theme.palette.primary.contrastText,
              },
            },
          }}
        />
      </ThemeProvider>
    </MsalProvider>
  );
};
