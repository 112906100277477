import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField } from '@mui/material'
import { useCreateTagMutation } from 'src/services/tag-service'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

const CreateTagsDialog = ({ open, onClose }) => {
  const initialTagState = { label: '', description: '' }
  const [newTag, setNewTag] = useState(initialTagState)
  const [createTag] = useCreateTagMutation()
  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const handleCreateTag = async () => {
    try {
      const trimmedLabel = newTag.label.trim()
      const { message } = await createTag({ tags: { ...newTag, label: trimmedLabel }, workspaceId }).unwrap()
      if (message.includes('Tag already exists')) {
        toast.error(message)
      } else {
        toast.success(message)
        onClose()
        setNewTag(initialTagState)
      }
    } catch (error) {
      console.error('Error creating tag:', error)
      toast.error('Failed to create tag. Please try again.')
    }
  }

  const handleSaveTag = async () => {
    if (newTag.label.trim()) {
      await handleCreateTag()
    } else {
      toast.error('Label is required')
    }
  }

  const handleDialogClose = () => {
    onClose()
    setNewTag(initialTagState)
  }

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth='md'>
      <Box sx={{ p: 2, py: 1 }}>
        <DialogTitle sx={{ fontSize: '24px', textAlign: 'center' }}>Create Tag</DialogTitle>
        <DialogContent>
          <TextField
            size='large'
            fullWidth
            placeholder='Enter Label'
            value={newTag.label}
            onChange={(e) => setNewTag({ ...newTag, label: e.target.value })}
            sx={{ mb: 3 }}
            maxRows={4}
          />
          <TextField
            size='large'
            fullWidth
            placeholder='Enter Description'
            value={newTag.description}
            onChange={(e) => setNewTag({ ...newTag, description: e.target.value })}
            sx={{ mb: 3 }}
          />
        </DialogContent>
        <DialogActions sx={{ mb: 3, justifyContent: 'center' }}>
          <Button onClick={handleSaveTag} variant='contained' color='primary'>
            Save
          </Button>
          <Button onClick={handleDialogClose} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default CreateTagsDialog
