import React, { useState } from 'react'
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
  IconButton
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDkimGeneratorMutation } from 'src/services/tool-service.js'

const DkimRecoredGeneratorPage = () => {
  const [domainDetails, setDomainDetails] = useState([])
  const [dkimGenerator] = useDkimGeneratorMutation()
  const validationSchema = () => {
    const schema = Yup.object({
      domain: Yup.string()
        .max(255)
        .required('Domain is required')
        .matches(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
          'Please add a valid Domain address'
        ),
      selector: Yup.string()
        .max(255)
        .required('selector is required')
        .matches(/^[a-zA-Z0-9-_]+$/, 'Please add a valid Selector'),
      keySize: Yup.string().max(255).required('Key size is required')
    })

    return schema
  }

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      const result = await dkimGenerator(values).unwrap()

      if (result.message === 'Fetched Error') {
        setDomainDetails([{ noDomainFound: `${formik?.values?.domain}: Invalid Domain Name ` }])
      } else {
        const publicKey = result.dkimRecordGenerate[0].publicKey
        const privateKey = result.dkimRecordGenerate[0].privateKey
        const generatedDkimRecord = result.dkimRecordGenerate[0].generatedDkimRecord
        const generatedRecordDomainDetials =
          result.dkimRecordGenerate[0].generatedRecordDomainDetials
        setDomainDetails([
          {
            publicKey,
            privateKey,
            generatedDkimRecord,
            generatedRecordDomainDetials
          }
        ])
      }
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const formik = useFormik({
    initialValues: {
      domain: '',
      selector: '',
      keySize: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Copy SUccess
      })
      .catch((err) => {
        console.error('Failed to copy: ', err)
      })
  }

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        DKIM Record Generator
      </Typography>
      <Typography color='primary.main'>
        Generate DKIM record to help prevent email spoofing and phishing attacks.
      </Typography>

      {/* DKIM Generator Form  */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{ display: 'flex', alignItems: 'baseline', flexDirection: { xs: 'column', sm: 'row' }, mt: { xs: '24px', sx: '24px', md: 3, lg: 3, xl: 3 } }}
        >
          <Box
            sx={{ display: 'flex', gap: { sm: 1 }, flexDirection: { xs: 'column', sm: 'row' }, mb: { xs: 0, sm: 0 }, width: { xs: '100%', sm: 'auto' } }}
          >
            <TextField
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helpertext={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name='domain'
              value={formik?.values?.domain}
              variant='outlined'
              placeholder='Enter a domain name'
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { sm: '21vw', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight: { sm: '0px' }

              }}
            />
            <TextField
              onChange={formik.handleChange}
              error={!!(formik.touched.selector && formik.errors.selector)}
              helpertext={formik.touched.selector && formik.errors.selector}
              onBlur={formik.handleBlur}
              name='selector'
              value={formik?.values?.selector}
              variant='outlined'
              placeholder='Enter selector'
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { sm: '21vw', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight: { sm: '0px' }

              }}
            />
            <Select
              onChange={formik.handleChange}
              error={!!(formik.touched.keySize && formik.errors.keySize)}
              helpertext={formik.touched.keySize && formik.errors.keySize}
              onBlur={formik.handleBlur}
              name='keySize'
              value={formik?.values?.keySize}
              variant='outlined'
              style={{ color: 'primary.main', maxHeight: '44px' }}
              placeholder='Select key size'
              sx={{
                mb: { xs: 2, sm: 2 },
                width: { sm: '21vw', xs: 'auto' },
                marginBottom: { xs: '8px', sm: '11px' }
              }}
            >
              <MenuItem value=''>Select key size</MenuItem>
              <MenuItem value='1024'>1024</MenuItem>
              <MenuItem value='2048'>2048</MenuItem>
              <MenuItem value='3072'>3072</MenuItem>
            </Select>
          </Box>

          <Button
            variant='contained'
            color='primary'
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '10px',
              marginLeft: { xs: '7px', sm: '4' },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: '44px',
              cursor: formik?.values?.domain ? 'pointer' : 'not-allowed'
            }}
            disabled={!formik?.values?.domain}
            type='submit'
          >
            {formik.isSubmitting ? <CircularProgress size={20} color='inherit' /> : <>Generate</>}
          </Button>
        </Box>
      </form>

      {/* result Table */}
      {domainDetails.length > 0 && (
        <Box
          mt={4}
          p={3}
          sx={{ border: '2px solid #ECEDF5', borderRadius: '8px', maxWidth: { sm: '80vw' } }}
        >
          {/* Public Key Section */}
          <Box className='public-key-container'>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' color='text.primary' fontWeight='medium'>
                Public Key
              </Typography>
              <IconButton
                sx={{ color: '#6E58F1' }}
                aria-label='copy public key'
                onClick={() => handleCopy(domainDetails[0].publicKey)}
              >
                <ContentCopyIcon />
                <Typography component='span' ml={1}>
                  Copy
                </Typography>
              </IconButton>
            </Box>
            <Paper variant='outlined' sx={{ backgroundColor: '#f9fafb', p: 2, mt: 2 }}>
              <Typography
                variant='body2'
                component='pre'
                color='text.secondary'
                style={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word'
                }}
              >
                {domainDetails[0].publicKey}
              </Typography>
            </Paper>
          </Box>

          {/* Private Key Section */}
          <Box className='private-key-container' mt={4}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' color='text.primary' fontWeight='medium'>
                Private Key
              </Typography>
              <IconButton
                sx={{ color: '#6E58F1' }}
                aria-label='copy private key'
                onClick={() => handleCopy(domainDetails[0].privateKey)}
              >
                <ContentCopyIcon />
                <Typography component='span' ml={1}>
                  Copy
                </Typography>
              </IconButton>
            </Box>
            <Paper variant='outlined' sx={{ backgroundColor: '#f9fafb', p: 2, mt: 2 }}>
              <Typography
                variant='body2'
                component='pre'
                color='text.secondary'
                style={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word'
                }}
              >
                {domainDetails[0].privateKey}
              </Typography>
            </Paper>
          </Box>

          {/* DKIM Record Section */}
          <Box className='generate-key-container' mt={4}>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' color='text.primary' fontWeight='medium'>
                Generated DKIM Record
              </Typography>
              <IconButton
                sx={{ color: '#6E58F1' }}
                aria-label='copy DKIM record'
                onClick={() => handleCopy(domainDetails[0].generatedDkimRecord)}
              >
                <ContentCopyIcon />
                <Typography component='span' ml={1}>
                  Copy
                </Typography>
              </IconButton>
            </Box>
            <Typography variant='body2' mt={2}>
              Please publish the DNS TXT record below on the subdomain: <br />
              <Typography component='span' color='primary' fontWeight='bold'>
                {domainDetails[0].generatedRecordDomainDetials}
              </Typography>
            </Typography>
            <Paper variant='outlined' sx={{ backgroundColor: '#f9fafb', p: 2, mt: 2 }}>
              <Typography
                variant='body2'
                component='pre'
                color='text.secondary'
                style={{
                  whiteSpace: 'pre-wrap',
                  overflowWrap: 'break-word',
                  wordWrap: 'break-word'
                }}
              >
                {domainDetails[0].generatedDkimRecord}
              </Typography>
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default DkimRecoredGeneratorPage
