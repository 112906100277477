import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'

export const RemoveEmailDialog = ({ open, onClose, handleBulkDelete }) => (
  <Dialog open={open} onClose={onClose}>
    <Box sx={{ p: 2, py: 1 }}>
      <DialogTitle sx={{ fontSize: '24px', textAlign: 'center' }}>Are you sure?</DialogTitle>
      <DialogContent>
        You won't be able to recover these emails!
      </DialogContent>
      <DialogActions sx={{ mb: 3, justifyContent: 'center' }}>
        <Button onClick={handleBulkDelete} variant='contained' color='error'>
          Delete
        </Button>
        <Button onClick={onClose} variant='contained' sx={{ backgroundColor: 'black', color: 'white' }}>Cancel</Button>
      </DialogActions>
    </Box>
  </Dialog>
)
