export const Opportunities = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        class='icon icon-tabler icon-tabler-bulb'
        width='36'
        height='36'
        viewBox='0 0 24 24'
        stroke-width='1.5'
        // stroke="#a905b6"
        fill='none'
        stroke-linecap='round'
        stroke-linejoin='round'
      >
        <rect width='24' height='24' rx='12' fill='#FFF5DC' />
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path stroke='#FFCB4C' d='M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7' />
        <path
          stroke='#FFCB4C'
          d='M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3'
        />
        <path stroke='#FFCB4C' d='M9.7 17l4.6 0' />
      </svg>
    </>
  )
}
