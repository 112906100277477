import React from 'react'

export const ImportLeadFinderIcon = () => {
  return (
    <>
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clip-path='url(#clip0_152_14)'>
          <path
            d='M48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48C37.2548 48 48 37.2548 48 24Z'
            fill='#DEC2DF'
          />
          <path
            d='M33 33L27 27M15 22C15 22.9193 15.1811 23.8295 15.5328 24.6788C15.8846 25.5281 16.4002 26.2997 17.0503 26.9497C17.7003 27.5998 18.4719 28.1154 19.3212 28.4672C20.1705 28.8189 21.0807 29 22 29C22.9193 29 23.8295 28.8189 24.6788 28.4672C25.5281 28.1154 26.2997 27.5998 26.9497 26.9497C27.5998 26.2997 28.1154 25.5281 28.4672 24.6788C28.8189 23.8295 29 22.9193 29 22C29 21.0807 28.8189 20.1705 28.4672 19.3212C28.1154 18.4719 27.5998 17.7003 26.9497 17.0503C26.2997 16.4002 25.5281 15.8846 24.6788 15.5328C23.8295 15.1811 22.9193 15 22 15C21.0807 15 20.1705 15.1811 19.3212 15.5328C18.4719 15.8846 17.7003 16.4002 17.0503 17.0503C16.4002 17.7003 15.8846 18.4719 15.5328 19.3212C15.1811 20.1705 15 21.0807 15 22Z'
            stroke='#760078'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_152_14'>
            <rect width='48' height='48' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
