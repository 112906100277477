import React from 'react'
import {
  Box,
  Container,
  Stack,
  Typography,
  Divider,
  ListItem,
  ListItemIcon
} from '@mui/material'
import { Link } from 'react-router-dom'
import firstImage from '../assets/zapier_instructions/first_image.png'
import secondImage from '../assets/zapier_instructions/second_image.png'
import thirdImage from '../assets/zapier_instructions/third_image.png'
import fourthImage from '../assets/zapier_instructions/forth_image.png'
import fifthImage from '../assets/zapier_instructions/fifth_image.png'
import lastImage from '../assets/zapier_instructions/last_image.png'

const ZapierInstructions = () => {
  return (
    <>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'neutral.50'
        }}
      >
        <Container maxWidth='xl'>
          <Stack spacing={3} sx={{ mb: 3 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Typography
                sx={{ color: '#050C46', fontSize: '32px', fontWeight: '700' }}
              >
                Zapier Instructions
              </Typography>
            </div>

            <Divider />
          </Stack>

          <Box sx={{ margin: '16px 0' }}>
            <ListItem sx={{ marginBottom: 1 }}>
              <ListItemIcon>
                {1}
              </ListItemIcon>
              <Typography>
                <p>
                  To access your <BoldText>Success.ai account</BoldText> , please click on the&nbsp;
                  <Link to='/login'>
                    <BoldText>Sign In</BoldText>
                  </Link>&nbsp;
                  button. This will direct you to the login page where you can enter your credentials to securely access your account and manage your business operations efficiently.
                </p>
              </Typography>
            </ListItem>
          </Box>

          <Box sx={{ margin: '16px 0' }}>
            <ListItem sx={{ marginBottom: 1 }}>
              <ListItemIcon>
                {2}
              </ListItemIcon>
              <Typography>
                <p>Please select the <BoldText>Integrations</BoldText> button located at the bottom left of the sidebar.</p>
              </Typography>
            </ListItem>

            <Box
              alt='setttings'
              component='img'
              src={firstImage}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
                border: '1px solid #f1f1f1',
                borderRadius: '20px'
              }}
            />
          </Box>

          <Box sx={{ margin: '16px 0' }}>
            <ListItem sx={{ marginBottom: 1 }}>
              <ListItemIcon>
                {3}
              </ListItemIcon>
              <Typography>
                <p>{'Next to the zapier logo, Click on the '} <BoldText>Switch Button</BoldText> to turn it ON. The appearance of the switch should change to indicate that it is now in the ON position.</p>
              </Typography>
            </ListItem>

            <Box
              alt='switch'
              component='img'
              src={secondImage}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
                border: '1px solid #f1f1f1',
                borderRadius: '20px'
              }}
            />
          </Box>

          <Box sx={{ margin: '16px 0' }}>
            <ListItem sx={{ marginBottom: 1 }}>
              <ListItemIcon>
                {4}
              </ListItemIcon>
              <p><BoldText>The Zapier API Key</BoldText>&nbsp;can be found below the Zapier logo. Please copy the Zapier API Key for use in the subsequent steps.</p>
            </ListItem>

            <Box
              alt='token'
              component='img'
              src={thirdImage}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
                border: '1px solid #f1f1f1',
                borderRadius: '20px'
              }}
            />
          </Box>

          <Box sx={{ margin: '8px 0' }}>
            <ListItem>
              <ListItemIcon>
                {5}
              </ListItemIcon>
              <BoldText>Access Zapier Account</BoldText>
            </ListItem>
            <ul style={{ marginLeft: '24px' }}>
              <li><p>Login to&nbsp;<BoldText>Zapier</BoldText>&nbsp;account.</p></li>
              <li><p>Once logged in, click on the <BoldText>Zap</BoldText> button on the left sidebar of the dashboard.</p></li>
              <li><p>Cilck on <BoldText>Create</BoldText> button to create a New Zap.</p></li>
              <li>
                <p>{'Assign a descriptive name to your Zap, such as "Success.ai Access Integration," to help you easily identify it later. In the '}<BoldText>Choose trigger</BoldText>{' section, type "Success.ai" in the search bar and select the Success.ai app from the list of available apps.'}</p>
                <Box
                  alt='zapier choose trigger'
                  component='img'
                  src={fourthImage}
                  sx={{
                    height: 'auto',
                    maxWidth: '600px',
                    width: '100%',
                    margin: 'auto',
                    border: '1px solid #f1f1f1',
                    borderRadius: '20px'
                  }}
                />
              </li>

              <li>
                <p>{'Select Triggered app '}<BoldText>Success.ai </BoldText>{' and choose event from '}<BoldText>App & event</BoldText>{' Tab. Click on Continue and move to the next step.'}</p>

                <Box
                  alt='zapier choose app event'
                  component='img'
                  src={fifthImage}
                  sx={{
                    height: 'auto',
                    maxWidth: '600px',
                    width: '100%',
                    margin: 'auto',
                    border: '1px solid #f1f1f1',
                    borderRadius: '20px'
                  }}
                />
              </li>

              <li>
                <p>{'In '}<BoldText>Account</BoldText>{' Tab, If this is your first time using the Success.ai app with Zapier, you will need to connect Success.ai  account. Click on the '}<BoldText>Sign in</BoldText>{' button. A new window will appear, prompting you to enter your Success.ai API key and Email Address. Retrieve this key from the API settings or integration section within your Success.ai account.'}</p>

                <Box
                  alt='zapier popup prompt'
                  component='img'
                  src={lastImage}
                  sx={{
                    height: 'auto',
                    maxWidth: '600px',
                    width: '100%',
                    margin: 'auto',
                    border: '1px solid #f1f1f1',
                    borderRadius: '20px'
                  }}
                />
              </li>
              <li>
                <p>{'Enter the '} <BoldText>Zapier API key</BoldText> {' in the provided field and authorize Zapier to access your Success.ai account by clicking '} <BoldText>Yes, Continue</BoldText></p>
              </li>
            </ul>
          </Box>

        </Container>
      </Box>
    </>
  )
}

export default ZapierInstructions

const BoldText = ({ children }) => {
  return <span style={{ color: '#050C46', fontSize: '16px', fontWeight: '700' }}>{children}</span>
}
