import { createTheme as createMuiTheme } from '@mui/material/styles'
import { createComponents } from './create-components'
import { createPalette } from './create-palette'
import { createShadows } from './create-shadows'
import { createTypography } from './create-typography'

export function createTheme (config) {
  const { colorPreset, contrast } = config
  const palette = createPalette({ colorPreset, contrast })
  const components = createComponents({ palette })
  const shadows = createShadows({ palette })
  const typography = createTypography()

  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440
      }
    },
    components,
    palette,
    shadows,
    shape: {
      borderRadius: 6
    },
    typography
  })
}
