import { alpha, Button, Menu, MenuItem, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import React from 'react'

export const CustomDropdown = ({
  label,
  options,
  selected,
  handleSelect,
  isReplyLoading
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 1,
          border: '1px solid #ECE9F5',
          boxShadow: `0 1px 2px 0 ${alpha('#101828', 0.05)}`,
          borderRadius: 1
        }}
        onClick={handleClick}
      >
        <Typography
          sx={{ fontSize: '14px', fontWeight: '700', color: '#111927' }}
        >
          {label}
        </Typography>
        <ExpandMore sx={{ color: '#111927' }} />
      </Button>
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {options.length > 0
          ? (
              options.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => handleSelect(option.value)}
                  selected={selected === option.value}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 1,
                    py: 1.5
                  }}
                >
                  {option.icon && <img src={option.icon} />}
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: selected === option.value ? '#5761FE' : '#111927'
                    }}
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
              ))
            )
          : isReplyLoading
            ? (
              <MenuItem key={0} disabled>
                Loading ...
              </MenuItem>
              )
            : (
              <MenuItem key={0} disabled>
                No Options
              </MenuItem>
              )}
      </Menu>
    </>
  )
}
