import React, { useState } from 'react'
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const EmailClickToOpenRateCalculator = () => {
  const [results, setResults] = useState('')

  const validationSchema = () => {
    const schema = Yup.object({
      emailsent: Yup.number()
        .typeError('Please enter a valid number')
        .required('Emails Sent is required')
        .min(1, 'Please enter a number greater than or equal to 1'),

      emailopen: Yup.number()
        .typeError('Please enter a valid number')
        .required('Email Open is required')
        .min(1, 'Please enter a number greater than or equal to 1'),

      emailUniqueClick: Yup.number()
        .typeError('Please enter a valid number')
        .required('Unique Click is required')
        .min(1, 'Please enter a number greater than or equal to 1')
    })

    return schema
  }

  const handleDataSubmit = async (values, helpers) => {
    try {
      const openRate = (100 / values.emailopen) * values.emailUniqueClick
      setResults(openRate.toFixed(2))
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const reSetData = () => {
    formik.resetForm()
    setResults('')
  }

  const formik = useFormik({
    initialValues: {
      emailsent: '',
      emailopen: '',
      emailUniqueClick: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDataSubmit(values, helpers, true)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
    }
  })

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Click-to-Open Rate Calculator
      </Typography>

      <Typography color='primary.main' sx={{ fontSize: '0.8rem' }}>
        Determine the effectiveness of your email campaigns with our free Click-to-Open Rate (CTOR)
        calculator.
      </Typography>

      {/* Email Open Rate Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            flexDirection: { xs: 'column', sm: 'row' },
            mt: { xs: '24px', sx: '24px', md: 3, lg: 3, xl: 3 }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: { sm: 1 },
              flexDirection: { xs: 'column', sm: 'row' },
              mb: { xs: 0, sm: 0 },
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            <TextField
              variant='outlined'
              onChange={formik.handleChange}
              error={!!(formik.touched.emailsent && formik.errors.emailsent)}
              helperText={formik.touched.emailsent && formik.errors.emailsent}
              onBlur={formik.handleBlur}
              name='emailsent'
              placeholder='Number of Emails Sent'
              value={formik?.values?.emailsent}
              type='number'
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { md: '21vw', sm: '35vw', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight: { sm: '0px' }
              }}
            />

            <TextField
              variant='outlined'
              onChange={formik.handleChange}
              error={!!(formik.touched.emailUniqueClick && formik.errors.emailUniqueClick)}
              helperText={formik.touched.emailUniqueClick && formik.errors.emailUniqueClick}
              onBlur={formik.handleBlur}
              name='emailUniqueClick'
              placeholder='Number of Unique Click'
              type='number'
              value={formik?.values?.emailUniqueClick}
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { md: '21vw', sm: '35vw', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight: { sm: '0px' }
              }}
            />

            <TextField
              variant='outlined'
              onChange={formik.handleChange}
              error={!!(formik.touched.emailopen && formik.errors.emailopen)}
              helperText={formik.touched.emailopen && formik.errors.emailopen}
              onBlur={formik.handleBlur}
              name='emailopen'
              placeholder='Number of Unique Opens'
              type='number'
              value={formik?.values?.emailopen}
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { md: '21vw', sm: '35vw', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight: { sm: '0px' }
              }}
            />
          </Box>

          <Button
            variant='contained'
            color='primary'
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '1rem',
              marginLeft: { xs: '7px', sm: '4' },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: '44px',
              cursor:
                formik?.values?.emailsent &&
                formik?.values?.emailopen &&
                formik?.values?.emailUniqueClick
                  ? 'pointer'
                  : 'not-allowed'
            }}
            disabled={
              !(
                formik?.values?.emailsent &&
                formik?.values?.emailopen &&
                formik?.values?.emailUniqueClick
              )
            }
            type='submit'
          >
            {formik.isSubmitting ? <CircularProgress size={20} color='inherit' /> : <>Calculate</>}
          </Button>
        </Box>
      </form>

      {results !== '' && (
        <Box
          sx={{
            py: { xs: '20px', sm: '40px' },
            display: 'flex',
            flexDirection: 'column',
            width: { sm: '100%' },
            justifyContent: 'center',
            alignItems: 'start',
            border: '6px solid #ECEDF5',
            p: { xs: '15px', sm: '30px' },
            mt: 8,
            borderRadius: '12px'
          }}
        >
          <Typography variant='h5' sx={{ fontWeight: '600', mb: '20px' }}>
            Open Rate Calculator Result
          </Typography>

          <Box
            sx={{
              border: '1px solid #ECEDF5',
              width: { md: '55%', xs: '100%' },
              borderRadius: '8px',
              pt: '32px'
            }}
          >
            <Box
              sx={{
                borderBottom: '1px solid #ECEDF5',
                px: { xs: '16px', sm: '32px' }
              }}
            >
              <Typography variant='h6' sx={{ fontWeight: '600' }}>
                Your Open Rate is{' '}
                <span style={{ color: results > 20 ? '#44d544' : '#D54444' }}>{results} %</span>
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: 4
                }}
              >
                <Typography>
                  {results > 20
                    ? 'This is a good CTO rate. However, this can be better by making sure you validate all your emails before sending.'
                    : 'This is not a good CTO rate. However, this can be better by making sure you validate all your emails before sending.'}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Button
            variant='contained'
            sx={{
              backgroundColor: '#6E58F1',
              '&:hover': {
                backgroundColor: '#7c68f1'
              },
              color: 'white',
              px: 4,
              py: 1.2,
              mt: 4,
              width: { md: '20%', xs: '100%' },
              borderRadius: '8px',
              minHeight: '35px'
            }}
            onClick={reSetData}
          >
            Start Over
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default EmailClickToOpenRateCalculator
