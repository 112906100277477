import React, { useState, useEffect } from 'react'
import { Box, Typography, CircularProgress, IconButton, Tooltip, Button, alpha } from '@mui/material'
import { Close, MoreVertOutlined } from '@mui/icons-material'
import { RiShareForwardFill } from 'react-icons/ri'
import parse from 'html-react-parser'
import InboxMoreMenu from './inboxMoreMenu'
import StatusLabelSelect from './statusLabelSelect'
import { replacePTags } from 'src/utils/util'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'

const EmailContent = ({
  email,
  mailReplies,
  isCampaignsEmailReplyLoading,
  handleClick,
  handleCloseEditor,
  handleEditClick,
  handleForwardClick,
  handleClose,
  open,
  anchorEl,
  theme,
  handleClickOpenLeadsDetailsDialog,
  handleClickOpenRemoveLeadDialog,
  handleClickOpenDeleteThreadDialog,
  handleClickOpenMoveToCampaignDialog,
  handlePortalEmailOpened,
  replyId,
  handleReload,
  isLabelsLoading,
  statusLabels,
  inboxSelectedID,
  fetchUpdatedData,
  handleClickOpenAddStatusDialog,
  sent,
  canEdit
}) => {
  const modifiedEmailBody = email?.body ? replacePTags(email?.body) : replacePTags(email?.campaign_email_id?.body)
  const workspace = useSelector((state) => state.workspace)
  const [timezone, setTimezone] = useState('')

  useEffect(() => {
    setTimezone(workspace?.timezone?.split(' ')[0])
  }, [workspace])

  const formatDate = (date) => {
    return moment(date).tz(timezone).format('h:mm A, MM/DD/YYYY')
  }

  const cleanHtml = (emailContent) => {
    return emailContent.replace(/<p(\s[^>]*)?>/gi, '<div$1>').replace(/<\/p>/gi, '</div>')
  }
  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: 'calc(100vh - 150px)', sm: 'calc(100vh - 110px)' },
        backgroundColor: 'white',
        p: 2,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        boxShadow: '0px 12px 15px 0px #4B71970D',
        borderRadius: '12px',
        flexDirection: 'column',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': { width: '14px' },
        '&::-webkit-scrollbar-track': { borderRadius: '60px' },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#E4E4E5',
          borderRadius: '10px',
          border: '4px solid rgba(0, 0, 0, 0)',
          backgroundClip: 'padding-box'
        },
        '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#d5d5d5' },
        position: 'relative'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Tooltip title='Close' placement='top' arrow>
          <IconButton sx={{ color: '#28287B' }} onClick={handleCloseEditor}>
            <Close />
          </IconButton>
        </Tooltip>
        {!sent && canEdit && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '18px',
                py: 1,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  color: theme.palette.primary.contrastText,
                  backgroundColor: alpha(theme.palette.primary.main, 0.8)
                }
              }}
              onClick={handleEditClick}
            >
              Reply
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', ml: 1 }}>
              <Tooltip title='Forward' placement='top' arrow>
                <IconButton sx={{ color: '#28287B' }} onClick={handleForwardClick}>
                  <RiShareForwardFill />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
              <Tooltip title='More' placement='top' arrow>
                <IconButton
                  onClick={handleClick}
                  size='small'
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  sx={{ color: '#28287B' }}
                >
                  <MoreVertOutlined />
                </IconButton>
              </Tooltip>
            </Box>
            <InboxMoreMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              theme={theme}
              handleClickOpenLeadsDetailsDialog={handleClickOpenLeadsDetailsDialog}
              handleClickOpenRemoveLeadDialog={handleClickOpenRemoveLeadDialog}
              handleClickOpenDeleteThreadDialog={handleClickOpenDeleteThreadDialog}
              handleClickOpenMoveToCampaignDialog={handleClickOpenMoveToCampaignDialog}
              handlePortalEmailOpened={handlePortalEmailOpened}
              replyId={replyId}
              handleReload={handleReload}
              sent={sent}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: { xs: 'column' },
          justifyContent: { xs: 'center' },
          alignItems: { xs: 'flex-start' },
          border: '1px solid #E4E4E5',
          borderRadius: '8px',
          my: 1,
          p: 2
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '18px',
            color: '#28287B'
          }}
        >
          {email?.leads?.email}
        </Typography>
        <StatusLabelSelect
          handleClickOpenAddStatusDialog={handleClickOpenAddStatusDialog}
          isLabelsLoading={isLabelsLoading}
          statusLabels={statusLabels}
          inboxSelectedID={inboxSelectedID}
          label={email?.label || email?.campaign_email_id?.label}
          fetchUpdatedData={fetchUpdatedData}
          canEdit={canEdit}
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          width: '100%',
          height: 'fit-content',
          p: 2,
          border: '1px solid #E4E4E5',
          borderRadius: '8px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': { width: '14px' },
          '&::-webkit-scrollbar-track': { borderRadius: '60px' },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E4E4E5',
            borderRadius: '10px',
            border: '4px solid rgba(0, 0, 0, 0)',
            backgroundClip: 'padding-box'
          },
          '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#d5d5d5' }
        }}
      >
        {isCampaignsEmailReplyLoading
          ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
              <CircularProgress sx={{}} size={25} thickness={5} />
              <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#4e88e6', ml: 2 }}>Loading...</Typography>
            </Box>
            )
          : (
            <>
              {Array.isArray(mailReplies) && mailReplies.map((j) => (
                !sent && (
                  <Box key={j.id}>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: { xs: 'center', sm: 'space-between' },
                        alignItems: { xs: 'flex-start', sm: 'center' }
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '18px',
                          color: '#28287B'
                        }}
                      >
                        {j.to}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '16px',
                          color: 'rgba(40, 40, 123, 0.5)'
                        }}
                      >
                        {moment(j.date).format('h:mm A')}, {moment(j.date).format('MM/DD/YYYY')}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        color: 'rgba(40, 40, 123, 0.5)',
                        mt: 1.5,
                        pb: 1.5,
                        borderBottom: '1px solid #E4E4E5'
                      }}
                    >
                      {j.subject === 'Re:' ? j.subject + ' ' + 'No Subject' : j.subject}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        lineHeight: '20px',
                        color: '#28287B',
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        flexDirection: 'column'
                      }}
                    >
                      {parse(cleanHtml(j.bodyTextHtml))}
                    </Typography>
                  </Box>
                )
              ))}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: { xs: 'center', sm: 'space-between' },
                  alignItems: { xs: 'flex-start', sm: 'center' }
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    color: '#28287B'
                  }}
                >
                  {sent ? email?.fromAccount?.email : email?.leads?.email}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    color: 'rgba(40, 40, 123, 0.5)'
                  }}
                >
                  {formatDate(email?.createdAt)}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: 'rgba(40, 40, 123, 0.5)',
                  mt: 1.5,
                  pb: 1.5,
                  borderBottom: '1px solid #E4E4E5'
                }}
              >
                {email?.subject || email?.campaign_email_id?.subject}
              </Typography>
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '20px',
                  color: '#28287B',
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  flexDirection: 'column'
                }}
              >
                {parse(modifiedEmailBody)}
              </Typography>
            </>
            )}
      </Box>
    </Box>
  )
}

export default EmailContent
