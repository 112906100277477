export const HelpArticleIcon = () => {
  return (
    <>
      <svg
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='40' height='40' rx='20' fill='#FFECDD' />
        <path
          d='M17.5594 29.5574H12.7594C11.4339 29.5574 10.3594 28.4828 10.3594 27.1574L10.3595 12.7574C10.3595 11.4319 11.434 10.3574 12.7595 10.3574H23.5597C24.8852 10.3574 25.9597 11.4319 25.9597 12.7574V17.5574M14.5598 15.1574H21.7598M14.5598 18.7574H21.7598M14.5598 22.3574H18.1598M21.1596 26.2484L26.2507 21.1573L29.6449 24.5514L24.5537 29.6426H21.1596V26.2484Z'
          stroke='#FF7000'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
