export const EmailFilterHasNoCustomTrackingDomainIcon = ({ color = '#28287B' }) => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.40039 4.7999L12.0004 4.7999M2.40039 11.9999H12.0004M12.0004 11.9999V14.3999M12.0004 11.9999V9.5999M2.40039 19.1999H7.20039M12.0004 19.1999L21.6004 19.1999M16.8004 11.9999H21.6004M16.8004 4.7999L21.6004 4.7999M16.8004 4.7999V7.1999M16.8004 4.7999V2.3999M7.80039 21.5999V16.7999'
          stroke={color}
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
