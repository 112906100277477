import React from 'react'

export const EDSFIleCheckIcon = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.3016 21.5998H5.50155C4.17607 21.5998 3.10155 20.5253 3.10156 19.1998L3.10166 4.79989C3.10166 3.47441 4.17618 2.3999 5.50166 2.3999L16.3019 2.3999C17.6274 2.3999 18.7019 3.47442 18.7019 4.7999V11.3999M13.9019 18.1999L16.1019 20.3999L20.9019 15.5997M7.30194 7.1999H14.5019M7.30194 10.7999H14.5019M7.30194 14.3999H10.9019'
          stroke='#28287B'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
