import AppBar from '@mui/material/AppBar'
import { Box, Alert } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import { Link as RouterLink } from 'react-router-dom'
import { Logo } from 'src/components/logo'
import VerificationLink from '../verification-link'

const topNavHeight = 64

const TopBar = ({ handleDrawerToggle, user, isMobile, isLoading }) => {
  return (
    <>
      <AppBar
        position='fixed'
        sx={{
          width: '100vw',
          height: topNavHeight,
          display: { xs: 'block', lg: 'none' }
        }}
      >
        <Toolbar sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Box
            component={RouterLink}
            to='/'
            sx={{
              ml: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '150px',
              height: '30px'
            }}
          >
            <Logo color='white' logoTextColor='white' />
          </Box>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box>
        {user && !isLoading && !user?.emailVerified && isMobile && (
          <Alert
            variant='outlined'
            severity='warning'
            sx={{
              backgroundColor: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              marginTop: '65px'
            }}
          >
            Your account needs verification to access all features.
            <VerificationLink />
          </Alert>
        )}
      </Box>
    </>
  )
}

export default TopBar
