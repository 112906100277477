import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  alpha,
  useMediaQuery,
  Menu,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Clear, LinkedIn } from '@mui/icons-material'
import EmailBlock from 'src/components/emailAccounts/emailBlock'
import EmailDrawer from 'src/components/emailAccounts/emailDrawer'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  accountDeleted,
  accountsDeleted,
  setAccounts,
  useBulkDeleteMutation,
  useGetAccountsMutation,
  useRemoveAccountMutation,
  useAssignTagsMutation
} from 'src/services/account-service.js'
import { useUpdateWorkSpaceNameMutation } from 'src/services/workSpace-service'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LinkedAccountBlock from 'src/components/emailAccounts/linkedAccountBlock'
import toast from 'react-hot-toast'
import { setDnsVitals, useGetDnsVitalsMutation } from 'src/services/dns-service.js'
import AddNewEmailBlock from 'src/components/emailAccounts/AddNewEmailBlock.js'
import BulkSettingEmailDrawer from 'src/components/emailAccounts/BulkSettingEmailDrawer.js'
import { useDispatch, useSelector } from 'react-redux'
import RemoveAccountDialog from 'src/components/RemoveAccountDialog.js'
import { FilterIcon } from 'src/assets/general/FilterIcon'
import { Plus } from 'src/assets/general/Plus'
import { SBSearch } from 'src/assets/sidebar/SBSearch'
import { EmailFilterPauseIcon } from 'src/assets/emailAccounts/filterMenu/EmailFilterPausedIcon'
import { EmailFilterHasErrorIcon } from 'src/assets/emailAccounts/filterMenu/EmailFilterHasError'
import { EmailFilterHasNoCustomTrackingDomainIcon } from 'src/assets/emailAccounts/filterMenu/EmailFilterNoCustomTrackingDomain'
import { EmailFilterWarmupActiveIcon } from 'src/assets/emailAccounts/filterMenu/EmailFilterWarmupActive'
import { EmailFilterWarmupPaused } from 'src/assets/emailAccounts/filterMenu/EmailFilterWarmupPaused'
import { EmailFilterWarmupErrorsIcon } from 'src/assets/emailAccounts/filterMenu/EmailFilterWarmupErrors'
import { EAEditIcon } from 'src/assets/emailAccounts/EAEditIcon'
import { EASettingsIcon } from 'src/assets/emailAccounts/EASettingsIcon'
import { OffCheckboxCustomIcon } from 'src/assets/general/OffCheckboxCustomIcon'
import { OnCheckboxCustomIcon } from 'src/assets/general/OnCheckboxCustomIcon'
import { EATrashIconBlackSmallIcon } from 'src/assets/emailAccounts/EATrashIconBlackSmall'
import { AiOutlineClose } from 'react-icons/ai'
import Pagination from 'src/components/Pagination'
import { useGetMeAndWorkspaceQuery, useGetMeQuery } from 'src/services/user-service'
import UserVerification from 'src/components/emailAccounts/UserVerification'
import { FaTags } from 'react-icons/fa'
import CreateTagsDialog from '../components/CreateTagsDialog'
import AddIcon from '@mui/icons-material/Add'
import { useGetAllTagsMutation } from 'src/services/tag-service'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import MetaPixel from './metaPixal'
import RefreshButton from 'src/components/RefreshButton'
import HeaderWithPopover from 'src/components/HeaderWithPopover'
import { useAuth } from '../auth/auth'
import { config } from 'src/config'
// import { getAuthEmail } from "src/services/auth-service";
import axios from 'axios'

// const linkedAccountsIcons = {
//   LINKEDIN: <LinkedIn />,
//   WHATSAPP: <WhatsAppIcon />
// }
const filterButtons = [
  {
    name: 'On Pause',
    value: 'paused',
    icon: (active) => <EmailFilterPauseIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'Issues Detected',
    value: 'has_errors',
    icon: (active) => <EmailFilterHasErrorIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'No Custom Tracking Domain',
    value: 'no_custom_tracking_domain',
    icon: (active) => (
      <EmailFilterHasNoCustomTrackingDomainIcon color={active ? '#0071F6' : '#28287B'} />
    )
  },
  {
    name: 'Warmup In Progress',
    value: 'warmup_active',
    icon: (active) => <EmailFilterWarmupActiveIcon color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'Warmup Paused',
    value: 'warmup_paused',
    icon: (active) => <EmailFilterWarmupPaused color={active ? '#0071F6' : '#28287B'} />
  },
  {
    name: 'Warmup Issues',
    value: 'warmup_has_errors',
    icon: (active) => <EmailFilterWarmupErrorsIcon color={active ? '#0071F6' : '#28287B'} />
  }
  // {
  //   name: "LinkedIn Accounts",
  //   value: "linkedin",
  //   icon: (active) => <LinkedIn color={active ? "#0071F6" : "#28287B"} />,
  // },
  // {
  //   name: "WhatsApp Accounts",
  //   value: "whatsapp",
  //   icon: (active) => <WhatsAppIcon color={active ? "#0071F6" : "#28287B"} />,
  // },
]

const BulkSettings = ({
  checkedAll,
  handleChangeCheckedAll,
  checkedAccounts,
  setOpenBulkSettingDrawer,
  handleBulkDelete,
  isMobile,
  canEdit,
  emailSearch
}) => {
  const [openCreateTagModal, setOpenCreateTagModal] = useState(false)
  const [, setAnchorEl] = useState(null)
  const [getAllTags] = useGetAllTagsMutation()
  const [assignTags] = useAssignTagsMutation()
  const [tagStatus, setTagStatus] = useState([])
  const [getData, setGetData] = useState(false)
  const [search, setSearch] = useState('')
  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const dispatch = useDispatch()
  const accounts = useSelector((state) => state.accounts)

  const handleAssignTags = async (tag) => {
    try {
      setGetData(true)
      const { accounts } = await assignTags({ ids: checkedAccounts, tag, workspaceId, search: emailSearch }).unwrap()
      dispatch(setAccounts(accounts))
      setGetData(false)
    } catch (error) {
      console.error('Failed to fetch accounts:', error)
    }
  }

  const handleManageChecks = (tags) => {
    try {
      const matchingTags = getMatchingTags(checkedAccounts, accounts)

      const flattenedTags = matchingTags.flat()

      const tagCounts = flattenedTags.reduce((acc, tag) => {
        acc[tag.label] = (acc[tag.label] || 0) + 1
        return acc
      }, {})

      const totalCheckedAccounts = checkedAccounts.length
      const tagStatuses = tags.map(tag => ({
        label: tag?.label,
        inAllAccounts: tagCounts[tag?.label] === totalCheckedAccounts,
        used: tagCounts[tag?.label] > 0
      }))
      setTagStatus(tagStatuses)
    } catch (error) {
      console.error('Failed to fetch accounts:', error)
    }
  }
  function getMatchingTags(checked, acc) {
    return acc.filter(account => checked.includes(account?._id)).map(account => account?.tags)
  };

  const handleGetAllTags = async () => {
    try {
      if (workspaceId) {
        const tags = await getAllTags({ search, workspaceId }).unwrap()
        setTagStatus(tags)
        handleManageChecks(tags)
      }
    } catch (error) {
      console.error('Failed to fetch tags:', error)
    }
  }

  useEffect(() => {
    handleGetAllTags()
  }, [search, getData])

  const handleApplyTagsClick = () => {
    setOpenCreateTagModal(true)
    handleCloseTagsModal()
  }
  const handleCloseModal = () => {
    setOpenCreateTagModal(false)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const ITEM_HEIGHT = 48

  const [anchorElTagsModal, setAnchorElTagsModal] = useState(null)
  const open2 = Boolean(anchorElTagsModal)
  const handleClickChecks = (event) => {
    handleClose()
    setAnchorElTagsModal(event.currentTarget)
  }
  const handleCloseTagsModal = () => {
    setAnchorElTagsModal(null)
    setSearch('')
  }

  return (
    <>
      {checkedAccounts.length !== 0 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            backgroundColor: 'white',
            borderRadius: '12px',
            px: 2,
            py: 1.5,
            boxShadow: '0px 12px 15px 0px #4B71970D',
            mt: { xs: 0, md: 3 },
            gap: 2
          }}
        >
          <Tooltip title={checkedAll ? 'Deselect all' : 'Select all'} arrow>
            <Checkbox
              checked={checkedAll}
              onChange={handleChangeCheckedAll}
              inputProps={{ 'aria-label': 'controlled' }}
              icon={<OffCheckboxCustomIcon />}
              checkedIcon={<OnCheckboxCustomIcon />}
              size='small'
            />
          </Tooltip>
          <>
            <Button
              variant='outlined'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '16.38px',
                color: '#28287B',
                backgroundColor: 'white',

                '&:hover': {
                  backgroundColor: 'white'
                  // boxShadow: 10,
                },
                border: '1px solid #E4E4E5',
                height: '36px',
                px: 2
              }}
              onClick={() => setOpenBulkSettingDrawer(true)}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 1
                }}
              >
                <EAEditIcon />
              </Box>
              {isMobile ? 'Bulk Config' : 'Bulk Configurations'}
            </Button>
            <Button
              variant='outlined'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '16.38px',
                color: '#28287B',
                backgroundColor: 'white',

                '&:hover': {
                  backgroundColor: 'white'
                  // boxShadow: 10,
                },
                border: '1px solid #E4E4E5',
                height: '36px',
                px: 2
              }}
              onClick={(event) => {
                handleClickChecks(event)
                handleGetAllTags()
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 1
                }}
              />
              <FaTags style={{ marginRight: '12px' }} fontSize='medium' />
              Tags
            </Button>
            <CreateTagsDialog
              open={openCreateTagModal}
              onClose={handleCloseModal}
              checkedAccounts={checkedAccounts}
              accounts={accounts}
            />
            <Button
              variant='outlined'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '13px',
                fontWeight: 700,
                lineHeight: '16.38px',
                color: '#28287B',
                backgroundColor: 'white',

                '&:hover': {
                  backgroundColor: 'white'
                  // boxShadow: 10,
                },
                border: '1px solid #E4E4E5',
                height: '36px',
                px: 2
              }}
              onClick={handleBulkDelete}
              disabled={!canEdit}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mr: 1
                }}
              >
                <EATrashIconBlackSmallIcon />
              </Box>
              Delete
            </Button>
            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorElTagsModal}
              open={open2}
              onClose={handleCloseTagsModal}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 6,
                  width: '20ch',
                  display: 'flex',
                  flexDirection: 'column'
                }
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <Box sx={{ position: 'sticky', top: 0, zIndex: 1, background: 'white', paddingLeft: '12px' }}>
                <TextField
                  placeholder='Search...'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  fullWidth
                  variant='standard'
                  margin='dense'
                />
              </Box>
              <Box style={{ overflowY: 'auto', maxHeight: ITEM_HEIGHT * 4 }}>
                {tagStatus.length === 0
                  ? (
                    <Typography sx={{ padding: '8px', textAlign: 'center', color: 'gray' }}>
                      No results found
                    </Typography>
                  )
                  : (
                    tagStatus?.map((tag, index) => (
                      <MenuItem
                        key={index}
                        selected={false}
                        onClick={() => {
                          handleAssignTags(tag?.label)
                        }}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                        {tag?.label}
                        {tag?.used
                          ? (
                            tag?.inAllAccounts
                              ? (
                                <CheckCircleIcon sx={{ color: '#61d345', height: 20, width: 20 }} />
                              )
                              : (
                                <RemoveCircleIcon sx={{ color: '#61d345', height: 20, width: 20 }} />
                              )
                          )
                          : null}
                      </MenuItem>
                    ))
                  )}
              </Box>
              {canEdit && (
                <Box sx={{ position: 'sticky', bottom: 0, zIndex: 1, background: 'white' }}>
                  <MenuItem
                    onClick={handleApplyTagsClick}
                    onClose={handleCloseTagsModal}
                  >
                    <IconButton
                      color='primary'
                      style={{ display: 'flex', alignItems: 'left' }}
                    >
                      <AddIcon />
                      <Box sx={{ fontSize: '1rem', marginLeft: '5px' }}>Create Tag</Box>
                    </IconButton>
                  </MenuItem>
                </Box>
              )}
            </Menu>
          </>

        </Box>
      )}
    </>
  )
}

const Page = () => {
  const { data: user } = useGetMeQuery()
  const [allLinkedAccounts, setAllLinkedAccounts] = useState(null)
  const [accountDeleteLoading, setAccountDeleteLoading] = useState(null)
  const [isFetchingAllAccounts] = useState(false)
  // const [isFetchingAllAccounts, setIsFetchingAllAccounts] = useState(false)
  const [accountReconnectLoading, setAccountReconnectLoading] = useState(null)
  const [linkedInFilterSelected, setLinkedInFilterSelected] = useState(false)
  const [whatsAppFilterSelected, setWhatsAppFilterSelected] = useState(false)
  const [reconnectLinkedinAccountEmail, setReconnectLinkedinAccountEmail] = useState(null)
  const [reconnectLinkedinAccountPassword, setReconnectLinkedinAccountPassword] = useState('')
  const [openReconnectLinkedInAccountModal, setOpenReconnectLinkedInAccountModal] = useState(false)
  const [accountIdToReconnect, setAccountIdToReconnect] = useState(null)

  const reconnectLinkedinModalToggle = (id, email) => {
    setAccountIdToReconnect(id)
    setReconnectLinkedinAccountEmail(email)
    setOpenReconnectLinkedInAccountModal(!openReconnectLinkedInAccountModal)
  }
  // script for twitter
  useEffect(() => {
    fetchAllLinkedAccounts()
    // Function to insert the Twitter conversion tracking script and configure it
    const insertTwitterConversionScript = () => {
      const script = document.createElement('script')
      script.async = true
      script.src = 'https://static.ads-twitter.com/uwt.js'
      script.onload = () => {
        // Once the script is loaded, configure Twitter conversion tracking
        if (window.twq) {
          window.twq('config', 'olxmp')
        } else {
          console.error('Twitter conversion tracking script loaded, but twq function is not defined.')
        }
      }
      script.onerror = () => {
        console.error('Failed to load Twitter conversion tracking script.')
      }
      document.head.appendChild(script)
    }

    // Check if the twq function is already defined
    if (typeof window.twq === 'undefined') {
      // If not, insert the script
      insertTwitterConversionScript()
    } else {
      // If the twq function is already defined, directly configure it
      window.twq('config', 'olxmp')
    }

    // Clean up function to remove the script when the component unmounts
    return () => {
      const existingScript = document.querySelector('script[src="https://static.ads-twitter.com/uwt.js"]')
      if (existingScript) {
        console.log('Removing Twitter conversion tracking script...')
        document.head.removeChild(existingScript)
      }
    }
  }, []) // Dependency array is empty, so the effect runs only once when the component mounts

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation() // Get the current location
  const { canEdit } = useAuth()
  const params = new URLSearchParams(location.search)
  const theme = useTheme()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTab = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })
  const anchor = 'right'
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const [blockClickedID, setBlockClickedID] = useState()

  const [openBulkSettingDrawer, setOpenBulkSettingDrawer] = useState(false)

  const accounts = useSelector((state) => state.accounts)
  const [getAccounts, { isLoading: isAccountsLoading }] = useGetAccountsMutation()

  // Search, filter and pagination
  const [isLoadingMoreAccounts] = useState(false)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState(null)
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [cardName, setCardName] = useState('Card View')
  const [refresh, setRefresh] = useState(false)
  const [workspaceId, setWorkspaceId] = useState()
  const workspace = useSelector((state) => state.workspace)
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  }, [workspace])

  const offset = accounts.length

  useEffect(() => {
    setTimeout(async () => {
      if (workspaceId) {
        const { docs, total } = await getAccounts({ search, filter: filter?.value, offset: offset * (page - 1), limit, workspaceId }).unwrap()
        dispatch(setAccounts(docs))
        setTotal(total)
        const warmedUpEmail = docs.filter((data) => data?.warmup?.status === 'enabled')
        window.Intercom('update', {
          connected_email_accounts: total,
          warmedup_email_accounts: warmedUpEmail?.length
        })
      }
    }, 500)
  }, [search, filter, limit, page, refresh, workspaceId])

  const [removeAccount] = useRemoveAccountMutation()

  const handleDelete = async (id) => {
    await toast.promise(removeAccount(id).unwrap(), {
      loading: 'Deleting...',
      success: 'Account Deleted!',
      error: 'Could not delete'
    })
    dispatch(accountDeleted(id))
  }

  const [tabValue, setTabValue] = useState(0)

  const handleSearchChange = (event) => {
    setSearch(event.target.value)
    setPage(1)
  }

  const handleSelectFilter = (filter) => {
    setFilter(filter)
    setPage(1)
  }

  // DNS Vitals
  const dnsVitals = useSelector((state) => state.dns.vitals)
  const [getDnsVitals, { isLoading: loadingDNS }] = useGetDnsVitalsMutation()

  const handleTestDomainSetup = async () => {
    const toastId = toast.loading('Verifying MX, SPF, DKIM, and DMARC records for all domains', {
      duration: Infinity
    })

    const dnsVitals = await getDnsVitals({ accounts: accounts.map((a) => a) }).unwrap()
    dispatch(setDnsVitals(dnsVitals))

    const failureCount = dnsVitals.failureCount
    const toastOptions = { id: toastId, duration: 2000 }

    if (failureCount > 0) {
      toast.error(`${failureCount} domains failed DNS check`, toastOptions)
    } else {
      toast.success('All domains passed DNS check', toastOptions)
    }
  }

  const getDnsRecordForEmail = (email) =>
    dnsVitals.records && dnsVitals.records[email.split('@')[1]]

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [bulkSettings, setBulkSettings] = useState(false)

  const [checkedAll, setCheckedAll] = useState(false)
  const [checkedAccounts, setCheckedAccounts] = useState([])

  const handleChangeCheckedAll = async (event) => {
    if (event.target.checked) {
      const { docs } = await getAccounts({
        search,
        filter: filter?.value,
        limit: total,
        workspaceId
      }).unwrap()
      setCheckedAccounts(docs.map((account) => account._id))
      setCheckedAll(true)
    } else {
      setCheckedAccounts([])
      setCheckedAll(false)
    }
  }

  const handleAccountCheckChange = (checked, id) => {
    if (checked) {
      setCheckedAccounts([...checkedAccounts, id])
    } else {
      if (checkedAll) {
        setCheckedAll(false)
      }
      setCheckedAccounts(checkedAccounts.filter((account) => account !== id))
    }
  }

  useEffect(() => {
    accounts?.length === 0 && setBulkSettings(false)
  }, [checkedAccounts, accounts])

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [bulkDelete] = useBulkDeleteMutation()

  const handleBulkDelete = async () => {
    setConfirmDialogOpen(true)
  }

  const handleConfirmDialogSubmit = async () => {
    setConfirmDialogOpen(false)
    const toastId = toast.loading('Deleting Accounts', { duration: Infinity })
    const { message } = await bulkDelete({ ids: checkedAccounts, workspaceId }).unwrap()
    dispatch(accountsDeleted({ ids: checkedAccounts }))
    toast.success(message, { id: toastId, duration: 2000 })
  }

  const handleLimitChange = (event) => {
    setLimit(event.target.value)
    setPage(1)
  }

  const navigateToEmailAccountAddition = () => {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
      window.localStorage.removeItem('reconnect')
    }
    navigate('/accounts/connect')
  }

  const { shouldDisableButton: isDisabledButton } = UserVerification()
  // const [workspace, setWorkspace] = useState(null);

  const handleWorkspaceChange = (newWorkspace) => {
    // setWorkspace(newWorkspace);
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  }

  const names = [
    'Card View',
    'Table View'
  ]

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setCardName(value)
  }

  const handleRefresh = () => {
    setRefresh(!refresh)
  }
  const { data, refetch } = useGetMeAndWorkspaceQuery()

  useEffect(() => {
    refetch()
  }, [workspace])

  const [updateWorkSpaceName] = useUpdateWorkSpaceNameMutation()

  const handleReconnectLinkedAccount = async (id) => {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
      },
      body: JSON.stringify({ provider: 'LINKEDIN', username: reconnectLinkedinAccountEmail, password: reconnectLinkedinAccountPassword })
    }
    setAccountReconnectLoading(id)
    const resp = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts/${id}`, options)
    const data = await resp.json()
    console.log(data)
    if (data.object === 'AccountReconnected') {
      toast.success('Account Reconnected!')
      reconnectLinkedinModalToggle()
      setReconnectLinkedinAccountEmail(null)
      setReconnectLinkedinAccountPassword('')
    } else if (data.status >= 400) toast.error(data.title)
    else toast.error('Error Reconnecting Account')
    fetchAllLinkedAccounts()
    setAccountReconnectLoading(null)
  }

  const handleDeleteLinkedAccountFromDB = async (id) => {
    const response = await axios.delete(`${config.API_BASE_URL}/multichannel/deleteLinkedAccount/${id}`)
    if (response.data.success) {
      toast.success('Account Deleted!')
      fetchAllLinkedAccounts()
    } else {
      toast.error(response.data.error)
    }
  }
  const handleDeleteLinkedAccount = async (id) => {
    const options = {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
      }
    }
    setAccountDeleteLoading(id)
    const response = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts/${id}`, options)
    const data = await response.json()
    console.log(data)
    if (data?.object === 'AccountDeleted' || data?.status === 404 || data?.status === 503) {
      setAccountDeleteLoading(null)
      setAllLinkedAccounts(allLinkedAccounts.filter((account) => account.id !== id))
      handleDeleteLinkedAccountFromDB(id)
      // fetchAllLinkedAccounts();
    } else {
      toast.error('Error Deleting Account')
    }
  }

  // const fetchLinkedInProfile = async (id) => {
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     }
  //   }

  //   const response = await fetch(`${config.UNIPILE_DSN}/api/v1/users/me?account_id=${id}`, options)
  //   const data = await response.json()
  //   return data
  // }

  const fetchAllLinkedAccounts = async () => {
    // console.log("fetching linked accounts");
    const email = user?.email
    const workspace_id = workspace?._id
    const filter = linkedInFilterSelected ? 'LINKEDIN' : whatsAppFilterSelected ? 'WHATSAPP' : null
    const response = await axios.post(`${config.API_BASE_URL}/multichannel/getLinkedAccounts`, { email, workspace_id, filter })
    if (response.data.success) {
      setAllLinkedAccounts(response.data.accounts)
    } else {
      toast.error(response.data.error)
    }
  }

  useEffect(() => {
    fetchAllLinkedAccounts()
  }, [linkedInFilterSelected, whatsAppFilterSelected, user, refresh, workspaceId])

  // const fetchAllLinkedAccounts = async () => {
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       'X-API-KEY': config.UNIPILE_ACCESS_TOKEN
  //     }
  //   };
  //   setIsFetchingAllAccounts(true);
  //   const response = await fetch(`${config.UNIPILE_DSN}/api/v1/accounts`, options)
  //   const data = await response.json();
  //   console.log("resppp:",data);
  //   let detailedData = [];
  //   if (data?.status >= 400) {
  //     setIsFetchingAllAccounts(false);
  //     toast.error(data?.title);
  //     return;
  //   }

  //   for (const item of data?.items) {
  //     if (item.type === 'LINKEDIN') {
  //       const linkedInProfile = await fetchLinkedInProfile(item.id);
  //       detailedData.push({ ...item, linkedInProfile });
  //     } else {
  //       detailedData.push(item);
  //     }
  //   }

  // Set the data only after all API calls are completed
  //     setAllLinkedAccounts(detailedData);
  //     setIsFetchingAllAccounts(false);
  // }

  useEffect(() => {
    if (workspace?._id && !workspace?.istimezone) {
      const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone

      const offset = -new Date().getTimezoneOffset()
      const hours = Math.floor(offset / 60)
      const minutes = Math.abs(offset % 60)

      const gmtOffset = `GMT${hours >= 0 ? '+' : '-'}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`

      const userTimezone = `${timeZoneName} (${gmtOffset})`

      const updateWorkspace = async () => {
        try {
          const { workSpace } = await updateWorkSpaceName({
            id: workspace?._id,
            data: {
              timezone: userTimezone,
              timezoneOnly: true
            }
          }).unwrap()
          // will remove the console after testing it on staging
          console.log('updated the timezone', workSpace)
        } catch (error) {
          console.error('Error updating workspace:', error)
        }
      }

      updateWorkspace()
    }
  }, [workspace])

  const title = (data?.workspace?.enterprise && data?.workspace?.warmup?.uiForWarmup)
    ? 'Accounts'
    : 'Accounts'
  return (
    <>
      {
        (params.get('user') === 'lifetimepaidUser' || params.get('user') === 'freeUser') &&
        <MetaPixel isFreeUser={params.get('user') !== 'lifetimepaidUser'} />
      }

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          width: '100%',
          height: '100%'
        }}
      >
        <Box
          sx={{
            width: '90%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >

          <HeaderWithPopover title={title} onWorkspaceChange={handleWorkspaceChange} selectedTimeline={data?.workspace} />
          <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
            <Box sx={{ marginLeft: 'auto' }}>
              {canEdit && (
                <Button
                  disabled={isDisabledButton}
                  id='Connect New Account'
                  sx={{
                    display: {
                      xs: workspace.enterprise ? 'flex' : 'none',
                      md: 'flex'
                    },
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: '#164694',
                      boxShadow: 10
                    },
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: 700,
                    lineHeight: '18px',
                    letterSpacing: '0em',
                    color: 'white',
                    backgroundColor: isDisabledButton ? '#e3e3e3' : '#0071F6',
                    borderRadius: '8px',
                    px: 0.5,
                    py: 1.5,
                    pr: '18px'
                  }}
                  variant='outlined'
                  size='small'
                  onClick={navigateToEmailAccountAddition}
                  title='Click to add a new email account'
                >
                  <Box
                    sx={{ mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Plus />
                  </Box>
                  {isMobile ? 'Connect' : 'Connect New Account'}
                </Button>
              )}
            </Box>
          </Box>
          {workspace?.enterprise && !workspace?.warmup?.uiForWarmup && (
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 700,
                lineHeight: '16.38px',
                color: '#0071f6',
                mt: 3
              }}
            >
              Purchase the Warmup UI Plan to manage your warmup process effectively.
            </Typography>
          )}
          {workspace?._id && ((workspace?.warmup?.uiForWarmup && workspace?.enterprise) || !workspace?.enterprise) && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: { xs: 'column', md: 'row' },
                  alignItems: 'center',
                  width: '100%',
                  mt: 2,
                  rowGap: { xs: 1, md: 0 }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: { xs: 'space-between', md: 'center' },
                    alignItems: 'center',
                    width: { xs: '100%', md: 'fit-content' }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex'
                    }}
                  >
                    {canEdit && (
                      <Button
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '13px',
                          fontWeight: 700,
                          lineHeight: '16.38px',
                          color: '#28287B',
                          backgroundColor: 'white',
                          mr: { xs: 0.5, sm: 1, md: 2 },
                          '&:hover': {
                            backgroundColor: 'white'
                            // boxShadow: 10,
                          },
                          border: loadingDNS ? '1px solid #0071F6' : '1px solid #E4E4E5',
                          height: '40px',
                          px: 2
                        }}
                        color='inherit'
                        onClick={() => {
                          !loadingDNS && handleTestDomainSetup()
                        }}
                        disabled={!!(isAccountsLoading || accounts?.length === 0)}
                      >
                        {loadingDNS
                          ? (
                            <CircularProgress size={16} sx={{ mr: 1, color: '#0071F6' }} thickness={5} />
                          )
                          : (
                            <Box
                              sx={{ mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                              <EASettingsIcon />
                            </Box>
                          )}
                        Test domain setup
                      </Button>
                    )}
                    <RefreshButton onClick={handleRefresh} />
                  </Box>
                  {canEdit && (
                    <Button
                      disabled={isDisabledButton}
                      id='Connect New Account'
                      sx={{
                        display: {
                          xs: !workspace.enterprise ? 'flex' : 'none',
                          md: 'none'
                        },
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: '#164694',
                          boxShadow: 10
                        },
                        ml: 1,
                        textAlign: 'left',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '16px',
                        letterSpacing: '0em',
                        color: 'white',
                        backgroundColor: isDisabledButton ? '#e3e3e3' : '#0071F6',
                        borderRadius: '8px',
                        px: 1,
                        py: 0.9,
                        pr: '18px'
                      }}
                      variant='outlined'
                      size='large'
                      onClick={navigateToEmailAccountAddition}
                      title='Click to add a new email account'
                    >
                      <Box sx={{ mr: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Plus />
                      </Box>
                      {isMobile ? 'Connect' : 'Connect New Account'}
                    </Button>
                  )}
                </Box>
                {!bulkSettings && canEdit && (
                  <Box sx={{ display: { xs: 'block', md: 'none', width: '100%' } }}>
                    <BulkSettings
                      checkedAll={checkedAll}
                      handleChangeCheckedAll={handleChangeCheckedAll}
                      checkedAccounts={checkedAccounts}
                      setOpenBulkSettingDrawer={setOpenBulkSettingDrawer}
                      handleBulkDelete={handleBulkDelete}
                      setBulkSettings={setBulkSettings}
                      isMobile={isMobile}
                      accounts={accounts}
                      canEdit={canEdit}
                      emailSearch={search}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: { xs: 'flex-end', md: 'center' },
                    flexDirection: { xs: 'column-reverse', md: 'row' },
                    rowGap: { xs: 1, md: 0 },
                    width: { xs: '100%', md: 'fit-content' }
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    {filter && (
                      // <Tooltip title="Remove Filters" placement="top" arrow>
                      //   <IconButton onClick={() => handleSelectFilter(null)}>
                      //     <DeleteIcon />
                      //   </IconButton>
                      // </Tooltip>
                      <Button
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '13px',
                          fontWeight: 700,
                          lineHeight: '16.38px',
                          color: theme.palette.primary.main,
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),

                          mr: 2,
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.2)
                            // boxShadow: 10,
                          },

                          height: '40px',
                          px: 2
                        }}
                        onClick={() => handleSelectFilter(null)}
                      >
                        {filter.name}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 1
                          }}
                        >
                          <AiOutlineClose />
                        </Box>
                      </Button>
                    )}
                    {linkedInFilterSelected && (
                      // <Tooltip title="Remove Filters" placement="top" arrow>
                      //   <IconButton onClick={() => handleSelectFilter(null)}>
                      //     <DeleteIcon />
                      //   </IconButton>
                      // </Tooltip>
                      <Button
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '13px',
                          fontWeight: 700,
                          lineHeight: '16.38px',
                          color: theme.palette.primary.main,
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),

                          mr: 2,
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.2)
                            // boxShadow: 10,
                          },

                          height: '40px',
                          px: 2
                        }}
                        onClick={() => setLinkedInFilterSelected(false)}
                      >
                        LinkedIn
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 1
                          }}
                        >
                          <AiOutlineClose />
                        </Box>
                      </Button>
                    )}
                    {whatsAppFilterSelected && (
                      // <Tooltip title="Remove Filters" placement="top" arrow>
                      //   <IconButton onClick={() => handleSelectFilter(null)}>
                      //     <DeleteIcon />
                      //   </IconButton>
                      // </Tooltip>
                      <Button
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          fontSize: '13px',
                          fontWeight: 700,
                          lineHeight: '16.38px',
                          color: theme.palette.primary.main,
                          backgroundColor: alpha(theme.palette.primary.main, 0.1),

                          mr: 2,
                          '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.2)
                            // boxShadow: 10,
                          },

                          height: '40px',
                          px: 2
                        }}
                        onClick={() => setWhatsAppFilterSelected(false)}
                      >
                        WhatsApp
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ml: 1
                          }}
                        >
                          <AiOutlineClose />
                        </Box>
                      </Button>
                    )}
                    {!isMobile && !isTab && (
                      <FormControl
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: 150,
                          mr: 1
                        }}
                      >
                        <Select
                          displayEmpty
                          value={cardName}
                          onChange={handleChange}
                          input={<OutlinedInput />}
                          MenuProps={MenuProps}
                          inputProps={{ 'aria-label': 'Without label' }}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '13px',
                            fontWeight: 700,
                            lineHeight: '16.38px',
                            color: '#28287B',
                            backgroundColor: 'white',
                            mr: { xs: 0, md: 2 },
                            '&:hover': {
                              backgroundColor: 'white'
                            },
                            height: '40px',
                            px: 2
                          }}
                        >
                          {names.map((name) => (
                            <MenuItem
                              key={name}
                              value={name}
                            >
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <Button
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '13px',
                        fontWeight: 700,
                        lineHeight: '16.38px',
                        color: '#28287B',
                        backgroundColor: 'white',
                        mr: { xs: 0, md: 2 },
                        '&:hover': {
                          backgroundColor: 'white'
                          // boxShadow: 10,
                        },
                        border: filter ? '1px solid #0071F6' : '1px solid #E4E4E5',
                        height: '40px',
                        px: 2
                      }}
                      onClick={handleClick}
                    >
                      <Box
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}
                      >
                        <FilterIcon />
                      </Box>
                      Filter
                    </Button>
                  </Box>

                  <form autoComplete="off">
                    <TextField
                      placeholder='Search by email'
                      autoComplete='off'
                      inputMode='text'
                      name='unique-email-search-field'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <IconButton sx={{ width: 32, height: 32 }}>
                              {search ? (
                                <Clear onClick={() => setSearch('')} />
                              )
                                : (
                                  <SBSearch color='rgba(40, 40, 123, 0.5)' />
                                )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      variant='outlined'
                      sx={{
                        width: { xs: '100%', md: 300 },
                        height: 40,
                        backgroundColor: 'white',
                        '& div': { pl: 0.3 },
                        '& div fieldset': { borderRadius: '8px', border: '1px solid #E4E4E5' },
                        '& div input': {
                          py: 1.3,
                          fontSize: '13px',
                          fontWeight: 400,
                          lineHeight: '16px',
                          letterSpacing: '0em',
                          '&::placeholder': {
                            color: 'rgba(40, 40, 123, 0.5)'
                          }
                        }
                      }}
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </form>
                </Box>
              </Box>
              {!bulkSettings && canEdit && (
                <Box sx={{ display: { xs: 'none', md: 'block', width: '100%' } }}>
                  <BulkSettings
                    checkedAll={checkedAll}
                    handleChangeCheckedAll={handleChangeCheckedAll}
                    checkedAccounts={checkedAccounts}
                    setOpenBulkSettingDrawer={setOpenBulkSettingDrawer}
                    handleBulkDelete={handleBulkDelete}
                    setBulkSettings={setBulkSettings}
                    isMobile={isMobile}
                    canEdit={canEdit}
                    emailSearch={search}
                  />
                </Box>
              )}

              {isFetchingAllAccounts || (isAccountsLoading && !isLoadingMoreAccounts)
                ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                    <CircularProgress sx={{}} size={25} thickness={5} />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#4e88e6', ml: 2 }}>
                      Loading...
                    </Typography>
                  </Box>
                )
                : (
                  <Grid container sx={{ mt: 2 }} spacing={2} columnSpacing={3}>
                    {cardName === 'Table View' && !isMobile && !isTab && accounts.length > 0
                      ? (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                        >
                          <Box
                            sx={{
                              width: '100%',
                              height: '100%',
                              pt: 1.5,
                              borderRadius: '12px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              flexDirection: 'column'
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                flexDirection: { xs: 'column-reverse', md: 'row' }
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  width: '150vw',
                                  maxWidth: { xs: '100%', md: 'calc(100% - 190px)' },
                                  ml: 7
                                }}
                              >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'space-between',
                                      alignItems: 'flex-start',
                                      gap: '2px',
                                      width: { md: '39vw', lg: '30vw', xl: '36vw' }
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        display: 'flex',
                                        ml: 1,
                                        fontSize: '11px',
                                        fontWeight: 800,
                                        lineHeight: '18px',
                                        color: '#6d727e',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%'
                                      }}
                                    >
                                      EMAIL
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  width: '50vw',
                                  maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                                }}
                              >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      gap: '2px',
                                      width: '10vw'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ml: 1,
                                        fontSize: '11px',
                                        fontWeight: 800,
                                        lineHeight: '18px',
                                        color: '#6d727e',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%'
                                      }}
                                    >
                                      EMAILS SENT
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  width: '50vw',
                                  maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                                }}
                              >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      alignItems: 'center',
                                      gap: '2px',
                                      width: '10vw'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ml: 1,
                                        fontSize: '11px',
                                        fontWeight: 800,
                                        lineHeight: '18px',
                                        color: '#6d727e',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%'
                                      }}
                                    >
                                      WARMUP EMAILS
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  width: '100%',
                                  maxWidth: { xs: '100%', md: 'calc(100% - 190px)' }
                                }}
                              >
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: '2px',
                                      width: '100%'
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        ml: 1,
                                        fontSize: '11px',
                                        fontWeight: 800,
                                        lineHeight: '18px',
                                        color: '#6d727e',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%'
                                      }}
                                    >
                                      HEALTH SCORE
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  alignItems: 'center',
                                  width: '100%'
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      )
                      : (
                        <></>
                      )}
                    {!linkedInFilterSelected && !whatsAppFilterSelected && accounts?.map((account) => {
                      return (
                        <Grid
                          item
                          key={account._id}
                          xs={12}
                          sm={cardName === 'Card View' ? 6 : 12}
                          onClick={(e) => {
                            setBlockClickedID(account._id)
                          }}
                        >
                          <EmailBlock
                            user={user}
                            isAccountChecked={checkedAccounts.some((a) => a === account._id)}
                            onAccountCheckChange={handleAccountCheckChange}
                            bulkSettingsOn={bulkSettings}
                            dnsRecord={getDnsRecordForEmail(account.email)}
                            account={account}
                            onClick={(e) => {
                              setTabValue(0)
                              toggleDrawer(true)(e)
                            }}
                            onDelete={handleDelete}
                            onClickSettings={(e) => {
                              setTabValue(1)
                              toggleDrawer(true)(e)
                            }}
                            cardName={cardName}
                            canEdit={canEdit}
                          />
                        </Grid>
                      )
                    })}
                    {allLinkedAccounts?.length > 0 &&
                      allLinkedAccounts
                        ?.filter((account) => account.account_obj?.email?.toLowerCase().includes(search.toLowerCase()) || search === '')
                        ?.map((account, index) =>
                          <Grid
                            item
                            key={`${account._id}-${index}`}
                            xs={12}
                            sm={cardName === 'Card View' ? 6 : 12}
                          >
                            <LinkedAccountBlock
                              account={account}
                              cardName={cardName}
                              onDelete={handleDeleteLinkedAccount}
                              deleteLoading={accountDeleteLoading}
                              onReconnect={reconnectLinkedinModalToggle}
                              reconnectLoading={accountReconnectLoading}
                            />
                          </Grid>
                        )}
                    <Dialog
                      open={openReconnectLinkedInAccountModal}
                      // TransitionComponent={Transition}
                      keepMounted
                      onClose={() => { setOpenReconnectLinkedInAccountModal(false); setReconnectLinkedinAccountEmail(null); setReconnectLinkedinAccountPassword('') }}
                      aria-describedby='alert-dialog-slide-description'
                      sx={{ zIndex: '10' }}
                    >
                      <DialogTitle>Reconnect Your LinkedIn Account</DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-slide-description'>
                          Enter your LinkedIn password to reconnect your account.
                        </DialogContentText>
                        <TextField
                          // autoFocus
                          // required
                          margin='dense'
                          fullWidth
                          variant='standard'
                          value={reconnectLinkedinAccountEmail}
                          disabled
                        />
                        <TextField
                          autoFocus
                          required
                          margin='dense'
                          label='LinkedIn Password'
                          type='password'
                          fullWidth
                          variant='standard'
                          value={reconnectLinkedinAccountPassword}
                          onChange={(e) => setReconnectLinkedinAccountPassword(e.target.value)}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => {
                            setOpenReconnectLinkedInAccountModal(false)
                            setReconnectLinkedinAccountEmail(null)
                            setReconnectLinkedinAccountPassword('')
                          }}
                        >
                          Cancel
                        </Button>
                        {accountReconnectLoading
                          ? (
                            <CircularProgress disableShrink size={30} />
                          )
                          : (
                            <Button disabled={reconnectLinkedinAccountPassword === ''} onClick={() => handleReconnectLinkedAccount(accountIdToReconnect)}>Reconnect</Button>
                          )}
                      </DialogActions>
                    </Dialog>
                    {canEdit && (
                      <Grid item key={0} xs={cardName === 'Card View' ? 6 : 12}>
                        <AddNewEmailBlock onClick={() => navigate('/accounts/connect')} />
                      </Grid>
                    )}
                    {Math.ceil(total / limit) > 0 && (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      >
                        <Pagination
                          page={page}
                          setPage={setPage}
                          total={total}
                          length={accounts?.length}
                          limit={limit}
                          handleLimitChange={handleLimitChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              {/* isLoadingMoreAccounts && <CircularProgress sx={{ mt: 5 }} /> */}
            </>
          )}
        </Box>
      </Box>
      <div>
        {accounts &&
          accounts.map(
            (account) =>
              account._id === blockClickedID && (
                <Dialog
                  fullWidth
                  disableEnforceFocus
                  fullScreen={isMobile}
                  maxWidth='md'
                  key={account._id}
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(false)}
                  sx={{
                    backgroundColor: 'rgba(4, 4, 30, 0.5)',
                    '& .MuiDialog-paper': {
                      height: '100%',
                      '&::-webkit-scrollbar': {
                        width: '14px'
                      },

                      // Track
                      '&::-webkit-scrollbar-track': {
                        borderRadius: '60px'
                      },

                      // /* Handle */
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#E4E4E5',
                        borderRadius: '10px',
                        border: '4px solid rgba(0, 0, 0, 0)',
                        backgroundClip: 'padding-box'
                      },

                      // /* Handle on hover */
                      '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#d5d5d5'
                      }
                    }
                  }}
                >
                  <EmailDrawer
                    account={account}
                    onClose={toggleDrawer(false)}
                    tabValue={tabValue}
                  />
                </Dialog>
              )
          )}
      </div>
      <Drawer
        anchor='right'
        open={openBulkSettingDrawer}
        onClose={() => setOpenBulkSettingDrawer(false)}
      >
        <BulkSettingEmailDrawer
          accountIds={checkedAccounts}
          onClose={() => setOpenBulkSettingDrawer(false)}
        />
      </Drawer>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ mt: 0.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // width: "fit-content",
            p: 1,
            width: '260px'
          }}
        >
          {filterButtons.map((item, i) => {
            return (
              <Button
                key={i}
                fullWidth
                sx={{
                  py: 1,
                  px: 1,

                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  color: '#101828',
                  fontSize: '13px',
                  backgroundColor: filter?.name === item.name && '#F2F4F6'
                }}
                onClick={() => {
                  setLinkedInFilterSelected(false)
                  setWhatsAppFilterSelected(false)
                  setFilter(item)
                  setPage(1)
                  handleClose()
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  {item.icon(filter?.name === item.name)}
                  <Typography
                    sx={{
                      color: filter?.name === item.name ? '#0071F6' : '#28287B',
                      fontSize: '13px',
                      fontWeight: 700,
                      linHeight: '16px',
                      letterSpacing: '0px',
                      ml: 2
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Button>
            )
          })}
          <Button
            fullWidth
            sx={{
              py: 1,
              px: 1,

              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: '#101828',
              fontSize: '13px'
              // backgroundColor: filter?.name === item.name && "#F2F4F6",
            }}
            onClick={() => {
              // setFilter(item);
              // setPage(1);
              setLinkedInFilterSelected(true)
              setWhatsAppFilterSelected(false)
              handleClose()
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <LinkedIn sx={{ color: '#28287B' }} />
              <Typography
                sx={{
                  // color: filter?.name === item.name ? "#0071F6" : "#28287B",
                  color: '#28287B',
                  fontSize: '13px',
                  fontWeight: 700,
                  linHeight: '16px',
                  letterSpacing: '0px',
                  ml: 2
                }}
              >
                LinkedIn
              </Typography>
            </Box>
          </Button>
          <Button
            fullWidth
            sx={{
              py: 1,
              px: 1,

              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              color: '#101828',
              fontSize: '13px'
              // backgroundColor: filter?.name === item.name && "#F2F4F6",
            }}
            onClick={() => {
              setWhatsAppFilterSelected(true)
              setLinkedInFilterSelected(false)
              handleClose()
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              <WhatsAppIcon sx={{ color: '#28287B' }} />
              <Typography
                sx={{
                  // color: filter?.name === item.name ? "#0071F6" : "#28287B",
                  color: '#28287B',
                  fontSize: '13px',
                  fontWeight: 700,
                  linHeight: '16px',
                  letterSpacing: '0px',
                  ml: 2
                }}
              >
                WhatsApp
              </Typography>
            </Box>
          </Button>
        </Box>
      </Popover>
      <RemoveAccountDialog
        open={confirmDialogOpen}
        onClick={handleConfirmDialogSubmit}
        onClose={() => setConfirmDialogOpen(false)}
      />
    </>
  )
}
export default Page
