export const Plus = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12 7.19995L12 16.8M16.8 12L7.19995 12'
          stroke='white'
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
