import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import DataNotFoundImage from "../../assets/no_data_found_image.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDkimLookUpMutation } from "src/services/tool-service.js";

const DkimRecordPage = () => {
  const [domainDetails, setDomainDetails] = useState([]);
  const [dkimLookUp] = useDkimLookUpMutation();
  const validationSchema = () => {
    let schema = Yup.object({
      domain: Yup.string()
        .max(255)
        .required("Domain is required")
        .matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, "Please add a valid Domain address"),
      selector: Yup.string()
        .max(255)
        .required("selector is required")
        .matches(/^[a-zA-Z0-9-_]+$/, "Please add a valid Selector"),
    });

    return schema;
  };

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      const result = await dkimLookUp(values).unwrap();

      if(result.message === "Fetched Error"){
        setDomainDetails([{noDomainFound:`${formik?.values?.domain}: DKIM Record not available `}]);
      } else {
        const dkimParts = result.dkimRecord.join(' ').split(' ');
        const dkimDataInArray = [];
      
        dkimParts.forEach((part) => {
          if (part.startsWith('v=')) {
            dkimDataInArray.push({
              tag: 'v',
              tagvalue: part.split('=')[1],
              name: 'version',
              description: 'The v tag is required and represents the protocol version. An example is v=DMARC1',
            });
          } else if (part.startsWith('k=')) {
            dkimDataInArray.push({
              tag: 'k',
              tagvalue: part.split('=')[1],
              name: 'Key Type',
              description: 'The type of the key used by tag (p).',
            });
          } else if (part.startsWith('p=')) {
            dkimDataInArray.push({
              tag: 'p',
              tagvalue: part.split('=')[1],
              name: 'Public Key	',
              description: 'The syntax and semantics of this tag value before being encoded in base64 are defined by the (k) tag.',
            });
          } 
        });
        setDomainDetails([{dkimRecord:result.dkimRecord,dkimRecordArray:dkimDataInArray}]);
      }
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message });
    }
  };

  const formik = useFormik({
    initialValues: {
      domain: "",
      selector: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      
      try {
        await handleDomainDataSubmit(values, helpers);
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
      formik.resetForm();
    },
  });

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        DKIM CHECKER
      </Typography>
      <Typography color="primary.main" sx={{ fontSize: "0.8rem" }}>
        Find DKIM records to help prevent email spoofing and phishing attacks.
      </Typography>

      {/* DKIM Checker Form  */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', flexDirection: { xs: 'column', sm: 'row' }, mt: {xs: '24px', sx:'24px', md:3, lg:3, xl:3} }}>
          <Box  sx={{ display: 'flex', gap: { sm: 1 }, flexDirection: { xs: 'column', sm: 'row' }, mb: { xs: 0, sm: 0 },width:{xs:'100%',sm:'auto'} }}>
            <TextField
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name="domain"
              value={formik?.values?.domain}
              variant="outlined"
              placeholder="Enter a domain name"
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { sm: '21vw', xs: 'auto' }, 
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                padding: { xs: '8px', sm: '11px' },
              }}
            />
            <TextField
              onChange={formik.handleChange}
              error={!!(formik.touched.selector && formik.errors.selector)}
              helperText={formik.touched.selector && formik.errors.selector}
              onBlur={formik.handleBlur}
              name="selector"
              value={formik?.values?.selector}
              variant="outlined"
              placeholder="Enter selector"
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { sm: '21vw', xs: 'auto' }, 
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                padding: { xs: '8px', sm: '11px' },
              }}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: [0,4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '10px',
              marginLeft: { xs: '7px', sm: '4' },
              marginTop: { xs: 2, sm: 0 } ,
              maxHeight: '44px',
              cursor: formik?.values?.domain ? 'pointer' : 'not-allowed',
            }}
            disabled={!formik?.values?.domain}
            type="submit"
          >
            {formik.isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <>
                Look up
              </>
            )}
          </Button>

        </Box>
      </form>

      {domainDetails.length > 0 && domainDetails[0] && (
        <Box
          mt={4}
          sx={{
            mt: { sm: 6 },
            backgroundColor: domainDetails[0].noDomainFound ? '#ffcbd1' : '#d6e9c6',
            p: 3,
            borderRadius: '8px',
            mb: 4,
            my:2,
            color: '#3c763d',
          }}
        >
          <Typography
            sx={{
              backgroundColor: domainDetails[0].noDomainFound ? '#ffcbd1' : '#d6e9c6',
              padding: '8px',
              borderRadius: '8px',
              color: domainDetails[0].noDomainFound ? '#EF4444' : '#3c763d',
              wordBreak: 'break-all', 
              whiteSpace: 'normal',
              overflowWrap: 'break-word', 
            }}
          >
            {domainDetails[0].dkimRecord
              ? domainDetails[0].dkimRecord
              : domainDetails[0].noDomainFound
              ? domainDetails[0].noDomainFound
              : ''}
          </Typography>
        </Box>
      )}


      {/* result Table */}
      <Box
        sx={{
          height: 'auto',
          mt: { sm: 6, xs: 6 },
          border: 8,
          borderColor: '#ECEDF5',
          borderRadius: '8px',
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#F6F6FA', color: '#9295A7', fontSize: '1.2rem', fontWeight: 'medium' }}>
                <TableCell sx={{ px: 4, py: 2 }}>TAG</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>TAG VALUE</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>NAME</TableCell>
                <TableCell sx={{ px: 4, py: 2 }}>DESCRIPTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {domainDetails.length !== 0 && domainDetails[0].dkimRecordArray ? (
                domainDetails[0].dkimRecordArray.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.tag}</TableCell>
                    <TableCell sx={{
                        wordBreak: 'break-all', 
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word', 
                      }}>
                        {row.tagvalue}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.description}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} sx={{ px: 4, py: 2, textAlign: 'center' }}>
                    <Typography sx={{ mt: { sm: '5vh', xs: '2vh', fontSize: '0.8rem' } }}>
                      {domainDetails[0]?.noDomainFound ? domainDetails[0].noDomainFound : 'Simply enter a domain and Selector, click "Look up", and get instant results.'}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: '10vh' }}>
                      <img src={DataNotFoundImage} alt="Doc Icon" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DkimRecordPage;
