import { useEffect, useMemo, useState, useRef, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetCampaignsQuery } from 'src/services/campaign-service'
import { useGetAllLabelsQuery } from 'src/services/campaign-service.js'
const useTotalCounts = () => {
  const dispatch = useDispatch()
  const { data: campaignData } = useGetCampaignsQuery({ unibox: true, option: true })
  const { data: statusLabels } = useGetAllLabelsQuery()
  const accountData = useSelector((state) => state.accounts)

  const [totalCount, setTotalCount] = useState(0)
  const [totalInboxCount, setTotalInboxCount] = useState(0)
  const [totalLabel, setTotalLabel] = useState(0)

  const memoizedCampaignData = useMemo(() => campaignData, [campaignData])
  const memoizedStatusLabels = useMemo(() => statusLabels, [statusLabels])
  const memoizedAccountData = useMemo(() => accountData, [accountData])

  useEffect(() => {
    let inboxCount = 0
    let campaignDatacount = 0

    memoizedCampaignData?.updatedEmail?.forEach((i) => {
      campaignDatacount += i.unreadCount
    })

    memoizedAccountData?.forEach((i) => {
      inboxCount += i.unreadCount
    })

    let count = 0

    memoizedStatusLabels?.labels?.forEach((i) => {
      count += i.unread_count
    })

    setTotalCount(campaignDatacount)
    setTotalInboxCount(inboxCount)
    setTotalLabel(count)
  }, [memoizedCampaignData, memoizedAccountData, memoizedStatusLabels])

  const actualTotalCount = totalCount

  const firstUpdate = useRef(true)
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
    }

    // dispatch(setActualTotalCount(actualTotalCount));
  }, [actualTotalCount, dispatch])

  return { totalCount, totalInboxCount, totalLabel }
}

export default useTotalCounts
