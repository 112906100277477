export const EDEmailsReceived = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' rx='12' fill='#D8E7FE' />
        <path
          d='M18.0485 5.95315L10.9392 13.0625M6.18229 9.49027L17.2529 5.64945C17.9346 5.41293 18.5887 6.06701 18.3522 6.74877L14.5114 17.8194C14.2483 18.5778 13.1833 18.5986 12.8907 17.851L11.1328 13.3585C11.045 13.1342 10.8675 12.9566 10.6431 12.8688L6.15065 11.1109C5.40309 10.8184 5.42388 9.7534 6.18229 9.49027Z'
          stroke='#0071F6'
          stroke-width='1.5'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
