import { useEffect, useState } from 'react'
import { useGetMeQuery } from 'src/services/user-service.js'
import { useNavigate } from 'react-router-dom'

function useUserVerifyCheck ({ lead }) {
  const { data: user, refetch: refetchUser } = useGetMeQuery()
  const [verified, setVerified] = useState('loading')
  const navigate = useNavigate()
  const pollingInterval = 5000

  useEffect(() => {
    if (user) {
      setVerified(user?.emailVerified === true ? 'verified' : user?.emailVerified === false ? 'unverified' : 'loading')
      if (user.emailVerified && !lead) {
        navigate('/accounts')
      }
    }
  }, [user, navigate])

  useEffect(() => {
    const interval = setInterval(() => {
      if (verified !== 'verified') {
        refetchUser()
      } else {
        clearInterval(interval)
      }
    }, pollingInterval)

    return () => clearInterval(interval)
  }, [verified, refetchUser])

  return { verified }
}

export default useUserVerifyCheck
