import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { HelpArticleIcon } from "src/assets/support/HelpArticlesIcon";
import { ContactIcon } from "src/assets/support/ContactIcon";
import { DocumentationIcon } from "src/assets/support/DocumentationIcon";
import HeaderWithPopover from "src/components/HeaderWithPopover";
import { useAuth } from "src/auth/auth";
import { useGetMeAndWorkspaceQuery } from "src/services/user-service";

const InfoCard = ({ icon: Icon, title, description, buttonProps, borderColor, padding = 2, canEdit }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        p: padding,
        pl: 3,
        border: `1px solid ${borderColor}`,
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: padding === 2 ? 2 : "12px",
        boxShadow: "0px 12px 15px 0px #4B71970D",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <Icon />
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            color: "#28287B",
            lineHeight: "26px",
            ml: 1.5,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "13px",
          color: "#8181B0",
          fontWeight: 400,
          lineHeight: "20px",
          mt: 1,
        }}
      >
        {description}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: 3,
        }}
      >
        {buttonProps.map((btnProps, index) => (
          <Button
            key={index}
            {...btnProps}
            sx={{
              borderRadius: "8px",
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "18px",
              py: 1.5,
              ...(btnProps.sx || {}),
            }}
            disabled={!canEdit}
          >
            {btnProps.text}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

const Page = () => {
  const [workspace, setWorkspace] = useState(null);
  const { canEdit } = useAuth();

  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace);
  };

  const {data: data} = useGetMeAndWorkspaceQuery();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          width: "90%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <HeaderWithPopover title="Support Center" onWorkspaceChange={handleWorkspaceChange} selectedTimeline={data?.workspace}/>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              color: "#8181B0",
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "20px",
              letterSpacing: "0px",
              width: "100%",
              mt: 1.5,
            }}
          >
            If you're having trouble, don't worry! You can reach out through our live chat or take
            a look at our documentation to get started!
          </Typography>
        </Box>
        <Grid container columnSpacing={3} rowGap={2} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              icon={HelpArticleIcon}
              title="Help Articles"
              description="Learn how to use Success.ai for automating your campaigns"
              borderColor="#FF7000"
              buttonProps={[
                {
                  onClick: () => window.Intercom("showSpace", "help"),
                  fullWidth: true,
                  variant: "contained",
                  text: "Check Articles",
                },
              ]}
              canEdit={canEdit}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              icon={ContactIcon}
              title="Contact"
              description="Contact us for business inquiries and product support"
              borderColor="#00AA38"
              buttonProps={[
                {
                  href: "mailto:support@success.ai",
                  fullWidth: true,
                  variant: "outlined",
                  text: "Contact Us",
                },
                {
                  onClick: () => window.Intercom("showSpace", "messages"),
                  id: "LiveChatButton",
                  fullWidth: true,
                  variant: "contained",
                  text: "Live Chat",
                  sx: { ml: 1, cursor: "pointer" },
                },
              ]}
              canEdit={canEdit}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Page;
