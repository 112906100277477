import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'src/utils/base-query.js'
import _ from 'lodash'

export const tagApi = createApi({
  reducerPath: 'tagApi',
  baseQuery,
  tagTypes: ['Tag'],
  endpoints: (builder) => ({
    createTag: builder.mutation({
      query: (data) => ({
        method: 'POST',
        url: 'tags/create',
        body: data
      }),
      invalidatesTags: ['Tag']
    }),
    getAllTags: builder.mutation({
      query: (queryParams) => {
        const params = new URLSearchParams(_.pickBy(queryParams))
        return {
          method: 'GET',
          url: 'tags/all-tags',
          params
        }
      },
      providesTags: ['Tag']
    }),
    getTagById: builder.query({
      query: (id) => ({
        method: 'GET',
        url: `tags/${id}`
      }),
      providesTags: (result, error, id) => [{ type: 'Tag', id }]
    })
  })
})

export const {
  useCreateTagMutation,
  useGetAllTagsMutation,
  useGetTagByIdQuery
} = tagApi
