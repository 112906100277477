import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Tab,
  Tabs,
  Link,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import { SalesForceLogo } from "src/assets/integrations/SalesForceLogo";
import { ZapierLogo } from "src/assets/integrations/ZapierLogo";
import { ZohoLogo } from "src/assets/integrations/ZohoLogo";
import WebhookTable from "./IntegrationWebhookTable";
import { ApiLogo } from "src/assets/integrations/apiLogo";
import { useUpdateApiKeyMutation, useGetApiKeyMutation } from "src/services/integration-service";
import { toast } from "react-hot-toast";
import { config } from "src/config.js";
import HeaderWithPopover from "src/components/HeaderWithPopover";
import { useSelector } from "react-redux";
import PremiumFeatureDialog from "src/utils/premiumFeatureDialog";
import UserVerification from "src/components/emailAccounts/UserVerification";
import { useAuth } from "src/auth/auth";
import { useGetMeAndWorkspaceQuery, useGetMeQuery } from "src/services/user-service";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import axios from "axios";
// import { getAuthEmail } from "src/services/auth-service.js";
import { Enterprise } from "src/assets/sidebar/SBEnterprise";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

const integrationData = [
  { logo: <ApiLogo />, title: "Success.ai API" },
  { logo: <ZapierLogo />, title: "Zapier" },
  { logo: <Enterprise sx={{ fontSize: "100px" }} />, title: "Warmup Connector" },
];

const ButtonCommingSoon = () => {
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        py: 1.5,
      }}
    >
      Coming Soon
    </Button>
  );
};

const Api = ({
  updateApiKey,
  getApiKey,
  isLoadingUpdateApiKey,
  isFreeUser,
  advancedApiButton = false,
  setOpenModal,
  canEdit,
  tabValue,
}) => {
  const [open, setOpen] = useState(false);
  const [clipboard, setClipboard] = useState("");
  const workspace = useSelector((state) => state.workspace);

  const handleClickOpen = () => {
    if (isFreeUser) {
      setOpenModal(true);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGenerateKey = async () => {
    try {
      const toastId = toast.loading("Generating new API key", { duration: Infinity });
      const { message } = await updateApiKey({ workspace: workspace }).unwrap();
      toast.success(message, { id: toastId, duration: 2000 });
      setOpen(false);
    } catch (error) {
      toast.error("Failed to generate API key. Please try again later.");
    }
  };

  const getApiKeyForCopy = async () => {
    const { apiKey } = await getApiKey({ workspace: workspace?._id }).unwrap();
    setClipboard(apiKey);
  };

  const copyToClipBoard = async () => {
    navigator.clipboard
      .writeText(clipboard)
      .then(() => {
        toast.success("Copied to Clipboard");
      })
      .catch((err) => {
        toast.error("Unable to copy to clipboard");
      });
  };

  useEffect(() => {
    getApiKeyForCopy();
  }, [isLoadingUpdateApiKey, tabValue]);

  return (
    <>
      <Box
        sx={{ width: "100%", display: "flex", alignItems: "flex-start", flexDirection: "column" }}
      >
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item>
            <Button variant="outlined" disabled={!canEdit || advancedApiButton} onClick={handleClickOpen}>
              Generate new API Key
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!clipboard || isFreeUser || !canEdit || advancedApiButton}
              onClick={copyToClipBoard}
            >
              Copy API Key
            </Button>
          </Grid>
        </Grid>
        <Typography variant="body2" sx={{ display: "block", marginTop: 2 }}>
          <Link
            href={`${config.API_BASE_URL}/docs`}
            color="primary"
            underline="none"
            target="_blank"
          >
            See Documentation
          </Link>
        </Typography>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "22px", fontWeight: "700" }}>Are you sure?</DialogTitle>
        <DialogContent dividers>
          <Typography sx={{ letterSpacing: "1px", fontWeight: 400, fontSize: "15px" }}>
            This will generate a new API key and invalidate the old one.
          </Typography>
        </DialogContent>

        <DialogActions sx={{ margin: 2 }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            disabled={isLoadingUpdateApiKey}
            onClick={handleGenerateKey}
            color="primary"
            variant="contained"
          >
            Generate New API Key
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const IntegrationCard = ({ logo, title, children, zapierSwitch = "" }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0px 12px 15px 0px #4B71970D",
      width: "100%",
      p: 3,
      flexDirection: "column",
      height: "100%",
      backgroundColor: "#fff",
      borderRadius: "12px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #E4E4E5",
        py: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "120px",
          height: "83px",
        }}
      >
        {logo}
      </Box>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        mt: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "28px",
          color: "#28287B",
        }}
      >
        {title}
      </Typography>
      <Box>{zapierSwitch}</Box>
    </Box>

    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        mt: 3,
      }}
    >
      {children}
    </Box>
  </Box>
);

const Page = () => {
  const { data: user } = useGetMeQuery();
  const [tabValue, setTabValue] = useState(0);
  const [isZapierEnabled, setIsZapierEnabled] = useState(false);
  const [zapierKey, setZapierKey] = useState("");
  const [updateApiKey, { isLoading: isLoadingUpdateApiKey }] = useUpdateApiKeyMutation();
  const [getApiKey] = useGetApiKeyMutation();
  const [openModal, setOpenModal] = useState(false);
  const { isFreeUser, advancedApiButton } = UserVerification();
  const { canEdit } = useAuth();

  const [openTooltip, setOpenTooltip] = useState(false);
  const email = user?.email;

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [workspace, setWorkspace] = useState(null);

  const handleWorkspaceChange = (newWorkspace) => {
    setWorkspace(newWorkspace);
  };

  const { data: data } = useGetMeAndWorkspaceQuery();

  const addZapierApiKey = async (checked) => {
    if (email) {
      try {
        const result = await axios.post(`${config.API_BASE_URL}/integrations/store-zapier-key`, {
          email: email,
          checked: checked,
        });

        if (result !== undefined) {
          setZapierKey(result?.data?.zapierApiKey);
          setIsZapierEnabled(checked);
        } else {
          console.log("undefind issue result -> ", result);
        }
      } catch (error) {
        console.error("Error updating isAuto:", error);
      }
    }
  };

  const copy = () => {
    navigator.clipboard.writeText(zapierKey);
    toast.success("Copied to Clipboard");
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const getUserIntegration = async (email) => {
    try {
      const result = await axios.get(
        `${config.API_BASE_URL}/integrations/get-user-integrations/${email}`
      );
      if (result !== undefined) {
        setIsZapierEnabled(result?.data?.[0]?.zapierVerifiedFlag === "Y");
        setZapierKey(result?.data?.[0]?.zapierApiKey);
      } else {
        console.log("undefind issue result -> ", result);
      }
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  };

  getUserIntegration(email);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <HeaderWithPopover
            title="Integrations"
            onWorkspaceChange={handleWorkspaceChange}
            selectedTimeline={data?.workspace}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "#8181B0",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "0px",
                width: "100%",
                mt: 1.5,
              }}
            >
              Experience seamless integration and elevate your efficiency with Success.ai. Choose
              the method that suits you best and supercharge your productivity today!
            </Typography>
          </Box>
          <Box sx={{ mt: 3, width: "100%" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{ borderBottom: "1px solid #E4E4E5" }}
            >
              <Tab label="API" />
              <Tab label="Webhook" />
            </Tabs>
            <Box sx={{ mt: 3 }}>
              {tabValue === 0 && (
                <Grid container columnSpacing={3} rowGap={2}>
                  {integrationData.map((integration, index) =>
                    integration.title === "Success.ai API" ? (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <IntegrationCard logo={integration.logo} title={integration.title}>
                          <Api
                            updateApiKey={updateApiKey}
                            getApiKey={getApiKey}
                            isLoadingUpdateApiKey={isLoadingUpdateApiKey}
                            isFreeUser={isFreeUser}
                            advancedApiButton={advancedApiButton}
                            setOpenModal={setOpenModal}
                            canEdit={canEdit}
                            tabValue={tabValue}
                          />
                        </IntegrationCard>
                      </Grid>
                    ) : integration.title === "Zapier" ? (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <IntegrationCard
                          logo={integration.logo}
                          title={integration.title}
                          zapierSwitch={
                            <Switch
                              checked={isZapierEnabled}
                              onChange={async ({ target: { checked } }) => {
                                await addZapierApiKey(checked);
                              }}
                              name="enable_disable_zapier"
                            />
                          }
                        >
                          {isZapierEnabled && (
                            <Grid
                              container
                              spacing={2}
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <Typography variant="subtitle2" color="textSecondary">
                                  Zapier Api Key
                                </Typography>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  sx={{
                                    boxShadow: "0px 0px 10px rgba(5, 12, 70, 0.4)",
                                    borderRadius: "10px",
                                    backgroundColor: "rgb(30 37 101)",
                                    color: "rgb(229 229 229)",
                                    marginTop: "0.5rem",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      padding: "8px 16px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      fontSize: { xs: "12px", sm: "14px" },
                                      maxWidth: { xs: "70%", sm: "calc(100% - 40px)" },
                                    }}
                                  >
                                    {zapierKey?.substring(0, isXs ? 20 : 33) + "..."}
                                  </Typography>
                                  <Tooltip
                                    open={openTooltip}
                                    onClose={handleCloseTooltip}
                                    onOpen={handleOpenTooltip}
                                    title="Copy zapier api key."
                                  >
                                    <IconButton
                                      sx={{
                                        backgroundColor: "rgba(76, 83, 148, 0.66)",
                                        margin: { xs: "4px", sm: "8px" },
                                      }}
                                      onClick={() => copy()}
                                    >
                                      <ContentCopyIcon style={{ color: "#fff" }} />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </IntegrationCard>
                      </Grid>
                    ) : integration.title === "Warmup Connector" ? (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <IntegrationCard logo={integration.logo} title="Warmup Connector">
                          <Button
                            fullWidth
                            variant="contained"
                            title={
                              data?.workspace?.enterprise
                                ? "Warmup Connector"
                                : "Warmup Connector"
                            }
                            onClick={() => data?.workspace?.enterprise && navigate("/enterprise")}
                            disabled={!data?.workspace?.enterprise || !canEdit}
                            sx={{
                              borderRadius: "8px",
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "18px",
                              py: 2.5,
                              backgroundColor: data?.workspace?.enterprise
                                ? "primary.main"
                                : "gray",
                              color: data?.workspace?.enterprise ? "white" : "black",
                            }}
                          >
                            {data?.workspace?.enterprise
                              ? "Warmup Connector"
                              : "Warmup Connector"}
                          </Button>
                        </IntegrationCard>
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <IntegrationCard logo={integration.logo} title={integration.title}>
                          <ButtonCommingSoon />
                        </IntegrationCard>
                      </Grid>
                    )
                  )}
                </Grid>
              )}
              {tabValue === 1 && <WebhookTable canEdit={canEdit} />}
            </Box>
          </Box>
        </Box>
      </Box>
      <PremiumFeatureDialog open={openModal} onClose={handleCloseModal} />
    </>
  );
};

export default Page;
