import React, { useState } from 'react'
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  Paper,
  IconButton
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const EmailVerificationCalculator = () => {
  const [results, setResults] = useState('')
  const [showDetails, setShowDetails] = useState(false)

  const handleToggleDetails = () => {
    setShowDetails((prevShowDetails) => !prevShowDetails)
  }

  const validationSchema = () => {
    const schema = Yup.object({
      emailSignUpsMonthly: Yup.number()
        .typeError('Please enter a valid number')
        .required('Email SignUps Monthly is required')
        .min(1, 'Please enter a number greater than or equal to 1'),

      liftTimeValue: Yup.number()
        .typeError('Please enter a valid number')
        .required('Lift Time Value is required')
        .min(1, 'Please enter a number greater than or equal to 1')
    })

    return schema
  }

  const handleDataSubmit = async (values, helpers) => {
    try {
      const monthlyRevenue = values.emailSignUpsMonthly * 0.125 * 0.5 * values.liftTimeValue
      const n = values.emailSignUpsMonthly * 0.008
      const monthlyROI = ((monthlyRevenue - n) / n) * 100

      const result = {
        monthlyRevenue: monthlyRevenue.toFixed(1),
        monthlyROI: monthlyROI.toFixed(1)
      }
      setResults(result)
    } catch (err) {
      console.log('err => ', err)
      helpers.setErrors({ submit: err.data.error.message })
    }
  }

  const reSetData = () => {
    formik.resetForm()
    setResults('')
  }

  const formik = useFormik({
    initialValues: {
      emailSignUpsMonthly: '',
      liftTimeValue: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDataSubmit(values, helpers, true)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
    }
  })

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Email Verification Calculator
      </Typography>

      <Typography color='primary.main' sx={{ fontSize: '0.8rem' }}>
        Unlock Your Potential Earnings: Calculate Your Monthly Revenue and ROI in Seconds!
      </Typography>

      {/* Email Verification Calculator Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            flexDirection: { xs: 'column', sm: 'row' },
            mt: { xs: '24px', sx: '24px', md: 3, lg: 3, xl: 3 }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: { sm: 1 },
              flexDirection: { xs: 'column', sm: 'row' },
              mb: { xs: 0, sm: 0 },
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            <TextField
              variant='outlined'
              onChange={formik.handleChange}
              error={!!(formik.touched.emailSignUpsMonthly && formik.errors.emailSignUpsMonthly)}
              helperText={formik.touched.emailSignUpsMonthly && formik.errors.emailSignUpsMonthly}
              onBlur={formik.handleBlur}
              name='emailSignUpsMonthly'
              placeholder='No. of Emails Sign ups Montly'
              value={formik?.values?.emailSignUpsMonthly}
              type='number'
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { md: '21vw', sm: '35vw', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight: { sm: '0px' }
              }}
            />

            <TextField
              variant='outlined'
              onChange={formik.handleChange}
              error={!!(formik.touched.liftTimeValue && formik.errors.liftTimeValue)}
              helperText={formik.touched.liftTimeValue && formik.errors.liftTimeValue}
              onBlur={formik.handleBlur}
              name='liftTimeValue'
              placeholder='Lifetime Value of Each Email Address'
              type='number'
              value={formik?.values?.liftTimeValue}
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { md: '21vw', sm: '35vw', xs: 'auto' },
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight: { sm: '0px' }
              }}
            />
          </Box>

          <Button
            variant='contained'
            color='primary'
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '10px',
              marginLeft: { xs: '7px', sm: '4' },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: '44px',
              cursor:
                formik?.values?.emailSignUpsMonthly && formik?.values?.liftTimeValue
                  ? 'pointer'
                  : 'not-allowed'
            }}
            disabled={!(formik?.values?.emailSignUpsMonthly && formik?.values?.liftTimeValue)}
            type='submit'
          >
            {formik.isSubmitting ? <CircularProgress size={20} color='inherit' /> : <>Calculate</>}
          </Button>
        </Box>
      </form>

      {results !== '' && (
        <Box
          sx={{
            py: { sm: '40px', xs: '20px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            border: '6px solid #ECEDF5',
            padding: { sm: '30px', xs: '15px' },
            marginTop: '32px',
            borderRadius: '8px',
            width: '100%'
          }}
        >
          <Typography variant='h5' component='h3' sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
            Result
          </Typography>
          <Typography>
            For {formik?.values?.emailSignUpsMonthly} email sign up monthly and lifetime value of
            each email address is {formik?.values?.liftTimeValue}, you will have the result:
          </Typography>
          <Paper
            variant='outlined'
            sx={{
              backgroundColor: '#F9F9FC',
              borderRadius: '8px',
              width: { md: '65%', xs: '100%' },
              paddingY: '32px',
              marginTop: '32px'
            }}
          >
            <Box sx={{ borderBottom: '1px solid', padding: { sm: '32px', xs: '16px' } }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px'
                }}
              >
                <Typography>Potential Revenue Lost without Success AI</Typography>
                <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#D54444' }}>
                  ${results.monthlyRevenue}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px'
                }}
              >
                <Typography>Potential Monthly ROI</Typography>
                <Typography variant='h6' sx={{ fontWeight: 'bold', color: '#34B24E' }}>
                  {results.monthlyROI}%
                </Typography>
              </Box>
            </Box>
            <Box sx={{ padding: { sm: '32px', xs: '16px' }, paddingTop: '16px' }}>
              <Typography
                onClick={handleToggleDetails}
                sx={{
                  color: '#9295A7',
                  cursor: 'pointer',
                  '&:hover': { textDecoration: 'underline' }
                }}
              >
                How is this calculated?
              </Typography>
              {showDetails && (
                <Box sx={{ marginTop: '16px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2
                    }}
                  >
                    <Typography>Invalid Address = Number of email signups x 12.5%</Typography>
                    <IconButton onClick={handleToggleDetails} size='small' />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2
                    }}
                  >
                    <Typography>Potentially Valid Addresses = Invalid Addresses x 50%</Typography>
                    <IconButton onClick={handleToggleDetails} size='small' />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2
                    }}
                  >
                    <Typography>
                      Missed Lifetime Value = Potentially Valid Addresses x $10
                    </Typography>
                    <IconButton onClick={handleToggleDetails} size='small' />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 2
                    }}
                  >
                    <Typography>Total Cost = Number of email sign ups x 0.008</Typography>
                    <IconButton onClick={handleToggleDetails} size='small' />
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
          <Button
            variant='contained'
            sx={{
              backgroundColor: '#6E58F1',
              '&:hover': {
                backgroundColor: '#7c68f1'
              },
              color: 'white',
              px: 4,
              py: 1.2,
              mt: 4,
              width: { md: '20%', xs: '100%' },
              borderRadius: '8px',
              minHeight: '35px'
            }}
            onClick={reSetData}
          >
            Start Over
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default EmailVerificationCalculator
