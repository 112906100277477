export const Opened = () => {
  return (
    <>
      <svg
        width='36'
        height='36'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width='24' height='24' rx='12' fill='#FFECDD' />
        <path
          d='M7 11H6V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V11H17M7 11L12 14L17 11M7 11V8C7 6.89543 7.89543 6 9 6H15C16.1046 6 17 6.89543 17 8V11'
          stroke='#FF7000'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
