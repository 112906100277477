const { Box, Typography } = require('@mui/material')
const AddNewSequenceStepBlock = ({ onClick, label, isLinkedInConnected, Icon }) => {
  return (
    <Box
      sx={{
        width: '300px',
        height: '75px',
        p: 2,
        py: 6,
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0px 12px 15px 0px #4B71970D',
        backgroundColor: isLinkedInConnected ? 'lime' : 'white',
        '&:hover': {
          boxShadow: '0px 2px 14px -1px rgba(0, 0, 0, 0.2)'
        },
        transition: 'all 0.2s ease-in-out',
        flexDirection: 'column',
        border: '1px solid #E0E0E5',
        borderSpacing: '15px'
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center'
          // flexDirection: "column",
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          {Icon && <Icon sx={{ background: '#E7F0FF', padding: '5px', height: '40px', width: '40px', borderRadius: '100%', color: '#0071F6' }} />}

        </Box>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '14px',
            color: '#000000',
            ml: 2
          }}
        >
          {isLinkedInConnected ? 'Account Connected' : label}
        </Typography>
        {/* <AddEmailHero />
        <Typography sx={{ fontWeight: 600, color: "rgba(0,0,0,0.5)", mt: 1 }}>
          Add New Email Account
        </Typography> */}
      </Box>
    </Box>
  )
}

export default AddNewSequenceStepBlock
