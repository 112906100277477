import React, { useState } from 'react'
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  IconButton
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import axios from 'axios'
import { config } from 'src/config.js'

const EmailsubjectLineAnalyzer = () => {
  const [response, setResponse] = useState([])
  const validationSchema = () => {
    const schema = Yup.object({
      subjectName: Yup.string().max(255).required('Subject Line is required')
    })

    return schema
  }

  const handleDataSubmit = async (values, helpers) => {
    const apiUrl = `${config.API_BASE_URL}/tools/analyze-subject-line`
    try {
      const responseData = await axios.post(
        apiUrl,
        {
          prompt: values
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = responseData.data.subject.split('\n')
      setResponse(data)
    } catch (error) {
      console.error('Error :', error)
      setResponse('Something Went Wroung Please Try Again !')
    }
  }

  const formik = useFormik({
    initialValues: {
      subjectName: ''
    },
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDataSubmit(values, helpers)
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message })
      }
      formik.resetForm()
    }
  })

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Box sx={{ padding: { sm: '24px 7%', xs: '16px' }, fontFamily: 'Inter' }}>
      <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'primary.main', py: 2 }}>
        Email subject line analyzer
      </Typography>
      <Typography color='primary'>
        Boost your email open rates: analyze and improve your subject lines before sending your next
        campaign.
      </Typography>

      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ my: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { sm: 'row', xs: 'column' },
              gap: { sm: 4, xs: 2 },
              my: 4
            }}
          >
            <TextField
              label='Enter your subject line'
              variant='outlined'
              sx={{ width: { md: '21vw', sm: '50vw', xs: '100%' } }}
              onChange={formik.handleChange}
              error={!!(formik.touched.subjectName && formik.errors.subjectName)}
              helperText={formik.touched.subjectName && formik.errors.subjectName}
              onBlur={formik.handleBlur}
              name='subjectName'
              value={formik?.values?.subjectName}
            />
          </Box>

          <Button
            variant='contained'
            sx={{
              backgroundColor: '#6E58F1',
              '&:hover': { backgroundColor: '#7c68f1' },
              width: { sm: '15vw', xs: '100%' },
              minHeight: 56
            }}
            disabled={!formik?.values?.subjectName}
            type='submit'
          >
            {formik.isSubmitting ? <CircularProgress size={20} color='inherit' /> : <>Test Now</>}
          </Button>
        </Box>
      </form>

      {response.map((raw, index) => (
        <Box mt={4} key={index}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{ width: { sm: '84%', xs: '100%' } }}
          >
            <Typography variant='h6' fontWeight='bold' color='primary' my={2}>
              Analyzed Subject Line :
            </Typography>
            <Tooltip title='Copy'>
              <IconButton
                onClick={() => handleCopy(raw)}
                sx={{ color: '#6E58F1', '&:hover': { color: '#7c68f1' } }}
              >
                <ContentCopyIcon fontSize='small' />
                <Typography variant='body2' ml={1} sx={{ cursor: 'pointer' }}>
                  Copy
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            component='pre'
            sx={{
              backgroundColor: 'grey.50',
              border: '1px solid',
              borderColor: 'grey.300',
              borderRadius: 1,
              p: 2,
              mt: 2,
              width: { sm: '84%', xs: '100%' },
              color: 'grey.800',
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word'
            }}
          >
            {raw}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default EmailsubjectLineAnalyzer
