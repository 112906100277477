import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAuth } from "src/auth/auth";
import Divider from '@mui/material/Divider';

const PlanCard = ({
  title,
  price,
  billingMonthly,
  handleUpdatePlanClick,
  isCurrentPlan,
  planId,
  isPlanUpdating,
  discountPrice,
  testlines
}) => {
  const { canEdit, canDelete } = useAuth();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleConfirmPurchase = () => {
    handleCloseModal();
    handleUpdatePlanClick(planId);
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", height: "100%", position: "relative" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "60%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >   
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "26px",
                color: "#28287B",
                ml: 1.5,
              }}
            >
              {title}
            </Typography>
          </Box>

          <Typography
            sx={{
              fontSize: "38px",
              fontWeight: 700,
              lineHeight: "20px",
              color: "#0071F6",
              px: 2,
              py: 1.5,
              my: 1.5,
            }}
          >
            ${price}  
            <Typography
              component="span"
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#28287B",
              }}
            >
              /Month
            </Typography>
          </Typography>
          {!billingMonthly && (
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 700,
                color: "#0071F6",
                textDecoration: "line-through",
              }}
            >
              Normally ${discountPrice}/Month
            </Typography>
          )}

          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 700,
              color: "#28287B",
            }}
          >
            {!billingMonthly ? "(Billed Annually)" : "(Billed Monthly)"}
          </Typography>

          <Box>{testlines}</Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: "auto",
          width: "40%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant={isCurrentPlan ? "outlined" : "contained"}
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18px",
            borderRadius: "8px",
            px: 2,
            py: 1.5,
          }}
          disabled={isPlanUpdating || !canEdit || !canDelete}
          onClick={handleOpenModal}
        >
          {isCurrentPlan ? "Current Plan" : "Get Offer Now"}
        </Button>
      </Box>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: 700,
          color: "#28287B",
        }}
      >
        * Coming Soon
      </Typography>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Plan Purchase"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to proceed with purchasing the {title} plan for ${price}/ month?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="contained" color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmPurchase} variant="outlined"  color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PlanCard;
