export const CalendarIcon = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.66667 5.94284H13M4.87302 2V3.02869M11.6667 2V3.02857M11.6667 3.02857H5C3.89543 3.02857 3 3.94958 3 5.0857V11.9429C3 13.079 3.89543 14 5 14H11.6667C12.7712 14 13.6667 13.079 13.6667 11.9429L13.6667 5.0857C13.6667 3.94958 12.7712 3.02857 11.6667 3.02857ZM7.66667 9.02857L8.66667 8V11.4286M8.66667 11.4286H7.66667M8.66667 11.4286H9.66667'
          stroke='#28287B'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
