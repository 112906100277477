import React from 'react'
import { Box, Typography, Button, Grid } from '@mui/material'
import CustomAccordion from './customAccordion'
import MoreAccordion from './moreAccordion'

const InboxColumn = ({
  value,
  handleChange,
  isCampaignsLoading,
  searchAccount,
  setSearchAccount,
  searchCampaign,
  setSearchCampaign,
  campaignData,
  totalCampaigns,
  currentCampaign,
  setCurrentCampaign,
  handleClickCampaign,
  showAll,
  setShowAll,
  handleSearchAccountChange,
  handleSearchCampaignChange,
  handleSearchStatusChange,
  accounts,
  totalAccounts,
  getAllAccounts,
  getAllCampaigns,
  isAccountsLoading,
  currentAccount,
  setCurrentAccount,
  isLabelsLoading,
  statusLabels,
  currentStatus,
  setCurrentStatus,
  handleClickStatus,
  handleClickAccount,
  setOpenAllInboxes,
  setOpenAllCampaigns,
  openAllCampaigns,
  openAllInboxes,
  theme,
  setInboxTabsOpen,
  handleClickOpenAddStatusDialog,
  setMoreData,
  unread,
  setUnread,
  sent,
  setSent
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: { sm: 'calc(100vh - 110px)' },
        backgroundColor: 'white',
        // p: 2,
        // pt: 0,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        boxShadow: '0px 12px 15px 0px #4B71970D',
        borderRadius: '12px',
        flexDirection: 'column',
        overflowY: 'hidden',

        // width
        '&::-webkit-scrollbar': {
          width: '14px'
        },

        // Track
        '&::-webkit-scrollbar-track': {
          borderRadius: '60px'
        },

        // /* Handle */
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#E4E4E5',
          borderRadius: '10px',
          border: '4px solid rgba(0, 0, 0, 0)',
          backgroundClip: 'padding-box'
        },

        // /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#d5d5d5'
        },
        position: 'relative'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            pt: 1,
            px: 2,
            alignItems: 'center',
            justifyContent: 'space-between'
            // borderBottom: "2px solid rgba(0,0,0,0.1)",
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              color: '#28287B'
            }}
          >
            Mail
          </Typography>
          <Button
            variant='outlined'
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              py: 0.5,
              border: '1px solid #E4E4E5',
              borderRadius: '8px',
              height: '100%',
              cursor: 'pointer'
            }}
            onClick={() => {
              setShowAll(true)
              setCurrentCampaign(null)
              setCurrentAccount(null)
              setCurrentStatus(null)
              setInboxTabsOpen(false)
              setUnread(false)
              setSent(false)
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 700,
                lineHeight: '20px',
                color: '#28287B'
              }}
            >
              View All
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            mt: 2,
            height: '700px',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '14px' },
            '&::-webkit-scrollbar-track': { borderRadius: '60px' },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#E4E4E5',
              borderRadius: '10px',
              border: '4px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box'
            },
            '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#d5d5d5' }
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                px: 1
                // border: "1px solid rgba(228, 228, 229, 1)",
                // borderRadius: "8px",
              }}
            >
              <Box sx={{ width: '100%' }}>
                <CustomAccordion
                  title='Status'
                  searchPlaceholder='Search by status'
                  handleSearchChange={handleSearchStatusChange}
                  items={statusLabels}
                  handleClickItem={handleClickStatus}
                  currentItem={currentStatus}
                  setInboxTabsOpen={setInboxTabsOpen}
                  setShowAll={setShowAll}
                  showCreateButton
                  createButtonText='Create New Label'
                  onCreateButtonClick={handleClickOpenAddStatusDialog}
                />
                <CustomAccordion
                  title='Campaigns'
                  searchPlaceholder='Search by campaign'
                  searchItem={searchCampaign}
                  setSearchItem={setSearchCampaign}
                  handleSearchChange={handleSearchCampaignChange}
                  items={campaignData}
                  handleClickItem={handleClickCampaign}
                  currentItem={currentCampaign}
                  setInboxTabsOpen={setInboxTabsOpen}
                  setShowAll={setShowAll}
                  totalItems={totalCampaigns}
                  isLoading={isCampaignsLoading}
                  loadMoreItems={() => {
                    getAllCampaigns()
                    setMoreData(true)
                  }}
                />
                <CustomAccordion
                  title='Inboxes'
                  searchPlaceholder='Search By Email'
                  searchItem={searchAccount}
                  setSearchItem={setSearchAccount}
                  handleSearchChange={handleSearchAccountChange}
                  items={accounts}
                  handleClickItem={handleClickAccount}
                  currentItem={currentAccount}
                  setInboxTabsOpen={setInboxTabsOpen}
                  setShowAll={setShowAll}
                  totalItems={totalAccounts}
                  isLoading={isAccountsLoading}
                  loadMoreItems={() => { getAllAccounts(); setMoreData(true) }}
                />
                <MoreAccordion
                  setShowAll={setShowAll}
                  setCurrentCampaign={setCurrentCampaign}
                  setCurrentAccount={setCurrentAccount}
                  setCurrentStatus={setCurrentStatus}
                  setInboxTabsOpen={setInboxTabsOpen}
                  unread={unread}
                  setUnread={setUnread}
                  sent={sent}
                  setSent={setSent}
                />
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default InboxColumn
