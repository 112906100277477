export const CDLeads = ({ color }) => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M2.39999 18.2168C3.49864 16.3843 5.90651 15.1301 9.66311 15.1301C13.4197 15.1301 15.8276 16.3843 16.9262 18.2168M17.6212 13.7406C19.6106 14.7352 20.6053 15.7299 21.6 17.7193M15.5522 6.1376C16.4883 6.64115 17.1246 7.62991 17.1246 8.7673C17.1246 9.87183 16.5245 10.8362 15.6326 11.3522M12.6472 8.76727C12.6472 10.4153 11.3112 11.7513 9.66311 11.7513C8.01506 11.7513 6.67904 10.4153 6.67904 8.76727C6.67904 7.11922 8.01506 5.7832 9.66311 5.7832C11.3112 5.7832 12.6472 7.11922 12.6472 8.76727Z'
          stroke={color}
          stroke-width='2'
          stroke-linecap='round'
        />
      </svg>
    </>
  )
}
