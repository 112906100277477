export const LFLocation = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.9994 21.6C11.9994 21.6 19.5124 14.9218 19.5124 9.91307C19.5124 5.76373 16.1487 2.40002 11.9994 2.40002C7.85003 2.40002 4.48633 5.76373 4.48633 9.91307C4.48633 14.9218 11.9994 21.6 11.9994 21.6Z'
          stroke='#28287B'
          stroke-width='2'
        />
        <path
          d='M14.3997 9.60018C14.3997 10.9257 13.3252 12.0002 11.9997 12.0002C10.6742 12.0002 9.59968 10.9257 9.59968 9.60018C9.59968 8.27469 10.6742 7.20018 11.9997 7.20018C13.3252 7.20018 14.3997 8.27469 14.3997 9.60018Z'
          stroke='#28287B'
          stroke-width='2'
        />
      </svg>
    </>
  )
}
