// src/components/PremiumFeatureDialog.js
import React from 'react'
import { Box, Typography, Button, Dialog, DialogContent } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'

const PremiumFeatureDialog = ({ open, onClose }) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='premium-feature-modal-title'
      aria-describedby='premium-feature-modal-description'
      maxWidth='sm'
      fullWidth
    >
      <DialogContent sx={{ textAlign: 'center', p: 4 }}>
        <Typography variant='h5' sx={{ fontWeight: 'bold', mb: 2, fontSize: '28px' }}>
          Unlock Premium Features
        </Typography>
        <Typography variant='body1' sx={{ fontWeight: 'bold', mb: 1 }}>
          You’ve discovered a premium feature!
        </Typography>
        <Typography variant='body1' sx={{ mb: 3 }}>
          Upgrade to one of the paid plans to unlock this feature.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (location.pathname !== '/settings/billing') {
                navigate('/settings/billing')
              } else {
                onClose()
              }
            }}
            sx={{
              backgroundColor: '#007bff',
              padding: '10px 20px',
              marginRight: '10px',
              '&:hover': {
                backgroundColor: '#0056b3'
              }
            }}
          >
            Upgrade Plan
          </Button>
          <Button
            onClick={onClose}
            sx={{
              color: '#6B7280',
              backgroundColor: '#f5f5f5',
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: '#e0e0e0'
              }
            }}
          >
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PremiumFeatureDialog
