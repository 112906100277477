import React, { useState, useEffect } from 'react'
import {
  Box,
  Typography,
  CircularProgress,
  Checkbox,
  IconButton,
  Tooltip,
  useTheme
} from '@mui/material'
import moment from 'moment-timezone'
import InboxPagination from 'src/components/InboxPagination'
import RefreshButton from 'src/components/RefreshButton'
import {
  Email,
  Today,
  Done,
  Close,
  Circle,
  Home,
  Cached,
  Favorite,
  EmojiEvents,
  RadioButtonUnchecked,
  Delete,
  MarkEmailRead,
  MarkAsUnread,
  PersonRemove,
  TurnedIn
} from '@mui/icons-material'
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe'
import {
  setActualTotalCount, useDeleteBulkEmailMutation,
  useOpenedBulkEmailMutation,
  useRemoveBulkEmailMutation,
  useUpdateBulkLabelMutation
} from 'src/services/unibox-service'
import { renderLabelWithIcon } from './statusLabelSelect'
import CustomPopover from './customPopover'
import { RemoveEmailDialog } from './removeEmailDialog'
import { RemoveLeadDialog } from './removeLeadDialog'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

const normalizeEmail = (email, sent) => {
  return {
    id: !sent ? email?._id : email?.campaign_email_id?._id,
    email: email?.leads?.email || 'Unsubscribe User',
    subject: email?.subject || email?.campaign_email_id?.subject,
    body: email?.body || email?.campaign_email_id?.body,
    createdAt: email?.createdAt,
    label: email?.label || email?.campaign_email_id?.label,
    portal_email_opened: email?.portal_email_opened,
    from: email?.from || email?.fromAccount?._id,
    replies: email?.mailReplies
  }
}

const getIconByLabelName = (label) => {
  switch (label) {
    case 'Lead':
      return (
        <>
          <Email sx={{ fontSize: 20, color: '#ffcf3c', ml: 1 }} />
        </>
      )
    case 'Interested':
      return (
        <>
          <Favorite sx={{ fontSize: 20, color: '#689f38', ml: 1 }} />
        </>
      )
    case 'Not interested':
      return (
        <>
          <Close sx={{ fontSize: 20, color: '#ee3131' }} />
        </>
      )
    case 'Meeting booked':
      return (
        <>
          <Today sx={{ fontSize: 20, color: '#97c7f3' }} />
        </>
      )
    case 'Meeting completed':
      return (
        <>
          <Done sx={{ fontSize: 20, color: '#689f38' }} />
        </>
      )
    case 'Out of office':
      return (
        <>
          <Home sx={{ fontSize: 20, color: '#852f02' }} />
        </>
      )
    case 'Wrong person':
      return (
        <>
          <Circle sx={{ fontSize: 20, color: '#272727' }} />
        </>
      )
    case 'Won':
      return (
        <>
          <EmojiEvents sx={{ fontSize: 20, color: '#c98d09' }} />
        </>
      )
    case 'Lost':
      return (
        <>
          {label.name} <RadioButtonUnchecked sx={{ fontSize: 20, color: '#ee3131' }} />
        </>
      )
    case 'Unsubscribed':
      return (
        <>
          {label.name} <UnsubscribeIcon sx={{ fontSize: 20, color: '#ee3131' }} />
        </>
      )
    default:
      return (
        <>
          {label.name} <Cached sx={{ fontSize: 20, color: '#ffcf3c' }} />
        </>
      )
  }
}

const EmailList = React.memo(({
  campaignEmails,
  isCampaignsEmailLoading,
  inboxSelectedID,
  handleEmailClick,
  page,
  setPage,
  totalCampaignsEmails,
  limit,
  handleLimitChange,
  parseBodyContent,
  unread,
  sent,
  handleRefresh,
  EmailUpdatedData,
  handleSearchChange,
  items = [],
  showCreateButton,
  createButtonText,
  searchPlaceholder,
  onCreateButtonClick,
  setCurrentEmail,
  campaignData,
  setCampaignData,
  setCampaignDataCount,
  accounts,
  setAccounts,
  removeLeadOptions,
  setRemoveLeadOptions,
  canEdit
}) => {
  const theme = useTheme()
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [selectedEmails, setSelectedEmails] = useState([])
  const [openedBulkEmail] = useOpenedBulkEmailMutation()
  const [deleteBulkEmail] = useDeleteBulkEmailMutation()
  const [updateBulkLabel] = useUpdateBulkLabelMutation()
  const [removeBulkEmail] = useRemoveBulkEmailMutation()
  const [popoverAnchor, setPopoverAnchor] = useState(null)
  const [openRemoveEmailDialog, setOpenRemoveEmailDialog] = useState(false)
  const [openRemoveLeadDialog, setOpenRemoveLeadDialog] = useState(false)
  const dispatch = useDispatch()
  const workspace = useSelector((state) => state.workspace)
  const [timezone, setTimezone] = useState('')

  useEffect(() => {
    setTimezone(workspace?.timezone?.split(' ')[0])
  }, [workspace])

  useEffect(() => {
    if (!isCampaignsEmailLoading) {
      setIsInitialLoading(false);
    } else if (campaignEmails.length === 0) {
      setIsInitialLoading(true);
    }
  }, [isCampaignsEmailLoading, campaignEmails]);

  const handleRefreshWrapper = async () => {
    setIsRefreshing(true)
    await handleRefresh()
    setIsRefreshing(false)
  }

  const shouldShowLoading = isInitialLoading || isRefreshing;

  const formatDate = (date) => {
    return moment(date).tz(timezone).format('h:mm A, MM/DD/YYYY')
  }

  const iconStyle = {
    fontSize: 30,
    border: '1px solid',
    borderRadius: '4px',
    padding: '4px',
    marginRight: '8px'
  }

  const toggleEmailSelection = (emailId) => {
    if (selectedEmails.includes(emailId)) {
      setSelectedEmails(selectedEmails.filter(id => id !== emailId))
    } else {
      setSelectedEmails([...selectedEmails, emailId])
    }
  }

  const handleSelectAll = () => {
    if (selectedEmails.length === campaignEmails.length) {
      setSelectedEmails([])
    } else {
      const allEmailIds = campaignEmails.map(email => email._id)
      setSelectedEmails(allEmailIds)
    }
  }

  const isSelected = (emailId) => selectedEmails.includes(emailId)

  const extractTextBeforeSeparator = (bodyText) => {
    const cleanedText = bodyText.replace(/P {margin-top:0;margin-bottom:0;}/g, '').trim()

    const onIndex = cleanedText.indexOf('On')
    const fromIndex = cleanedText.indexOf('From:')

    const separatorIndex = Math.min(
      onIndex !== -1 ? onIndex : cleanedText.length,
      fromIndex !== -1 ? fromIndex : cleanedText.length
    )

    return separatorIndex !== -1 ? cleanedText.substring(0, separatorIndex).trim() : cleanedText
  }

  const handleAddSelectedToBlocklist = () => {
    setOpenRemoveLeadDialog(true)
  }

  const handleRemoveLead = async () => {
    const data = {
      allDomain: removeLeadOptions.allDomain,
      allCampaign: removeLeadOptions.allCampaign,
      blocklist: removeLeadOptions.blocklist
    }
    const { accountsWithUnreadCount, campaignsWithUnreadCount, campaignDatacount, message } = await removeBulkEmail({
      body: {
        ids: selectedEmails,
        data
      }
    }).unwrap()
    dispatch(setActualTotalCount(campaignDatacount))
    updateCampaignData(campaignsWithUnreadCount)
    updateAccountData(accountsWithUnreadCount)
    resetEmailSelections()
    setOpenRemoveLeadDialog(false)
    toast.success(message)
  }

  const handleChangeInterestOfSelected = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setPopoverAnchor(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setPopoverAnchor(null)
  }

  const handleBulkDelete = async () => {
    const { accountsWithUnreadCount, campaignsWithUnreadCount, campaignDatacount, message } = await deleteBulkEmail({ ids: selectedEmails }).unwrap()
    dispatch(setActualTotalCount(campaignDatacount))
    updateCampaignData(campaignsWithUnreadCount)
    updateAccountData(accountsWithUnreadCount)
    setOpenRemoveEmailDialog(false)
    toast.success(message)
    resetEmailSelections()
  }

  const handleInterestChange = async (id) => {
    await updateBulkLabel({
      body: {
        ids: selectedEmails,
        labelId: id
      }
    }).unwrap()
    resetEmailSelections()
    handlePopoverClose()
  }

  const handleDeleteSelected = async () => {
    setOpenRemoveEmailDialog(true)
  }

  const updateCampaignData = (campaignsWithUnreadCount) => {
    try {
      const updatedCampaignData = campaignData?.map(campaign => {
        const updatedCampaign = campaignsWithUnreadCount?.find(doc => doc._id === campaign._id)
        return updatedCampaign || campaign
      })
      setCampaignData(updatedCampaignData)
    } catch (error) {
      console.log('Error updating campaigns:', error)
    }
  }

  const updateAccountData = (accountsData) => {
    try {
      const updatedAccounts = accounts?.map(account => {
        const updatedAccount = accountsData?.find(doc => doc._id === account._id)
        return updatedAccount || account
      })
      setAccounts(updatedAccounts)
    } catch (error) {
      console.error('Error fetching accounts:', error)
    }
  }

  const resetEmailSelections = () => {
    EmailUpdatedData()
    setSelectedEmails([])
    setCurrentEmail('')
  }

  const handleBulkEmailAction = async (markAsRead) => {
    const { accountsWithUnreadCount, campaignsWithUnreadCount, unreadCount } = await openedBulkEmail({
      body: {
        ids: selectedEmails,
        value: markAsRead
      }
    }).unwrap()
    dispatch(setActualTotalCount(unreadCount))
    updateCampaignData(campaignsWithUnreadCount)
    updateAccountData(accountsWithUnreadCount)
    resetEmailSelections()
  }

  const handleMarkAsUnreadSelected = async () => {
    handleBulkEmailAction(false)
  }

  const handleMarkAsReadSelected = async () => {
    handleBulkEmailAction(true)
  }

  const handleCloseRemoveLeadDialog = () => {
    setOpenRemoveLeadDialog(false)
  }

  const icons = [
    { Icon: PersonRemove, title: 'Add to blocklist', handler: handleAddSelectedToBlocklist },
    { Icon: TurnedIn, title: 'Change Interest', handler: handleChangeInterestOfSelected },
    { Icon: Delete, title: 'Delete Email', handler: handleDeleteSelected },
    { Icon: MarkAsUnread, title: 'Mark as unread', handler: handleMarkAsUnreadSelected },
    { Icon: MarkEmailRead, title: 'Mark as read', handler: handleMarkAsReadSelected }
  ]

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: 'calc(100vh - 150px)', sm: 'calc(100vh - 110px)' },
        backgroundColor: 'white',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0px 12px 15px 0px #4B71970D',
        borderRadius: '12px',
        overflowY: 'hidden',
        position: 'relative'
      }}
    >
      <Box sx={{ width: '100%', flexGrow: 1, overflowY: 'auto', position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 700,
              lineHeight: '18px',
              color: '#28287B'
            }}
          >
            {unread ? 'Unread' : sent ? 'Sent Emails' : 'All Inboxes'}
          </Typography>
          <RefreshButton onClick={handleRefreshWrapper} disabled={isRefreshing} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1
          }}
        >
          {!sent
            ? (
              <>
                {selectedEmails?.length > 0 && (
                  <Checkbox
                    checked={selectedEmails?.length === campaignEmails?.length && campaignEmails?.length > 0}
                    onChange={handleSelectAll}
                    onClick={(e) => e.stopPropagation()}
                  />
                )}
                {selectedEmails?.length > 0 && canEdit && (
                  <IconButton>
                    {icons.map(({ Icon, title, handler }, index) => (
                      <Tooltip key={index} title={title} arrow>
                        <Icon
                          sx={iconStyle}
                          onClick={(e) => handler(e)}
                        />
                      </Tooltip>
                    ))}
                  </IconButton>
                )}
              </>
              )
            : ''}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: campaignEmails?.length === 0 ? 'center' : 'flex-start',
            alignItems: 'center',
            flexDirection: 'column',
            mt: 2,
            width: '100%',
            '&::-webkit-scrollbar': {
              width: '14px'
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: '60px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#E4E4E5',
              borderRadius: '10px',
              border: '4px solid rgba(0, 0, 0, 0)',
              backgroundClip: 'padding-box'
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#d5d5d5'
            }
          }}
        >
        {shouldShowLoading
          ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <CircularProgress size={25} thickness={5} />
              <Typography
                sx={{ fontSize: '16px', fontWeight: 600, color: '#4e88e6', ml: 2 }}
              >
                {isRefreshing ? 'Refreshing...' : 'Loading...'}
              </Typography>
            </Box>
          )
          : campaignEmails.length < 0 && !shouldShowLoading 
            ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <Typography
                    sx={{ fontSize: '16px', fontWeight: 600 }}
                  >
                    No emails
                  </Typography>
                </Box>
              )
            : (
              campaignEmails.length > 0 && !shouldShowLoading &&
              <>
                {campaignEmails?.map((email) => {
                  const normalizedEmail = normalizeEmail(email, sent)
                  let previewText
                  if (!sent) {
                    const lastReply = normalizedEmail?.replies[normalizedEmail?.replies?.length - 1]
                    const replyHtml = parseBodyContent({ body: lastReply?.bodyTextHtml })
                    previewText = extractTextBeforeSeparator(replyHtml)
                  }
                  return (
                    <Box
                      key={normalizedEmail?.id}
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        backgroundColor: normalizedEmail?.portal_email_opened === false ? '#F0FFFF' : '',
                        borderRadius: '8px',
                        border:
                      normalizedEmail?.id === inboxSelectedID
                        ? '1px solid rgba(0, 113, 246, 1)'
                        : '1px solid rgba(228, 228, 229, 1)',
                        cursor: 'pointer',
                        py: 1,
                        px: 2,
                        mb: 2,
                        color: '#28287B',
                        '&:hover': {
                          backgroundColor: '#F2F4F6',
                          color: '#3F4FF8'
                        }
                      }}
                      onClick={() => handleEmailClick(email)}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'row' },
                          justifyContent: { xs: 'space-between' },
                          alignItems: { xs: 'center' },
                          width: '100%'
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'row' },
                            alignItems: 'center'
                          }}
                        >
                          {!sent && (
                            <Checkbox
                              checked={isSelected(normalizedEmail?.id)}
                              onChange={() => toggleEmailSelection(normalizedEmail?.id)}
                              onClick={(e) => e.stopPropagation()}
                            />
                          )}
                          <Typography
                            sx={{
                              fontSize: { xs: '12px', md: '13px', lg: '12px', xl: '13px' },
                              fontWeight: { xs: 600, md: 700 },
                              lineHeight: '20px'
                            }}
                          >
                            {normalizedEmail?.email}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: { xs: 'flex-end', sm: 'flex-end' }
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: { xs: '12px', md: '13px', lg: '11px', xl: '13px' },
                              fontWeight: { xs: 300, md: 400 },
                              lineHeight: '20px',
                              color: 'rgba(40, 40, 123, 0.5)'
                            }}
                          >
                            {formatDate(normalizedEmail?.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography
                          sx={{
                            fontSize: { xs: '12px', md: '13px' },
                            fontWeight: { xs: 300, md: 400 },
                            lineHeight: '20px',
                            color: 'rgba(40, 40, 123, 0.5)',
                            my: 1.5,
                            textAlign: 'left',
                            width: '100%'
                          }}
                        >
                          {normalizedEmail?.subject}
                        </Typography>
                        {normalizedEmail?.label?.name && (
                          <Typography
                            sx={{
                              fontSize: { xs: '12px', md: '13px' },
                              fontWeight: { xs: 300, md: 400 },
                              lineHeight: '20px',
                              color: 'rgba(40, 40, 123, 0.5)',
                              // display: 'flex',
                              // alignItems: 'center',
                              gap: 0.5,
                              mt: 0.5
                            }}
                            title={normalizedEmail?.label?.name}
                          >
                            {getIconByLabelName(normalizedEmail?.label?.name)}
                          </Typography>
                        )}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: { xs: '12px', md: '13px' },
                          fontWeight: { xs: 400, md: 500 },
                          lineHeight: '20px',
                          color: '#28287B',
                          textAlign: 'left',
                          width: '100%'
                        }}
                      >
                        {!sent ? previewText : parseBodyContent({ body: normalizedEmail?.body })}
                      </Typography>
                    </Box>
                  )
                })}
              </>
              )}
        </Box>
      </Box>
      {campaignEmails?.length > 0 && (
        <>
          <Box sx={{ position: 'sticky', bottom: 0, width: '100%' }}>
            <InboxPagination
              page={page}
              setPage={setPage}
              total={totalCampaignsEmails}
              length={campaignEmails?.length}
              limit={limit}
              handleLimitChange={handleLimitChange}
            />
          </Box>
          <CustomPopover
            open={Boolean(popoverAnchor)}
            anchorEl={popoverAnchor}
            onClose={handlePopoverClose}
            searchPlaceholder={searchPlaceholder}
            handleSearchChange={handleSearchChange}
            showCreateButton={showCreateButton}
            onCreateButtonClick={onCreateButtonClick}
            createButtonText={createButtonText}
            items={items}
            handleInterestChange={handleInterestChange}
            renderLabelWithIcon={renderLabelWithIcon}
          />
          <RemoveEmailDialog
            open={openRemoveEmailDialog}
            onClose={() => setOpenRemoveEmailDialog(false)}
            handleBulkDelete={handleBulkDelete}
          />
          <RemoveLeadDialog
            open={openRemoveLeadDialog}
            onClose={handleCloseRemoveLeadDialog}
            handleRemoveLead={handleRemoveLead}
            removeLeadOptions={removeLeadOptions}
            setRemoveLeadOptions={setRemoveLeadOptions}
            theme={theme}
          />
        </>
      )}
    </Box>
  )
});

export default EmailList
