export const EASettingsIcon = () => {
  return (
    <>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1.59961 3.2001L7.99961 3.2001M1.59961 8.0001H7.99961M7.99961 8.0001V9.6001M7.99961 8.0001V6.4001M1.59961 12.8001H4.79961M7.99961 12.8001L14.3996 12.8001M11.1996 8.0001H14.3996M11.1996 3.2001L14.3996 3.2001M11.1996 3.2001V4.8001M11.1996 3.2001V1.6001M5.19961 14.4001V11.2001'
          stroke='#28287B'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </>
  )
}
