import { config } from "./config"

export const msalConfig = {
    auth: {
        // This is the ONLY mandatory field that you need to supply.
        clientId: config.MSAUTH_CLIENT_ID,
        // clientId:'96e4b74f-e718-4cfa-a064-0aac3d4264b9',
        // Replace the placeholder with your tenant subdomain
        // authority: 'https://login.microsoftonline.com/b2brocketshivaoutlook.onmicrosoft.com',
        authority: 'https://login.microsoftonline.com/common',
        // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        // redirectUri: 'http://localhost:3000/',
        redirectUri: window.location.origin,
        // Indicates the page to navigate after logout.
        postLogoutRedirectUri: '/',
        // If "true", will navigate back to the original request location before processing the auth code
        navigateToLoginRequestUrl: false,
    },
    cache: {
        // Configures cache location. "sessionStorage" is more secure,
        // but "localStorage" gives you SSO between tabs.
        cacheLocation: 'sessionStorage',
        // Set this to "true" if you are having issues on IE11 or Edge
        storeAuthStateInCookie: false,

    }
}

export const loginRequest = {
    scopes: ['user.read'],
}