import React, { useEffect, useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { DropDown } from 'src/assets/general/DropDown'
import { useLocation, useNavigate } from 'react-router-dom'
import PopoverMenu from './settings/PopoverMenu'
import { setWorkspace, useGetWorkSpacesMutation, useUpdateWorkSpaceStatusMutation } from 'src/services/workSpace-service'
import { useDispatch } from 'react-redux'
import UserVerification from './emailAccounts/UserVerification.js'
import PremiumFeatureDialog from 'src/utils/premiumFeatureDialog'

const HeaderWithPopover = ({ title, onWorkspaceChange, selectedTimeline }) => {
  const { isFreeUser } = UserVerification()
  const [getWorkSpaces] = useGetWorkSpacesMutation()
  const [updateWorkSpaceStatus] = useUpdateWorkSpaceStatusMutation()
  const [openModal, setOpenModal] = useState(false)
  const [timelineButtons, setTimeLineButtons] = useState([])
  const [activeWorkspace, setActiveWorkspace] = useState({})
  const [timeline, setTimeline] = useState(null)
  const location = useLocation()
  const dispatch = useDispatch()

  const isAppSumoUser = (workspace) => {
    return workspace && workspace?.assignedPlan && workspace?.assignedPlan.length > 0
  }

  const isEarlyBirdAccessUser = (workspace) => {
    return workspace && workspace?.isEarlyBirdAccessUser
  }

  const isPaidUser = (workspace, currentPlan) => {
    return workspace && currentPlan && (currentPlan?.subscription?.sendingWarmup?.expiresAt || currentPlan?.subscription?.leads?.expiresAt)
  }

  const checkFreeWorkspace = async (docs) => {
    const currentWorkspace = docs?.currentWorkspace
    const currentPlan = docs?.currentPlan
    const uniqueWorkspaces = docs?.uniqueWorkspaces?.length

    const isEarlyBird = isEarlyBirdAccessUser(currentWorkspace)
    const isPaid = isPaidUser(currentWorkspace, currentPlan)
    const isAppSumo = isAppSumoUser(currentWorkspace)
    if (
      (!currentPlan && !isAppSumo) ||
      (uniqueWorkspaces > 1 && !isPaid && !isEarlyBird && !isAppSumo)
    ) {
      navigate('/workSpace/billing', { state: { fromCreateWorkspace: true }, replace: true })
    }
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      const docs = await getWorkSpaces().unwrap()
      setTimeLineButtons(docs?.uniqueWorkspaces)
      setActiveWorkspace(docs?.currentWorkspace)
      await checkFreeWorkspace(docs)
    }, 500)
    return () => clearTimeout(timer)
  }, [getWorkSpaces])

  useEffect(() => {
    setTimeline(activeWorkspace)
    dispatch(setWorkspace(activeWorkspace))
    if (activeWorkspace) {
      if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
        window.localStorage.setItem('workspace', JSON.stringify(activeWorkspace))
      }
    }
  }, [timelineButtons])

  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigateToCreateWorkspace = () => {
    if (isFreeUser) {
      setOpenModal(true)
      return
    }
    navigate('/new/workSpace/create', { state: { from: location.pathname } })
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    setTimeline(selectedTimeline)
  }, [selectedTimeline])

  const handleReload = () => {
    navigate(0)
  }

  const handleWorkspaceChange = async (newTimeline) => {
    // const currentWorkspace = timeline
    const { data } = await updateWorkSpaceStatus({ workspaceId: newTimeline?._id })
    const updatedWorkspace = data?.updatedWorkspace
    const currentPlan = data?.currentPlan
    if (currentPlan || updatedWorkspace?.appSumoCode) {
      navigate('/accounts')
      handleReload()
    } else {
      navigate('/workSpace/billing', { state: { fromCreateWorkspace: true }, replace: true })
    }
    setTimeline(updatedWorkspace)
    onWorkspaceChange(updatedWorkspace)
    dispatch(setWorkspace(updatedWorkspace))
  }

  return (
    <Box
      sx={{
        width: title === 'InboxHub' ? { xs: '100%', lg: 'calc(100% - 30px)', xl: 'calc(100% - 70px)' } : { xs: '100%', xl: 'calc(100% + 90px)' },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E4E4E5',
        pb: 2,
        ml: { xs: '0px', lg: '-10px' },
        mb: 3
      }}
    >
      <Typography
        sx={{
          color: '#28287B',
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: '40px',
          letterSpacing: '0px'
        }}
      >
        {title}
      </Typography>
      <Button
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'left',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '16.38px',
          color: '#28287B',
          backgroundColor: '#fff',
          px: 5.6,
          mb: 1,
          '&:hover': {
            backgroundColor: '#fff'
          },
          border: '1px solid #E4E4E5',
          height: '36px'
        }}
        onClick={handleClick}
      >
        {timeline?.name}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ml: 1
          }}
        >
          <DropDown />
        </Box>
      </Button>

      <PopoverMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        timelineButtons={timelineButtons}
        setTimeline={handleWorkspaceChange}
        navigateToCreateWorkspace={navigateToCreateWorkspace}
        timeline={timeline}
      />
      <PremiumFeatureDialog
        open={openModal}
        onClose={handleCloseModal}
      />
    </Box>
  )
}

export default HeaderWithPopover
