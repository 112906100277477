import React from 'react'

export const EDSMeterIcon = () => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21 16C21 13.6131 20.0518 11.3239 18.364 9.63604C16.6761 7.94821 14.3869 7 12 7C9.61305 7 7.32387 7.94821 5.63604 9.63604C3.94821 11.3239 3 13.6131 3 16L8.00506 16C8.00506 14.9405 8.42595 13.9243 9.17515 13.1751C9.92435 12.426 10.9405 12.0051 12 12.0051C13.0595 12.0051 14.0757 12.426 14.8249 13.1751C15.574 13.9243 15.9949 14.9405 15.9949 16H21Z'
          stroke='#28287B'
          stroke-width='2'
        />
        <path d='M12 7L12 12' stroke='#28287B' stroke-width='2' />
        <path d='M18.3633 9.63574L15.3633 12.6357' stroke='#28287B' stroke-width='2' />
        <path d='M5.63672 9.63574L9.00084 12.9999' stroke='#28287B' stroke-width='2' />
      </svg>
    </>
  )
}
