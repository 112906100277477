export const ApiLogo = () => {
  return (
    <svg
      width='192'
      height='192'
      viewBox='0 0 192 192'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='96' cy='96' r='64' fill='black' />
      <text
        x='96'
        y='106'
        textAnchor='middle'
        fontSize='35'
        fill='white'
        fontFamily='Arial, sans-serif'
      >
        {'{ api }'}
      </text>
    </svg>
  )
}
