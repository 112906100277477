import { createSvgIcon } from '@mui/material/utils'

export const Enterprise = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='currentColor'
  >
    <path d='M3 21v-2h2V7c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v12h2v2H3zm6-14v2h2V7H9zm4 0v2h2V7h-2zm-4 4v2h2v-2H9zm4 0v2h2v-2h-2zm-4 4v2h2v-2H9zm4 0v2h2v-2h-2zm-6 2h2v-2H7v2zm10-2v2h2v-2h-2z' />
  </svg>,
  'Enterprise'
)
